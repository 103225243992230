import React, { useEffect } from "react";
import styled from "styled-components";
import { Trans } from "react-i18next";
import Edit from "@material-ui/icons/Edit";
import { useBeforeunload } from "react-beforeunload";
import { useParams } from "react-router-dom";

import {
  Card as MuiCard,
  CardContent,
  Grid,
  TextField as MuiTextField,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import { updateRangeAction } from "../../redux/actions/rangeActions";

const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);

const useStyles = makeStyles(() => ({
  iconEdit: {
    fontSize: "1em",
    color: "#1876d1bd",
  },
  disableField: {
    "& td div": {
      color: "black!important",
    },
  },
}));
const RangeDetail = () => {
  const classes = useStyles();
  const { name, tva_rate } = useSelector((state) => state.ranges.range);
  useBeforeunload(() => "Are you sure to close this tab?");
  const [rangeState, setRangeState] = React.useState({
    name,
    tva_rate,
  });
  useEffect(() => {
    setRangeState({
      name,
      tva_rate,
    });
  }, [name, tva_rate]);

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;
  const [saveRequired, setSaveRequired] = React.useState(false);
  const { rangeId } = useParams();
  const updateRangeName = (newRangeName) => {
    setRangeState({ ...rangeState, name: newRangeName });
    dispatch({
      type: "EDIT_RANGE",
      payload: { range: { name: newRangeName } },
    });
    setSaveRequired(true);
  };
  const saveRange = async () => {
    if (saveRequired) {
      await updateRangeAction(
        { name: rangeState.name, tva_rate: rangeState.tva_rate },
        rangeId,
        user.accessToken
      );
      setSaveRequired(false);
    }
  };
  const updateRangeTvaRate = (newTvaRate) => {
    setRangeState({ ...rangeState, tva_rate: newTvaRate });
    setSaveRequired(true);
  };
  const saveRangeTvaRate = async () => {
    saveRange();
    await dispatch({
      type: "EDIT_RANGE",
      payload: { range: { tva_rate: rangeState.tva_rate } },
    });
  };
  const flexContainer = {
    display: "flex",
    flexDirection: "row",
  };

  return (
    <Card mb={6}>
      <CardContent>
        <form style={flexContainer}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                fullWidth
                id="name"
                value={rangeState.name}
                label={<Trans i18nKey="Nom de la gamme">Nom de la gamme</Trans>}
                name="name"
                autoFocus
                // eslint-disable-next-line prettier/prettier
                onChange={(e) => updateRangeName(e.target.value)}
                onBlur={saveRange}
                InputProps={{
                  endAdornment: (
                    <Edit className={classes.iconEdit} onClick={() => 0}>
                      x
                    </Edit>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                fullWidth
                value={rangeState.tva_rate}
                type="number"
                id="tva_rate"
                label={<Trans i18nKey="Taux de TVA(%)">Taux de TVA(%)</Trans>}
                // eslint-disable-next-line prettier/prettier
                onChange={(e) => updateRangeTvaRate(e.target.value)}
                onBlur={saveRangeTvaRate}
                name="tva_rate"
                InputProps={{
                  endAdornment: (
                    <Edit className={classes.iconEdit} onClick={() => 0}>
                      x
                    </Edit>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default RangeDetail;
