// eslint-disable-next-line no-unused-vars
import React, { useEffect } from "react";
import Helmet from "react-helmet";

import { Divider, Typography, makeStyles, Grid } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import GeneralInformation from "../../components/Sig/GeneralInformation";
import TotalProduct from "../../components/Sig/TotalProduct";
import ConsumedMaterials from "../../components/Sig/ConsumedMaterials";
import GrossMargin from "../../components/Sig/GrossMargin";
// eslint-disable-next-line no-unused-vars
import useDebounce from "../../services/useDebounce";
import {
  fetchExternalCosts,
  fetchSig,
  fetchWorkforceCosts,
  updateProjectIntermediateManagementThreshold,
} from "../../redux/actions/sigActions";
import { openSnackbar } from "../../redux/actions/snackbarActions";
import { updateUserAction } from "../../redux/actions/userActions";
import ExternalCosts from "../../components/Sig/ExternalCosts";
import OperatingResult from "../../components/Sig/OperatingResult";
import CurrentResultBeforeTaxRCAI from "../../components/Sig/CurrentResultBeforeTaxRCAI";
import NetResult from "../../components/Sig/NetResult";
import CashFlow from "../../components/Sig/CashFlow";
import {
  UPDATE_EXTERNAL_COSTS_SUMMARY,
  UPDATE_WORKFORCE_COSTS_SUMMARY,
} from "../../redux/types/sigActionTypes";
import {
  getExternalCostTotal,
  getExternalCostTotalFixedPart,
  getExternalCostTotalFixedPartPercentage,
  getExternalCostTotalPercentage,
} from "../../services/external_costs/external_costs_summary";
import WorkforceCosts from "../../components/Sig/WorkforceCosts";
import Taxes from "../../components/Sig/Taxes";
import GrossOperatingSurplus from "../../components/Sig/GrossOperatingSurplus";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
  margin: {
    marginTop: "24px",
    marginBottom: "24px",
  },
}));

const ShowSig = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const currentProject = useSelector((state) => state.projects.currentProject);
  const sig = useSelector((state) => state.sig.sig);
  const external_costs = useSelector((state) => state.sig.external_costs);
  const workforce_costs = useSelector((state) => state.sig.workforce_costs);
  const userAction = useSelector((state) => state.user.user_action);
  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;

  const debouncedgeneralInformationForm = useDebounce(sig, 400);

  useEffect(async () => {
    await dispatch(fetchSig(currentProject.id, user.accessToken));
    await dispatch(
      fetchExternalCosts(currentProject.id, user.accessToken, "EXTERNAL")
    );
    await dispatch(
      fetchWorkforceCosts(currentProject.id, user.accessToken, "STAFF")
    );
  }, [currentProject]);
  useEffect(async () => {
    if (workforce_costs.length > 0) {
      const external_cost_total = getExternalCostTotal(workforce_costs);

      dispatch({
        type: UPDATE_WORKFORCE_COSTS_SUMMARY,
        payload: {
          workforce_cost_total: external_cost_total,
          workforce_cost_total_percentage: getExternalCostTotalPercentage(
            external_cost_total,
            sig
          ),
          workforce_total_fixed_part: getExternalCostTotalFixedPart(
            workforce_costs
          ),
          workforce_total_fixed_part_percentage: getExternalCostTotalFixedPartPercentage(
            workforce_costs
          ),
        },
      });
    }
  }, [workforce_costs]);
  useEffect(async () => {
    if (external_costs.length > 0) {
      const external_cost_total = getExternalCostTotal(external_costs);
      dispatch({
        type: UPDATE_EXTERNAL_COSTS_SUMMARY,
        payload: {
          external_cost_total,
          external_cost_total_percentage: getExternalCostTotalPercentage(
            external_cost_total,
            sig
          ),
          external_cost_total_fixed_part: getExternalCostTotalFixedPart(
            external_costs
          ),
          external_cost_total_fixed_part_percentage: getExternalCostTotalFixedPartPercentage(
            external_costs
          ),
        },
      });
    }
  }, [external_costs]);
  useEffect(async () => {
    if (debouncedgeneralInformationForm && userAction) {
      const generalInformationFormWithProject = {
        ...debouncedgeneralInformationForm,
        project: currentProject.id,
      };

      await dispatch(
        updateProjectIntermediateManagementThreshold(
          generalInformationFormWithProject,
          user.accessToken
        )
      );
      const updateMessage = `Informations sauvegardées`;
      dispatch(updateUserAction(false));
      dispatch(openSnackbar(updateMessage, "success"));
    }
  }, [debouncedgeneralInformationForm]);

  return (
    <>
      <Helmet>
        <title>{t("SIG")}</title>
      </Helmet>

      <Typography variant="h3" gutterBottom display="inline">
        <Trans i18nKey="Soldes intermédiaires de gestion (et charges fixes)">
          Soldes intermédiaires de gestion (et charges fixes)
        </Trans>
      </Typography>

      <Divider my={6} mr={24} className={classes.margin} />
      <Grid container spacing={2} className={classes.disableField}>
        <Grid xs={12}>
          <GeneralInformation />
        </Grid>
        <Grid xs={12}>
          <TotalProduct />
        </Grid>
        <Grid xs={12}>
          <ConsumedMaterials />
        </Grid>
        <Grid xs={12}>
          <GrossMargin />
        </Grid>
        <Grid xs={12}>
          <ExternalCosts />
        </Grid>
        <Grid xs={12}>
          <Taxes />
          <WorkforceCosts />
        </Grid>
        <Grid xs={12}>
          <GrossOperatingSurplus />
        </Grid>
        <Grid xs={12}>
          <OperatingResult />
        </Grid>
        <Grid xs={12}>
          <CurrentResultBeforeTaxRCAI />
        </Grid>
        <Grid xs={12}>
          <NetResult />
        </Grid>
        <Grid xs={12}>
          <CashFlow />
        </Grid>
        <Grid xs={3} />
      </Grid>
    </>
  );
};

export default ShowSig;
