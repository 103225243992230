export const API_BASE = `${process.env.REACT_APP_POPSER_API_URL}`;
export const RANGE_URL = `${API_BASE}/range/`;
export const RANGE_PRODUCTS = `${API_BASE}/product-of-range/`;
export const PRODUCTS_URL = `${API_BASE}/product/`;
export const RAW_MATERIALS_URL = `${API_BASE}/rawmaterial/`;
export const OFFERS_URL = `${API_BASE}/offer/`;
export const OFFER_RANGES_URL = `${API_BASE}/ranges-of-offer/`;
export const SIG_URL = `${API_BASE}/intermediate-management-threshold/`;
export const EXTERNAL_COSTS_URL = `${API_BASE}/external-costs/`;
export const CSER_URL = `${API_BASE}/cser/`;
export const PROJECT_URL = `${API_BASE}/project/`;
export const PROJECT_MEMBERSHIP_URL = `${API_BASE}/project-member/`;
