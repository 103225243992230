import { LOADING_ACTION } from "../types/settingsActionTypes";

export const updateSettingsAction = (isLoading) => (dispatch) => {
  dispatch({
    type: LOADING_ACTION,
    payload: isLoading,
  });
};

export default updateSettingsAction;
