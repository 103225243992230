import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { ErrorMessage } from "@hookform/error-message";
import Edit from "@material-ui/icons/Edit";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  input: {
    width: "100px",
    border: "none",
    backgroundColor: "transparent",
    "& input": {
      fontSize: "0.875rem",
      color: "Black",
    },
    "& div:before": {
      borderBottom: "none",
      borderBottomStyle: "none!important",
    },
    "& div:hover": {
      borderBottom: "none",
    },
  },
  inputEnd: {
    width: "90px",
  },
  iconEdit: {
    fontSize: "1em",
    color: "#1876d1bd",
  },
}));

const FieldWithSuffix = ({
  nameColumn,
  control,
  errors,
  suffix,
  register,
  onChangeField,
  tableMeta,
  updateValue,
  value,
  disabled,
  isEdit,
}) => {
  const classes = useStyles();
  const handleChange = (event) => {
    updateValue(event.target.value);
    onChangeField(tableMeta, event.target.value);
  };

  return (
    <div>
      <Controller
        inputRef={register()}
        id={nameColumn}
        defaultValue=""
        name={nameColumn}
        className={classes.input}
        render={() => (
          <NumberFormat
            customInput={TextField}
            disabled={disabled}
            value={value}
            defaultValue=""
            className={classes.input}
            onBlur={(event) => handleChange(event)}
            suffix={suffix}
            InputProps={
              isEdit
                ? {
                    endAdornment: (
                      <Edit className={classes.iconEdit} onClick={() => 0}>
                        x
                      </Edit>
                    ),
                  }
                : {}
            }
          />
        )}
        control={control}
      />
      <ErrorMessage errors={errors} name={nameColumn} as="p" />
    </div>
  );
};
FieldWithSuffix.propTypes = {
  nameColumn: PropTypes.string.isRequired,
  control: PropTypes.func.isRequired,
  errors: PropTypes.string.isRequired,
  suffix: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  onChangeField: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tableMeta: PropTypes.object.isRequired,
  updateValue: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  isEdit: PropTypes.bool,
};

FieldWithSuffix.defaultProps = {
  disabled: false,
  isEdit: false,
};

export default FieldWithSuffix;
