import { PRODUCTS_URL, RAW_MATERIALS_URL } from "../../services/constants/urls";
import {
  FETCH_TOTAL_RAW_COST,
  FETCH_NUMBER_PRODUCED,
  FETCH_TOTAL_PRICE_PORTION,
  FETCH_TOTAL_ENERGY,
  FETCH_PRODUCT,
  CLEAR_PRODUCT,
  FETCH_PRODUCTS,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_ERROR,
  FETCH_PRODUCTS_ERROR,
  FETCH_RAW_MATERIALS,
  FETCH_RAW_MATERIALS_ERROR,
  POST_RAW_MATERIAL,
  POST_RAW_MATERIAL_ERROR,
  DELETE_RAW_MATERIAL,
  DELETE_RAW_MATERIAL_ERROR,
  PUT_RAW_MATERIAL,
  PUT_RAW_MATERIAL_ERROR,
} from "../types/productActionTypes";
import { LOADING_ACTION } from "../types/settingsActionTypes";

import apiClient from "../../services/api/apiClient";
import { store } from "../store";

export const fetchProducts = (project_id, accessToken) => async (dispatch) => {
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${PRODUCTS_URL}?project_id=${project_id}&page_size=100`;
  try {
    const res = await apiClient({ method: "get", url, headers });
    dispatch({
      type: FETCH_PRODUCTS,
      payload: res.data.results,
    });
  } catch (e) {
    dispatch({
      type: FETCH_PRODUCTS_ERROR,
      payload: { error: e },
    });
  }
};
export const fetchProduct = (product_id, user) => async (dispatch) => {
  await dispatch({
    type: LOADING_ACTION,
    payload: true,
  });
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${user.accessToken}`,
  };
  const url = `${PRODUCTS_URL}${product_id}`;
  try {
    const res = await apiClient({ method: "get", url, headers });
    await dispatch({
      type: FETCH_PRODUCT,
      payload: res.data,
    });
    await dispatch({
      type: FETCH_NUMBER_PRODUCED,
      payload: res.data.number_produced,
    });
    await dispatch({
      type: LOADING_ACTION,
      payload: false,
    });
  } catch (e) {
    await dispatch({
      type: FETCH_PRODUCTS_ERROR,
      payload: { error: e },
    });
    await dispatch({
      type: LOADING_ACTION,
      payload: false,
    });
  }
};

export const clearProduct = () => async (dispatch) => {
  dispatch({
    type: CLEAR_PRODUCT,
    payload: {},
  });
};

export const fetchTotalRawCost = (listRawMaterial) => async (dispatch) => {
  const listTotalPrice = [];
  let rawCostList;
  if (listRawMaterial) {
    if (listRawMaterial.length > 0) {
      listRawMaterial.forEach((element) => {
        listTotalPrice.push(element.total_price);
      });
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      rawCostList = listTotalPrice.reduce(reducer);
    } else {
      rawCostList = 0;
    }
  } else {
    rawCostList = 0;
  }
  dispatch({
    type: FETCH_TOTAL_RAW_COST,
    payload: rawCostList,
  });
};

export const fetchNumberProduced = (numberProduced) => async (dispatch) => {
  dispatch({
    type: FETCH_NUMBER_PRODUCED,
    payload: numberProduced,
  });
};

export const fetchTotalPricePortion = (totalPricePortion) => async (
  dispatch
) => {
  dispatch({
    type: FETCH_TOTAL_PRICE_PORTION,
    payload: totalPricePortion,
  });
};

export const fetchTotalEnergy = (listRawMaterial) => async (dispatch) => {
  const listTotalEnergy = [];
  let rawEnergy;
  if (listRawMaterial) {
    if (listRawMaterial.length > 0) {
      listRawMaterial.forEach((element) => {
        listTotalEnergy.push(
          (parseInt(element.energy, 10) || 0) *
            (parseInt(element.net_quantity, 10) || 0)
        );
      });

      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      rawEnergy = listTotalEnergy.reduce(reducer);
    } else {
      rawEnergy = 0;
    }
  } else {
    rawEnergy = 0;
  }
  dispatch({
    type: FETCH_TOTAL_ENERGY,
    payload: rawEnergy,
  });
};

export const updateProduct = (
  product,
  productId,
  user,
  currentProject
) => async (dispatch) => {
  const data = {
    name: product.name ? product.name : undefined,
    project: currentProject.id,
    number_produced: product.number_produced
      ? product.number_produced
      : undefined,
    ranges: product.line ? product.line : undefined,
    seasonnality: product.seasonnality ? product.seasonnality : undefined,
    coefficient:
      product.coefficient && !Number.isNaN(product.coefficient)
        ? product.coefficient
        : undefined,
    tva_rate:
      product.tva_rate && !Number.isNaN(product.tva_rate)
        ? product.tva_rate
        : undefined,
    ttc_price:
      product.ttc_price && !Number.isNaN(product.ttc_price)
        ? product.ttc_price
        : undefined,
    selling_price_ht:
      product.selling_price_ht && !Number.isNaN(product.selling_price_ht)
        ? product.selling_price_ht
        : undefined,
    per_unit_price:
      product.per_unit_price && !Number.isNaN(product.per_unit_price)
        ? product.per_unit_price
        : undefined,
    total_raw_material_cost:
      product.total_raw_material_cost &&
      !Number.isNaN(product.total_raw_material_cost)
        ? product.total_raw_material_cost
        : undefined,
    sales_coefficient:
      product.sales_coefficient && !Number.isNaN(product.sales_coefficient)
        ? product.sales_coefficient
        : undefined,
    tva: product.tva && !Number.isNaN(product.tva) ? product.tva : undefined,
    extra_charge:
      (product.extra_charge && !Number.isNaN(product.extra_charge)) ||
      product.extra_charge === 0
        ? product.extra_charge
        : undefined,
    per_unit_energy:
      product.per_unit_energy && !Number.isNaN(product.per_unit_energy)
        ? product.per_unit_energy
        : undefined,
    raw_material_cost:
      product.raw_material_cost && !Number.isNaN(product.raw_material_cost)
        ? product.raw_material_cost
        : undefined,
    production_techniques: product.production_techniques
      ? product.production_techniques
      : undefined,
  };

  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${user.accessToken}`,
  };
  const url = `${process.env.REACT_APP_POPSER_API_URL}/product/${productId}`;
  try {
    const res = await apiClient({ method: "patch", url, headers, data });
    dispatch({
      type: UPDATE_PRODUCT,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: UPDATE_PRODUCT_ERROR,
      payload: { error: e },
    });
  }
};

export const fetchRawMaterials = (productId, accessToken) => async (
  dispatch
) => {
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${RAW_MATERIALS_URL}?product_id=${productId}`;
  try {
    const res = await apiClient({ method: "get", url, headers });
    if (res.data.length > 0) {
      dispatch({
        type: FETCH_RAW_MATERIALS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: FETCH_RAW_MATERIALS,
        payload: [],
      });
    }
  } catch (e) {
    dispatch({
      type: FETCH_RAW_MATERIALS_ERROR,
      payload: { error: e },
    });
  }
};

export const postRawMaterial = (rawMaterial, accessToken) => async (
  dispatch
) => {
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${RAW_MATERIALS_URL}`;
  try {
    const res = await apiClient({
      method: "post",
      url,
      headers,
      data: rawMaterial,
    });
    dispatch({
      type: POST_RAW_MATERIAL,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: POST_RAW_MATERIAL_ERROR,
      payload: { error: e },
    });
  }
};

export const deleteRawMaterial = (rawMaterialId, accessToken) => async (
  dispatch
) => {
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${RAW_MATERIALS_URL}${rawMaterialId}`;
  try {
    await apiClient({
      method: "delete",
      url,
      headers,
    });
    const state = store
      .getState()
      .product.raw_materials.filter(
        (raw_material) => raw_material.id !== rawMaterialId
      );
    dispatch({
      type: DELETE_RAW_MATERIAL,
      payload: state,
    });
  } catch (e) {
    dispatch({
      type: DELETE_RAW_MATERIAL_ERROR,
      payload: { error: e },
    });
  }
};

export const putRawMaterial = (
  rawMaterial,
  rawMaterials,
  accessToken
) => async (dispatch) => {
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${RAW_MATERIALS_URL}${rawMaterial.id}`;
  console.log("coucou", rawMaterial);
  const rawMaterialData = rawMaterial;
  if (rawMaterialData.energy === "") {
    rawMaterialData.energy = null;
  }
  if (rawMaterialData.raw_quantity === "") {
    rawMaterialData.raw_quantity = null;
  }
  if (rawMaterialData.loss === "") {
    rawMaterialData.loss = null;
  }
  if (rawMaterialData.unit_price === "") {
    rawMaterialData.unit_price = null;
  }
  try {
    const res = await apiClient({
      method: "put",
      url,
      headers,
      data: rawMaterialData,
    });
    const index = rawMaterials.findIndex(
      (raw_material) => raw_material.id === res.data.id
    );
    rawMaterials.splice(index, 1, res.data);
    dispatch({
      type: PUT_RAW_MATERIAL,
      payload: rawMaterials,
    });
  } catch (e) {
    dispatch({
      type: PUT_RAW_MATERIAL_ERROR,
      payload: { error: e },
    });
  }
};
export default fetchTotalRawCost;
