import React from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  Typography,
  makeStyles,
  Modal,
  Fade,
  Backdrop,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import FunctionsIcon from "@material-ui/icons/Functions";
import FieldWithSuffix from "../ElementsField/FieldWithSuffix";
import { editingProjectIntermediateManagementThreshold } from "../../redux/actions/sigActions";
import { getProfitTax } from "../../services/sig/SigMethodService";
import { updateUserAction } from "../../redux/actions/userActions";

const useStyles = makeStyles({
  margin: {
    marginTop: "12px",
  },
  input: {
    width: "90px!important",
  },
  gridInput: {
    borderLeft: "solid 1px",
    paddingLeft: "30px",
    borderBottom: "solid 1px",
  },
  gridTitle: {
    borderBottom: "solid 1px",
    paddingBottom: "11px",
    paddingTop: "21px",
  },
  tableEqualWidth: {
    "& th": { width: "20%!important" },
  },
  tableEqualWidthTotal: {
    "& th": { width: "20%!important", border: "0px!important" },
    "& td": { border: "0px!important" },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "5px",
    border: "solid",
    width: "400px",
  },
  iconEdit: {
    fontSize: "1em",
    color: "#1876d1bd",
  },
  card: {
    paddingBottom: "0px!important",
  },
  gridColor: {
    backgroundColor: "#ffffff",
  },
  componentSig: {
    marginBottom: "0px!important",
  },
  noPadding: {
    paddingBottom: "0px",
    paddingTop: "0px",
  },
  noPaddingTh: {
    "& th": {
      padding: "0px",
    },
  },
  paddingThTd: {
    "& th": {
      paddingTop: "5px!important",
      paddingBottom: "5px!important",
    },
    "& td": {
      paddingTop: "5px!important",
      paddingBottom: "5px!important",
    },
  },
  floatRight: {
    float: "right",
  },
});
const TaxTier = ({ setOpenModal, openModal }) => {
  const { register, control, errors } = useForm();
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sig = useSelector((state) => state.sig.sig);
  const handleUserUpdateTaxTier = (ProjectIntermediateManagementThreshold) => {
    dispatch(
      editingProjectIntermediateManagementThreshold(
        getProfitTax(ProjectIntermediateManagementThreshold)
      )
    );
    dispatch(updateUserAction(true));
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className={classes.modal}
      open={openModal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal} className={classes.paper}>
        <div className={classes.paper}>
          <Typography variant="h5" gutterBottom display="inline">
            <Trans i18nKey="Imposition :">Imposition :</Trans>
          </Typography>
          <FunctionsIcon className={classes.floatRight} />

          <TableContainer component={Paper}>
            <Table
              className={[classes.table, classes.tableEqualWidth]}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow className={[classes.noPaddingTh]}>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody className={classes.paddingThTd}>
                <TableRow key="tax" className={classes.gridColor}>
                  <TableCell align="right">
                    <FieldWithSuffix
                      isEdit
                      name="tax_rate_lower_tier"
                      control={control}
                      errors={errors}
                      suffix={t("%")}
                      value={sig.tax_rate_lower_tier}
                      onChangeField={
                        (e) =>
                          handleUserUpdateTaxTier({
                            ...sig,
                            tax_rate_lower_tier: e.target.value.slice(
                              0,
                              e.target.value.length - 1
                            ),
                          })
                        // eslint-disable-next-line react/jsx-curly-newline
                      }
                      register={register}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Trans i18nKey="sur les">sur les</Trans>
                  </TableCell>
                  <TableCell align="right">
                    <FieldWithSuffix
                      isEdit
                      name="tax_lower_tier"
                      control={control}
                      errors={errors}
                      suffix={t("€")}
                      value={sig.tax_lower_tier}
                      onChangeField={
                        (e) =>
                          handleUserUpdateTaxTier({
                            ...sig,
                            tax_lower_tier: e.target.value.slice(
                              0,
                              e.target.value.length - 1
                            ),
                          })
                        // eslint-disable-next-line react/jsx-curly-newline
                      }
                      register={register}
                    />
                  </TableCell>
                </TableRow>
                <TableRow
                  key="tax_rate_upper_tier"
                  className={classes.gridColor}
                >
                  <TableCell align="right">
                    <FieldWithSuffix
                      isEdit
                      name="tax_rate_upper_tier"
                      control={control}
                      errors={errors}
                      suffix={t("%")}
                      value={sig.tax_rate_upper_tier}
                      onChangeField={
                        (e) =>
                          handleUserUpdateTaxTier({
                            ...sig,
                            tax_rate_upper_tier: e.target.value.slice(
                              0,
                              e.target.value.length - 1
                            ),
                          })
                        // eslint-disable-next-line react/jsx-curly-newline
                      }
                      register={register}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Trans i18nKey="au delà">au delà</Trans>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Fade>
    </Modal>
  );
};

TaxTier.propTypes = {
  setOpenModal: PropTypes.func.isRequired,
  openModal: PropTypes.bool.isRequired,
};
export default TaxTier;
