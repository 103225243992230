import { OFFERS_URL } from "../../services/constants/urls";
import {
  FETCH_PROJECT_OFFERS,
  DELETE_OFFERS,
  FETCH_PROJECT_OFFERS_ERROR,
  DELETE_OFFERS_ERROR,
  ADD_OFFER,
  FETCH_OFFER_BY_UUID,
  FETCH_OFFER_ERROR,
  UPDATE_OFFER,
  UPDATE_OFFER_ERROR,
} from "../types/offerActionTypes";
import { LOADING_ACTION } from "../types/settingsActionTypes";

import apiClient from "../../services/api/apiClient";
import { openSnackbar } from "./snackbarActions";

export const fetchProjectOffers = (
  project_id,
  accessToken,
  url_page,
  url_page_size,
  url_search,
  url_sort,
  url_sort_asc
) => async (dispatch) => {
  dispatch({
    type: LOADING_ACTION,
    payload: true,
  });
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${OFFERS_URL}?project_id=${project_id}${url_page}${url_page_size}${url_search}${url_sort}${url_sort_asc}`;
  try {
    const res = await apiClient({ method: "get", url, headers });
    await dispatch({
      type: FETCH_PROJECT_OFFERS,
      payload: res.data,
    });
    await dispatch({
      type: LOADING_ACTION,
      payload: false,
    });
  } catch (e) {
    await dispatch({
      type: FETCH_PROJECT_OFFERS_ERROR,
      payload: { error: e },
    });
    await dispatch({
      type: LOADING_ACTION,
      payload: false,
    });
  }
};
export const deleteOffers = (list_offers_uuid, accessToken) => async (
  dispatch
) => {
  const listDeleteOffers = [];
  let errorLog = { isError: false, message: null };

  await Promise.all(
    list_offers_uuid.map(async (uuid) => {
      const headers = {
        "Content-Type": `application/json;`,
        Authorization: `Bearer ${accessToken}`,
      };
      const url = `${OFFERS_URL}${uuid}/`;
      try {
        await apiClient({
          method: "delete",
          url,
          headers,
        });
        listDeleteOffers.push(uuid);
      } catch (e) {
        errorLog = { isError: true, message: e };
      }
    })
  );
  if (errorLog.isError) {
    dispatch({
      type: DELETE_OFFERS_ERROR,
      payload: { error: errorLog.message },
    });
  } else {
    dispatch({
      type: DELETE_OFFERS,
      payload: listDeleteOffers,
    });
    dispatch(openSnackbar("Offre supprimée avec succès", "Success"));
  }
};
export const addOfferWithoutRecording = (offer) => async (dispatch) => {
  dispatch({
    type: ADD_OFFER,
    payload: offer,
  });
};

export const fetchOffer = (offerId, accessToken) => async (dispatch) => {
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${OFFERS_URL}${offerId}`;
  try {
    const res = await apiClient({ method: "get", url, headers });

    dispatch({
      type: FETCH_OFFER_BY_UUID,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: FETCH_OFFER_ERROR,
      payload: { error: e },
    });
  }
};

export const updateOffer = (data, offerId, accessToken) => async (dispatch) => {
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${OFFERS_URL}${offerId}/`;
  try {
    const res = await apiClient({ method: "patch", url, headers, data });
    dispatch({
      type: UPDATE_OFFER,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: UPDATE_OFFER_ERROR,
      payload: { error: e },
    });
  }
};

export default fetchProjectOffers;
