import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { ErrorMessage } from "@hookform/error-message";
import { TextField } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";

const useStyles = makeStyles(() => ({
  input: {
    "&:before": {
      borderBottom: "none",
    },
    "& input": {
      textAlign: "center!important",
    },
  },
  defaultWidth: {
    width: "100%",
  },
  iconEdit: {
    fontSize: "1em",
    color: "#1876d1bd",
  },
  underlineHidden: {
    "& .MuiInput-underline:before": {
      borderBottom: "none!important",
    },
  },
  alignCenter: {
    "& input": {
      color: "black",
    },
  },
}));

const FieldWithSuffix = ({
  disabled,
  name,
  control,
  errors,
  suffix,
  register,
  onChangeField,
  value,
  defaultValue,
  isEdit,
  classesProps,
  isString,
}) => {
  const classes = useStyles();
  const handleChange = (event) => {
    const eventData = event;
    if (!isString) {
      eventData.target.value = event.target.value.split(" ").join("");
    }
    onChangeField(eventData);
  };
  return (
    <div className={classes.underlineHidden}>
      <Controller
        inputRef={register()}
        id={name}
        defaultValue={defaultValue}
        name={name}
        render={() => (
          <NumberFormat
            disabled={disabled}
            value={value}
            name={name}
            allowedDecimalSeparators=","
            customInput={TextField}
            defaultValue={defaultValue}
            className={[
              classesProps === undefined
                ? classes.defaultWidth
                : [classesProps],
              classes.alignCenter,
              classes.input,
            ]}
            onBlur={(event) => handleChange(event)}
            suffix={suffix}
            thousandSeparator=" "
            InputProps={
              isEdit
                ? {
                    endAdornment: (
                      <Edit className={classes.iconEdit} onClick={() => 0}>
                        x
                      </Edit>
                    ),
                    /* classes: {
                      adornedEnd: classes.input,
                    }, */
                  }
                : {}
            }
          />
        )}
        control={control}
      />
      <ErrorMessage errors={errors} name={name} as="p" />
    </div>
  );
};
FieldWithSuffix.propTypes = {
  disabled: PropTypes.bool.isRequired,
  isString: PropTypes.bool,
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object.isRequired,
  suffix: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  onChangeField: PropTypes.func,
  value: PropTypes.number,
  defaultValue: PropTypes.number,
  isEdit: PropTypes.bool,
  classesProps: PropTypes.shape({
    width: PropTypes.string,
  }),
};

FieldWithSuffix.defaultProps = {
  value: null,
  defaultValue: null,
  onChangeField: () => console.log("Cellule cliquée. Valeur non gérée"),
  isEdit: false,
  classesProps: useStyles.defaultWidth,
  isString: false,
};
export default FieldWithSuffix;
