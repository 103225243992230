import React from "react";
import PropTypes from "prop-types";
import { Add as AddIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import {
  TableFooter,
  Tooltip,
  IconButton,
  TableRow,
  TablePagination,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  buttonAdd: {
    width: "100%",
    borderRadius: "0",
  },
}));

const OfferFooterAddLine = ({
  count,
  page,
  rowsPerPage,
  changeRowsPerPage,
  changePage,
  addLine,
  title,
}) => {
  const classes = useStyles();

  return (
    <>
      <TableFooter>
        <TableRow>
          <TablePagination
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            // eslint-disable-next-line no-shadow
            onChangePage={(_, page) => changePage(page)}
            onChangeRowsPerPage={(event) =>
              // eslint-disable-next-line prettier/prettier
              changeRowsPerPage(event.target.value)}
            rowsPerPageOptions={[10, 15, 100]}
          />
        </TableRow>
      </TableFooter>
      <tfoot>
        <tr>
          <td>
            <Tooltip
              title={title}
              className={classes.buttonAdd}
              onClick={addLine}
            >
              <IconButton aria-label="ajouter une ligne">
                <AddIcon />
              </IconButton>
            </Tooltip>
          </td>
        </tr>
      </tfoot>
    </>
  );
};
OfferFooterAddLine.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  changeRowsPerPage: PropTypes.func,
  changePage: PropTypes.func,
  addLine: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

OfferFooterAddLine.defaultProps = {
  changeRowsPerPage: null,
  changePage: null,
};
export default OfferFooterAddLine;
