import axios from "../../services/api/apiClient";
import {
  FETCH_PROJECTS,
  ADD_PROJECT,
  DELETE_PROJECT,
  ADD_CURRENT_PROJECT,
  ERROR_FETCHING_PROJECTS,
  UPDATE_PROJECT,
} from "../types/projectActionTypes";
import { LOADING_ACTION } from "../types/settingsActionTypes";

import { openSnackbar } from "./snackbarActions";

export const fetchProjects = (user) => async (dispatch) => {
  await dispatch({
    type: LOADING_ACTION,
    payload: true,
  });
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${user.accessToken}`,
  };
  const url = `${process.env.REACT_APP_POPSER_API_URL}/project/`;
  try {
    const res = await axios({ method: "get", url, headers });
    dispatch({
      type: FETCH_PROJECTS,
      payload: res.data,
    });
    await dispatch({
      type: LOADING_ACTION,
      payload: false,
    });
  } catch (e) {
    dispatch({
      type: ERROR_FETCHING_PROJECTS,
      payload: { error: e },
    });
    await dispatch({
      type: LOADING_ACTION,
      payload: false,
    });
  }
};
export const addProject = (user, data) => async (dispatch) => {
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${user.accessToken}`,
  };
  const url = `${process.env.REACT_APP_POPSER_API_URL}/project/`;

  try {
    const res = await axios({ method: "post", url, headers, data });
    dispatch({
      type: ADD_PROJECT,
      payload: res.data,
    });
    dispatch(openSnackbar("Projet ajouté avec succès", "Success"));
  } catch (e) {
    dispatch({
      type: ERROR_FETCHING_PROJECTS,
      payload: { error: e },
    });
  }
};
export const updateProject = (user, data) => async (dispatch) => {
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${user.accessToken}`,
  };
  const url = `${process.env.REACT_APP_POPSER_API_URL}/project/${data.id}/`;

  try {
    const res = await axios({ method: "patch", url, headers, data });
    dispatch({
      type: UPDATE_PROJECT,
      payload: res.data,
    });
    dispatch(openSnackbar("Projet modifié avec succès", "Success"));
  } catch (e) {
    dispatch({
      type: ERROR_FETCHING_PROJECTS,
      payload: { error: e },
    });
  }
};
export const deleteProject = (user, data) => async (dispatch) => {
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${user.accessToken}`,
  };
  const url = `${process.env.REACT_APP_POPSER_API_URL}/project/${data.id}`;

  try {
    const res = await axios({ method: "delete", url, headers });
    if (res.status === 204) {
      dispatch({
        type: DELETE_PROJECT,
        payload: data,
      });
      dispatch(openSnackbar("Projet supprimé avec succès", "Success"));
    }
  } catch (e) {
    dispatch({
      type: ERROR_FETCHING_PROJECTS,
      payload: { error: e },
    });
  }
};
export const addCurrentProject = (currentProject) => async (dispatch) => {
  dispatch({
    type: ADD_CURRENT_PROJECT,
    payload: currentProject,
  });
};

export default fetchProjects;
