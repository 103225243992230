/* eslint-disable */
/* react/no-unescaped-entities */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import apiClient from "../../services/api/apiClient";
import { NOT_AUTHENTICATED_QUERY } from "../../services/constants/errors";
import Helmet from "react-helmet";
import { Trans, useTranslation } from "react-i18next";
import {
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { fetchProjects, addProject, updateProject, deleteProject } from "../../redux/actions/projectActions";
import AvatarComponent from "../../components/Avatar/AvatarComponent"
import ShareIcon from '@material-ui/icons/Share';
import {
  Backdrop,
  Button,
  Card as MuiCard,
  CardActions,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Fade,
  Grid,
  Modal,
  TextField,
  Typography as MuiTypography,
  Tooltip,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { ProjectContext } from "../../providers/ProjectProvider";
import ShareProject from "../../components/Project/ShareProject";
import { getProjetMembers } from "../../services/api/projectsApi";
import { useHistory } from "react-router-dom";;

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;
const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);
const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: "24px",
    marginBottom: "24px",
  },
  buttonRight: {
    float: "right",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "30%",
  },
  shareComponent: {
    position: "absolute",
    marginRight: "13px",
    right: "0",
    fontSize: "0.8125rem",
    fontWeight: "600",
    fontFamily: "Nunito,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",
  },
  shareIcon: {
    width: "20px!important",
    fontSize: "0.8125rem",
  },
  contentButton: {
    position: "relative",
  }
}));
function Project({ project, setIsLoad, index }) {

  const dispatch = useDispatch();
  const auth = useSelector(state => state.firebase.auth);
  const user = auth.stsTokenManager;
  const [isUpdateProject, setIsUpdateProject] = useState(false);
  const [notificationUpdateName, setNotificationUpdateName] = useState(null);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { handleSubmit, register, errors } = useForm();
  const [members, setMembers] = useState([]);

  // const [setProjectId] = useContext(ProjectContext);
  const [projectNameToBeUpdated, setProjectNameToBeUpdated] = useState(project.name);
  const [openShareProjectModal, setOpenShareProjectModal] = useState(false);
  useEffect(async () => {
    const project_members = await getProjetMembers(
      user.accessToken,
      project.id
    );
    setMembers(project_members);

  }, [project]);
  const updatingProject = async () => {
    project.name = projectNameToBeUpdated;
    await dispatch(updateProject(user, project));
    setIsUpdateProject(false);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      updatingProject(e);
    }
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event) => {
    setProjectNameToBeUpdated(event.target.value);
  };
  const submitDelete = async () => {
    await dispatch(deleteProject(user, project));

    return handleClose();
  };

  const getMemberFirstLetterOfEmail = (member) => { return member.member.email.substr(0, 1).toUpperCase() };
  return (
    <>
      <Card mb={6}>
        <form onSubmit={handleSubmit(updatingProject)}>

          <CardContent>
            {!isUpdateProject && (
              <Grid justify="space-around" container>
                <Grid item xs={6} >
                  <Typography gutterBottom variant="h5" component="h2">
                    {project.name}
                  </Typography>
                </Grid>
                <Grid item xs={2} />
                {members.map((member, key) => {
                  if (member.role !== "OWNER") {
                    return (
                      <Tooltip
                        disableFocusListener
                        title={member.member.email}
                      >
                        <Grid item xs={1} >
                          <AvatarComponent initial={getMemberFirstLetterOfEmail(member)} />
                        </Grid>
                      </Tooltip>)
                  }
                })
                }
              </Grid>

            )}
            {isUpdateProject && (
              <TextField
                margin="normal"
                fullWidth
                id="nom"
                name="nom"
                autoFocus
                value={projectNameToBeUpdated}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
            )}
            {notificationUpdateName && <p>{notificationUpdateName}</p>}
          </CardContent>
          {!isUpdateProject && (
            <CardActions className={classes.contentButton}>
              
              {/* <Button size="small" color="primary">
                <Trans i18nKey="Dupliquer">Dupliquer</Trans>
              </Button> */}
              <Button
                size="small"
                color="primary"
                onClick={() => setIsUpdateProject(true)}
              >
                <Trans i18nKey="Editer">Editer</Trans>
              </Button>
              <Button size="small" color="primary" onClick={handleOpen}>
                <Trans i18nKey="Supprimer">Supprimer</Trans>
              </Button>
              <Tooltip
                disableFocusListener
                title={t("Partager le projet")}
              >
                <IconButton size="small"
                  className={classes.shareComponent}
                  color="primary"
                  onClick={() => setOpenShareProjectModal(true)}
                >
                  <ShareIcon
                    fontSizeSmall="small"
                    className={classes.shareIcon}
                  />
                  <Trans i18nKey="Partager">Partager</Trans>
                </IconButton>
              </Tooltip>

            </CardActions>
          )}
          {isUpdateProject && (
            <CardActions>
              <Button size="small" color="primary" type="submit">
                <Trans i18nKey="Enregistrer">Enregistrer</Trans>
              </Button>
              <Button
                size="small"
                color="primary"
                onClick={() => setIsUpdateProject(false)}
              >
                <Trans i18nKey="Retour">Retour</Trans>
              </Button>
            </CardActions>
          )}
        </form>
      </Card>
      <ShareProject setOpenShareProjectModal={setOpenShareProjectModal} openShareProjectModal={openShareProjectModal} project={project} />
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open} className={classes.paper}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">
              <Trans i18nKey="Supprimer le projet">Supprimer le projet</Trans>
              <span> "</span>
              {project.name}
              <span>"</span>
            </h2>
            <Typography gutterBottom display="inline">
              <Trans i18nKey="Êtes-vous sûr(e) de vouloir le supprimer ?">Êtes-vous sûr(e) de vouloir le supprimer ?</Trans>
            </Typography>
            <form onSubmit={handleSubmit(submitDelete)}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  margin="normal"
                  name="confirm_delete"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={
                    <Trans i18nKey="Confirmer la suppression en écrivant le mot CONFIRMER">
                      Confirmer la suppression en écrivant le mot CONFIRMER
                    </Trans>
                  }
                  type="text"
                  id="confirm_delete"
                  error={"confirm_delete" in errors}
                  inputRef={register({
                    validate: (value) => value === "CONFIRMER",
                  })}
                  helperText={
                    "confirm_delete" in errors ? (
                      <Trans i18nKey="Le mot ne correspond pas">
                        Le mot ne correspond pas
                      </Trans>
                    ) : (
                      ""
                    )
                  }
                />
              </Grid>
              <Button
                type="submit"
                margin="normal"
                fullWidth
                variant="contained"
                color="primary"
              >
                <Trans i18nKey="Supprimer">Supprimer</Trans>
              </Button>
            </form>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
Project.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    owner: PropTypes.number.isRequired,
    vocabulary: PropTypes.string.isRequired,
  }).isRequired,
  setIsLoad: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

function Projects() {
  const { handleSubmit, register, errors } = useForm();
  const history = useHistory();

  const auth = useSelector(state => state.firebase.auth);
  const user = auth.stsTokenManager;
  const classes = useStyles();
  const { t } = useTranslation();

  const [isLoad, setIsLoad] = useState(false);
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects.projects);

  useEffect(() => {
    dispatch(fetchProjects(user));
  }, []);

  const [open, setOpen] = useState(false);
  const [notificationAddProject, setNotificationAddProject] = useState({
    vmessage: "",
    typemessage: "",
  });

  const handleOpen = () => {
    setNotificationAddProject({
      vmessage: "",
      typemessage: "",
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitData = async (data) => {
    await dispatch(addProject(user, { name: data.nom }));
    return setOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>{t("project")}</title>
      </Helmet>
      <Typography variant="h3" gutterBottom display="inline">
        <Trans i18nKey="Projets">Projets</Trans>
      </Typography>
      <Button
        className={classes.buttonRight}
        mr={2}
        variant="contained"
        color="primary"
        onClick={handleOpen}
      >
        <Trans i18nKey="Créer un projet">Créer un projet</Trans>

        <AddIcon />
      </Button>
      <Divider my={6} mr={24} className={classes.margin} />
      {projects && (
        <Grid container spacing={6}>
          {projects.length > 0 &&
            projects.map(function (project, index) {
              return (
                <Grid key={project.id} item xs={12} lg={6} xl={3}>
                  <Project
                    project={project}
                    setIsLoad={setIsLoad}
                    index={index}
                  />
                </Grid>
              );
            })}
          {projects.length === 0 && (
            <Grid item xs={12} lg={6} xl={3}>
              <p> Pas de projet créé</p>
            </Grid>
          )}
        </Grid>
      )}
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open} className={classes.paper}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">
              <Trans i18nKey="Créer un projet">Créer un projet</Trans>
            </h2>
            <form onSubmit={handleSubmit(submitData)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="nom"
                    label={<Trans i18nKey="Nom du projet">Nom du projet</Trans>}
                    name="nom"
                    autoFocus
                    inputRef={register({
                      required: "Required",
                    })}
                    error={"nom" in errors}
                    helperText={
                      "nom" in errors ? (
                        <Trans i18nKey="Veuillez entrer le nom de votre projet">
                          Veuillez entrer le nom de votre projet
                        </Trans>
                      ) : (
                        ""
                      )
                    }
                  />
                </Grid>
              </Grid>
              {notificationAddProject.vmessage && (
                <p>{notificationAddProject.vmessage}</p>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                <Trans i18nKey="Créer">Créer</Trans>
              </Button>
            </form>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

export default Projects;
