import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { makeStyles, Grid, TextField, Button } from "@material-ui/core";
import { Cancel as CancelIcon, Send as SendIcon } from "@material-ui/icons";
import { Trans, useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  addProjectComments,
  fetchProjectComments,
  fetchProjectCommentRead,
} from "../../../redux/actions/projectCommentsActions";
import { hide_comments } from "../../../redux/actions/managerFeedbackAction";
import MessageComponent from "./messageComponent";

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const useStyles = makeStyles((theme) => ({
  messageComponent: {
    maxHeight: "450px",
    overflowY: "scroll",
    [theme.breakpoints.between("xs", "sm")]: {
      maxHeight: "300px",
    },
  },
}));

function chatBoxComponent() {
  const { t } = useTranslation();
  const classes = useStyles();

  const auth = useSelector((state) => state.firebase.auth);
  const currentProject = useSelector((state) => state.projects.currentProject);

  const user = auth.stsTokenManager;

  const comments = useSelector((state) => state.projectComments.comments);
  const [myMessage, setMyMessage] = useState("");
  const { pathname } = window.location;
  const dispatch = useDispatch();

  useEffect(async () => {
    await dispatch(fetchProjectComments(user, currentProject.id, pathname));
    await dispatch(fetchProjectCommentRead(user, currentProject.id));
  }, [currentProject]);

  const addComment = async () => {
    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;
    await dispatch(
      addProjectComments(user, {
        comment: myMessage,
        dateTime: date,
        project: currentProject.id,
        url: pathname,
      })
    );
    setMyMessage("");
  };

  const hideComment = () => {
    dispatch(hide_comments());
    setMyMessage("");
  };

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} className={classes.messageComponent}>
        <Grid container spacing={5}>
          {comments
            .sort((a, b) => new Date(a.created_on) - new Date(b.created_on))
            .map((comment) => {
              if (comment.user !== null) {
                return (
                  <>
                    {comment.user.email === auth.email && <Grid item xs={2} />}
                    <Grid item xs={10}>
                      <Grid container justifyContent="flex-end">
                        <MessageComponent
                          comment={comment}
                          isMyMessage={comment.user.email === auth.email}
                        />
                      </Grid>
                    </Grid>
                    {comment.user.email !== auth.email && <Grid item xs={2} />}
                  </>
                );
              }
              return null;
            })}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Spacer />
          <Grid item xs={12}>
            <TextField
              id="outlined-multiline-static"
              label={t("Entrer votre message...")}
              multiline
              fullWidth
              onChange={(e) => setMyMessage(e.target.value)}
              value={myMessage}
              rows={4}
              variant="filled"
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              color="secondary"
              style={{ color: "#b91616" }}
              startIcon={<CancelIcon />}
              aria-label={t("Modifier mon message")}
              onClick={() => hideComment()}
            >
              <Trans i18nKey="Annuler">Annuler </Trans>
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ textAlign: "right" }}
            alignContent="right"
            justifyContent="flex-end"
          >
            <Button
              color="primary"
              aria-label={t("Modifier mon message")}
              endIcon={<SendIcon />}
              onClick={addComment}
            >
              <Trans i18nKey="Envoyer">Envoyer </Trans>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default chatBoxComponent;
