/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "../../redux/actions/snackbarActions";
import FieldWithSuffix from "../ElementsField/FieldWithSuffix";
import { interactionExtraCharge } from "../../services/product/TechnicalSheetCalculInteractionService";
import { updateTechnicalSheet } from "../../redux/actions/technicalSheetActions";

const useStyles = makeStyles(() => ({
  input: {
    "&::before": {},
    width: "70px",
  },
  root: {
    "& .MuiInput-underline:before": {
      borderBottom: "solid 1px",
    },
  },
}));

function RawCost({ control, errors, register }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;
  const { productId } = useParams();
  const currentProject = useSelector((state) => state.projects.currentProject);
  const technicalSheet = useSelector(
    (state) => state.technicalSheet.technicalSheet
  );

  const dispatch = useDispatch();

  const onChangeExtraCharge = (event) => {
    if (
      technicalSheet.listTotalPrice.value.length === 0 &&
      technicalSheet.materialCosts.value === 0
    ) {
      dispatch(
        openSnackbar(
          "Note: Veuillez remplir des prix pour vos matières première s'il vous plaît ",
          "Warning"
        )
      );
      return;
    }
    const resultTechnicalSheet = interactionExtraCharge(event.target.value);
    dispatch(
      updateTechnicalSheet(
        resultTechnicalSheet,
        user.accessToken,
        productId,
        currentProject.id
      )
    );
    dispatch(openSnackbar("Fiche technique modifiée avec succès", "Success"));
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Grid container spacing={2}>
          <Table>
            <TableBody className={classes.root}>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Trans i18nKey="Coût matières">Coût matières</Trans>
                </TableCell>
                <TableCell>
                  <FieldWithSuffix
                    disabled
                    name="raw_material_cost"
                    control={control}
                    errors={errors}
                    suffix={t("€")}
                    register={register}
                    value={technicalSheet.materialCosts.value}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Trans i18nKey="Majoration">Majoration</Trans>
                </TableCell>
                <TableCell>
                  <FieldWithSuffix
                    disabled={false}
                    name="extra_charge"
                    control={control}
                    onChangeField={onChangeExtraCharge}
                    errors={errors}
                    suffix={t("%")}
                    register={register}
                    defaultValue={technicalSheet.extraCharge.value}
                    value={technicalSheet.extraCharge.value || 0}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Trans i18nKey="Coût matières Total">
                    Coût matières Total
                  </Trans>
                </TableCell>
                <TableCell>
                  <FieldWithSuffix
                    disabled
                    name="total_raw_material_cost"
                    control={control}
                    errors={errors}
                    suffix={t("€")}
                    register={register}
                    value={technicalSheet.totalMaterialCost.value || 0}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Trans i18nKey="Coût unitaire">Coût unitaire</Trans>
                </TableCell>
                <TableCell>
                  <FieldWithSuffix
                    disabled
                    name="per_unit_price"
                    control={control}
                    errors={errors}
                    suffix={t("€")}
                    register={register}
                    value={technicalSheet.totalPricePortion.value}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Trans i18nKey="Total énergie par unité">
                    Total énergie par unité
                  </Trans>
                </TableCell>
                <TableCell>
                  <FieldWithSuffix
                    disabled
                    name="per_unit_energy"
                    control={control}
                    errors={errors}
                    suffix={t("")}
                    register={register}
                    value={technicalSheet.perUnitEnergy.value || 0}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </CardContent>
    </Card>
  );
}

RawCost.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
};

export default RawCost;
