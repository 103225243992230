import {
  CLEAR_RANGE,
  CLEAR_RANGES,
  FETCH_RANGE,
  FETCH_RANGES_SUCCESS,
  UPDATE_RANGE_SUCCESS,
  CLEAN_RANGE,
  PUT_RANGE_ERROR,
  PUT_RANGE_SUCCESS,
  EDIT_RANGE,
  EDIT_RANGE_SUCCESS,
  COMPUTE_RANGE_VALUES_SUCCESS,
  COMPUTE_INITIAL_VALUES,
} from "../types/rangeActionTypes";

const initialState = {
  range: {},
  ranges: [],
  rangeIsLoading: true,
  rangeListIsLoading: false,
  rangeInitialValuesComputed: false,
  mustUpdateRange: false,
  rangeEditInprogress: false,
};

const rangeReducer = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_RANGE:
      return {
        ...state,
        range: { ...state.range, ...action.payload.range },
        rangeEditInprogress: true,
      };
    case COMPUTE_INITIAL_VALUES:
      return {
        ...state,
        rangeInitialValuesComputed: true,
      };
    case COMPUTE_RANGE_VALUES_SUCCESS:
      return {
        ...state,
        range: action.payload,
      };
    case EDIT_RANGE_SUCCESS:
      return {
        ...state,
        rangeEditInprogress: false,
      };
    case FETCH_RANGE:
      return {
        ...state,
        range: action.payload.range,
        rangeIsLoading: action.payload.rangeIsLoading,
        rangeInitialValuesComputed: false,
      };
    case FETCH_RANGES_SUCCESS:
      return {
        ...state,
        ranges: action.payload,
        rangeIsLoading: false,
      };
    case UPDATE_RANGE_SUCCESS:
      return {
        ...state,
        range: action.payload.range,
        mustUpdateRange: action.payload.mustUpdateRange,
      };
    case PUT_RANGE_SUCCESS:
      return {
        ...state,
        rangeIsLoading: false,
        errors: action.payload,
      };
    case PUT_RANGE_ERROR:
      return { ...state };
    case CLEAR_RANGES:
      return {
        ...state,
        ranges: [],
      };
    case CLEAR_RANGE:
      return {
        ...state,
        range: {},
      };
    case CLEAN_RANGE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default rangeReducer;
