import {
  getResultByPercentage,
  getPercentagetByValue,
  getTotalProduct,
  getCalculProfitTax,
  getOperatingResult,
  getCurrentResultBeforeTaxRCAI,
  getIndicatorsNumberOfCustomersByDay,
} from "./SigCalculService";

function toFixedNumber(num, digits, base) {
  const pow = (base || 10) ** digits;
  return Math.round(num * pow) / pow;
}

// return Sig with new percentage part fixe
export function getSigWithPercentagePartFixeByDevisePartFixe(
  projectIntermediateManagementThreshold
) {
  return {
    ...projectIntermediateManagementThreshold,
    raw_material_purchases_percentage_part_fixe: getPercentagetByValue(
      projectIntermediateManagementThreshold.raw_material_purchases,
      projectIntermediateManagementThreshold.raw_material_purchases_devise_part_fixe
    ),
    change_in_inventories_raw_material_percentage_part_fixe: getPercentagetByValue(
      projectIntermediateManagementThreshold.change_in_inventories_raw_material,
      projectIntermediateManagementThreshold.change_in_inventories_raw_material_devise_part_fixe
    ),
    purchase_goods_percentage_part_fixe: getPercentagetByValue(
      projectIntermediateManagementThreshold.purchase_goods,
      projectIntermediateManagementThreshold.purchase_goods_devise_part_fixe
    ),
    change_in_inventories_purchase_goods_percentage_part_fixe: getPercentagetByValue(
      projectIntermediateManagementThreshold.change_in_inventories_purchase_goods,
      projectIntermediateManagementThreshold.change_in_inventories_purchase_goods_devise_part_fixe
    ),
    depreciation_amortization_percentage_part_fixe: getPercentagetByValue(
      projectIntermediateManagementThreshold.depreciation_amortization,
      projectIntermediateManagementThreshold.depreciation_amortization_devise_part_fixe
    ),
    financial_result_percentage_part_fixe: getPercentagetByValue(
      projectIntermediateManagementThreshold.financial_result,
      projectIntermediateManagementThreshold.financial_result_devise_part_fixe
    ),
    exceptional_result_percentage_part_fixe: getPercentagetByValue(
      projectIntermediateManagementThreshold.exceptional_result,
      projectIntermediateManagementThreshold.exceptional_result_devise_part_fixe
    ),
    profit_tax_percentage_part_fixe: getPercentagetByValue(
      projectIntermediateManagementThreshold.profit_tax,
      projectIntermediateManagementThreshold.profit_tax_devise_part_fixe
    ),
    taxes_duties_percentage_part_fixe: getPercentagetByValue(
      projectIntermediateManagementThreshold.taxes_duties,
      projectIntermediateManagementThreshold.taxes_duties_devise_part_fixe
    ),
    other_operating_expenses_percentage_part_fixe: getPercentagetByValue(
      projectIntermediateManagementThreshold.other_operating_expenses,
      projectIntermediateManagementThreshold.other_operating_expenses_devise_part_fixe
    ),
  };
}

// return Sig with new devise part fixe
export function getSigWithDevisePartFixeByPercentagePartFixe(
  projectIntermediateManagementThreshold
) {
  return {
    ...projectIntermediateManagementThreshold,
    raw_material_purchases_devise_part_fixe: getResultByPercentage(
      projectIntermediateManagementThreshold.raw_material_purchases,
      projectIntermediateManagementThreshold.raw_material_purchases_percentage_part_fixe
    ),
    change_in_inventories_raw_material_devise_part_fixe: getResultByPercentage(
      projectIntermediateManagementThreshold.change_in_inventories_raw_material,
      projectIntermediateManagementThreshold.change_in_inventories_raw_material_percentage_part_fixe
    ),
    purchase_goods_devise_part_fixe: getResultByPercentage(
      projectIntermediateManagementThreshold.purchase_goods,
      projectIntermediateManagementThreshold.purchase_goods_percentage_part_fixe
    ),
    change_in_inventories_purchase_goods_devise_part_fixe: getResultByPercentage(
      projectIntermediateManagementThreshold.change_in_inventories_purchase_goods,
      projectIntermediateManagementThreshold.change_in_inventories_purchase_goods_percentage_part_fixe
    ),
    depreciation_amortization_devise_part_fixe: getResultByPercentage(
      projectIntermediateManagementThreshold.depreciation_amortization,
      projectIntermediateManagementThreshold.depreciation_amortization_percentage_part_fixe
    ),
    financial_result_devise_part_fixe: getResultByPercentage(
      projectIntermediateManagementThreshold.financial_result,
      projectIntermediateManagementThreshold.financial_result_percentage_part_fixe
    ),
    exceptional_result_devise_part_fixe: getResultByPercentage(
      projectIntermediateManagementThreshold.exceptional_result,
      projectIntermediateManagementThreshold.exceptional_result_percentage_part_fixe
    ),
    profit_tax_devise_part_fixe: getResultByPercentage(
      projectIntermediateManagementThreshold.profit_tax,
      projectIntermediateManagementThreshold.profit_tax_percentage_part_fixe
    ),
    taxes_duties_devise_part_fixe: getResultByPercentage(
      projectIntermediateManagementThreshold.taxes_duties,
      projectIntermediateManagementThreshold.taxes_duties_percentage_part_fixe
    ),
    other_operating_expenses_devise_part_fixe: getResultByPercentage(
      projectIntermediateManagementThreshold.other_operating_expenses,
      projectIntermediateManagementThreshold.other_operating_expenses_percentage_part_fixe
    ),
  };
}
// return Sig interaction General Information
export function interactionGeneraInformation(
  projectIntermediateManagementThreshold
) {
  const number_customers_per_day = getIndicatorsNumberOfCustomersByDay(
    projectIntermediateManagementThreshold.number_customers_per_year,
    projectIntermediateManagementThreshold.number_days_open_per_year
  );

  return {
    ...projectIntermediateManagementThreshold,
    number_customers_per_day,
  };
}

// return Sig with new percentage
export function getSigWithPercentagetByValue(
  projectIntermediateManagementThreshold
) {
  const sumOtherProductAndNetTurnover = getTotalProduct(
    projectIntermediateManagementThreshold
  );

  const updateProjectIntermediateManagementThresholdToGeneralInformation = interactionGeneraInformation(
    projectIntermediateManagementThreshold
  );

  const updateProjectIntermediateManagementThreshold = getSigWithDevisePartFixeByPercentagePartFixe(
    updateProjectIntermediateManagementThresholdToGeneralInformation
  );
  return {
    ...updateProjectIntermediateManagementThreshold,
    others_products_percentage: toFixedNumber(
      getPercentagetByValue(
        sumOtherProductAndNetTurnover,
        projectIntermediateManagementThreshold.others_products
      ),
      2
    ),
    net_turnover_excl_tax_percentage: toFixedNumber(
      getPercentagetByValue(
        sumOtherProductAndNetTurnover,
        projectIntermediateManagementThreshold.net_turnover_excl_tax
      ),
      2
    ),
    production_used_percentage: toFixedNumber(
      getPercentagetByValue(
        sumOtherProductAndNetTurnover,
        projectIntermediateManagementThreshold.production_used
      ),
      2
    ),
    raw_material_purchases_percentage: toFixedNumber(
      getPercentagetByValue(
        sumOtherProductAndNetTurnover,
        projectIntermediateManagementThreshold.raw_material_purchases
      ),
      2
    ),
    change_in_inventories_raw_material_percentage: toFixedNumber(
      getPercentagetByValue(
        sumOtherProductAndNetTurnover,
        projectIntermediateManagementThreshold.change_in_inventories_raw_material
      ),
      2
    ),
    purchase_goods_percentage: toFixedNumber(
      getPercentagetByValue(
        sumOtherProductAndNetTurnover,
        projectIntermediateManagementThreshold.purchase_goods
      ),
      2
    ),
    change_in_inventories_purchase_goods_percentage: toFixedNumber(
      getPercentagetByValue(
        sumOtherProductAndNetTurnover,
        projectIntermediateManagementThreshold.change_in_inventories_purchase_goods
      ),
      2
    ),
    depreciation_amortization_percentage: toFixedNumber(
      getPercentagetByValue(
        sumOtherProductAndNetTurnover,
        projectIntermediateManagementThreshold.depreciation_amortization
      ),
      2
    ),
    financial_result_percentage: toFixedNumber(
      getPercentagetByValue(
        sumOtherProductAndNetTurnover,
        projectIntermediateManagementThreshold.financial_result
      ),
      2
    ),
    exceptional_result_percentage: toFixedNumber(
      getPercentagetByValue(
        sumOtherProductAndNetTurnover,
        projectIntermediateManagementThreshold.exceptional_result
      ),
      2
    ),
    profit_tax_percentage: toFixedNumber(
      getPercentagetByValue(
        sumOtherProductAndNetTurnover,
        projectIntermediateManagementThreshold.profit_tax
      ),
      2
    ),
    taxes_duties_percentage: toFixedNumber(
      getPercentagetByValue(
        sumOtherProductAndNetTurnover,
        projectIntermediateManagementThreshold.taxes_duties
      ),
      2
    ),
    other_operating_expenses_percentage: toFixedNumber(
      getPercentagetByValue(
        sumOtherProductAndNetTurnover,
        projectIntermediateManagementThreshold.other_operating_expenses
      ),
      2
    ),
  };
}

// return profit Tax
export function getProfitTax(projectIntermediateManagementThreshold) {
  const currentResult = getCurrentResultBeforeTaxRCAI(
    getOperatingResult(),
    projectIntermediateManagementThreshold.exceptional_result,
    projectIntermediateManagementThreshold.financial_result
  );

  const profitTax = getCalculProfitTax(
    currentResult,
    projectIntermediateManagementThreshold.tax_lower_tier,
    projectIntermediateManagementThreshold.tax_rate_lower_tier,
    projectIntermediateManagementThreshold.tax_rate_upper_tier
  );

  if (Number.isNaN(profitTax)) {
    return {
      ...projectIntermediateManagementThreshold,
    };
  }

  return getSigWithPercentagetByValue({
    ...projectIntermediateManagementThreshold,
    profit_tax: toFixedNumber(profitTax, 2),
  });
}
