import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import {
  getAvarageRequestedPrice,
  getAveragePrice,
  getIndiceReponsePrix,
  getRecommandedAveragePriceRange,
  getThirdPrincipleInterpretation,
} from "../../services/range/RangeBalanceService";
import {
  getListProductPrices,
  getListQuantitySold,
} from "../../services/range/RangeMethodService";
import NotEnoughData from "./NotEngoughData";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  margin: {
    margin: "12px",
  },
  padding12: {
    padding: "12px",
  },
});

function createData(a, b) {
  return { a, b };
}

export default function ThirdOmnesPrinciple() {
  const classes = useStyles();

  const rangeProducts = useSelector(
    (state) => state.rangeProducts.rangeProducts
  );

  const allProductPrices = getListProductPrices(rangeProducts);

  const allProductQuantitiesSold = getListQuantitySold(rangeProducts);

  const rows = [
    createData(
      "Prix moyen offert",
      `${getAveragePrice({ allProductPrices })} €`
    ),
    createData(
      "Prix moyen demandé",
      `${getAvarageRequestedPrice({
        allProductPrices,
        allProductQuantitiesSold,
      })} €`
    ),
    createData(
      "Indice réponse/Prix",
      getIndiceReponsePrix({ allProductPrices, allProductQuantitiesSold })
    ),
  ];

  return (
    <Grid container>
      <Grid container item xs={6} className={classes.padding12}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Troisième principe d&apos;Omnes</TableCell>
                <TableCell align="left">Indice réponse/Prix</TableCell>
              </TableRow>
            </TableHead>
            {rangeProducts.length > 0 ? (
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.a}>
                    <TableCell component="th" scope="row">
                      {row.a}
                    </TableCell>
                    <TableCell align="left">{row.b}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <NotEnoughData />
            )}
          </Table>
        </TableContainer>
      </Grid>
      <Grid container xs={6} className={classes.padding12}>
        <Grid item xs={12}>
          <p>Interprétation</p>
          <p>
            {getThirdPrincipleInterpretation({
              allProductPrices,
              allProductQuantitiesSold,
            })}
            <br />
            <br />
            {getRecommandedAveragePriceRange({
              allProductPrices,
              allProductQuantitiesSold,
            })}
          </p>
        </Grid>
      </Grid>
    </Grid>
  );
}

ThirdOmnesPrinciple.propTypes = {
  range: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    products: PropTypes.shape({}),
    sales_revenue_TTC: PropTypes.number,
    sales_revenue_HT: PropTypes.number,
    material_consumption_HT: PropTypes.number,
    gross_margin: PropTypes.number,
    average_price_offered_TTC: PropTypes.number,
    average_material_ratio: PropTypes.number,
    range_multiplier_coefficient: PropTypes.number,
    coefficient_k: PropTypes.number,
    cser_range: PropTypes.string,
    average_price_requested_TTC: PropTypes.number,
    variation_popularity: PropTypes.number,
    variation_rentability: PropTypes.number,
    price_TTC: PropTypes.number,
    quantity_sold: PropTypes.number,
    quantity_check: PropTypes.number,
    number_days_presentation: PropTypes.number,
    number_check_total: PropTypes.number,
    number_option_check_range: PropTypes.number,
    number_day_by_year: PropTypes.number,
    number_custom_by_day: PropTypes.number,
    number_custom_by_year_number_check_total: PropTypes.number,
    number_custom_by_year_number_option_check_range: PropTypes.number,
    tva_rate: PropTypes.number,
  }).isRequired,
};
