/* eslint-disable radix */
import React from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import {
  Card as MuiCard,
  CardContent,
  Typography,
  makeStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

import { useForm } from "react-hook-form";

import { spacing } from "@material-ui/system";

import { useDispatch, useSelector } from "react-redux";
import { updateUserAction } from "../../redux/actions/userActions";
import { editingProjectIntermediateManagementThreshold } from "../../redux/actions/sigActions";
import FieldWithSuffix from "../ElementsField/FieldWithSuffix";
import {
  getSigWithPercentagetByValue,
  getSigWithPercentagePartFixeByDevisePartFixe,
  getSigWithDevisePartFixeByPercentagePartFixe,
} from "../../services/sig/SigMethodService";
import {
  getConsumedMaterials,
  getPercentageOnTotalProduct,
  getTotalProduct,
  getConsumedMaterialsDevisePartFixe,
} from "../../services/sig/SigCalculService";

const Card = styled(MuiCard)(spacing);

const useStyles = makeStyles({
  margin: {
    marginTop: "12px",
  },
  input: {
    width: "90px!important",
  },
  gridInput: {
    borderLeft: "solid 1px",
    paddingLeft: "30px",
    borderBottom: "solid 1px",
  },
  gridTitle: {
    borderBottom: "solid 1px",
    paddingBottom: "11px",
    paddingTop: "21px",
  },
  tableEqualWidth: {
    "& th": { width: "20%!important" },
  },
  iconEdit: {
    fontSize: "1em",
    color: "#1876d1bd",
  },
  card: {
    paddingBottom: "0px!important",
  },
  gridColor: {
    backgroundColor: "#1876d121",
  },
  componentSig: {
    marginBottom: "0px!important",
  },
  noPaddingTh: {
    "& th": {
      padding: "0px",
    },
  },
  paddingThTd: {
    "& th": {
      paddingTop: "5px!important",
      paddingBottom: "5px!important",
    },
    "& td": {
      paddingTop: "5px!important",
      paddingBottom: "5px!important",
    },
  },
  disableField: {
    "& td div": {
      color: "black!important",
      fontWeight: "600",
    },
  },
});
const ConsumedMaterials = () => {
  const { register, control, errors } = useForm();
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sig = useSelector((state) => state.sig.sig);
  const handleUserUpdateValue = (ProjectIntermediateManagementThreshold) => {
    dispatch(
      editingProjectIntermediateManagementThreshold(
        getSigWithPercentagetByValue(ProjectIntermediateManagementThreshold)
      )
    );
    dispatch(updateUserAction(true));
  };
  const handleUserUpdateDevisePartFixe = (
    ProjectIntermediateManagementThreshold
  ) => {
    dispatch(
      editingProjectIntermediateManagementThreshold(
        getSigWithPercentagePartFixeByDevisePartFixe(
          ProjectIntermediateManagementThreshold
        )
      )
    );
    dispatch(updateUserAction(true));
  };
  const handleUserUpdatePercentagePartFixe = (
    ProjectIntermediateManagementThreshold
  ) => {
    dispatch(
      editingProjectIntermediateManagementThreshold(
        getSigWithDevisePartFixeByPercentagePartFixe(
          ProjectIntermediateManagementThreshold
        )
      )
    );
    dispatch(updateUserAction(true));
  };
  return (
    <Card mb={12} className={classes.componentSig}>
      <CardContent className={classes.card}>
        <TableContainer component={Paper}>
          <Table
            className={[classes.table, classes.tableEqualWidth]}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow className={[classes.noPaddingTh]}>
                <TableCell />
                <TableCell align="center">
                  <Trans i18nKey="Montant en (en €)">Montant en (en €)</Trans>
                </TableCell>
                <TableCell align="center">%</TableCell>
                <TableCell align="center">
                  <Trans>Part fixe (en €)</Trans>
                </TableCell>
                <TableCell align="center">
                  <Trans>Part fixe (en %)</Trans>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.paddingThTd}>
              <TableRow
                key="ConsumedMaterials"
                className={[classes.gridColor, classes.disableField]}
              >
                <TableCell component="th" scope="row">
                  <Typography variant="h5" gutterBottom display="inline">
                    <Trans i18nKey="Matières consommées">
                      Matières consommées
                    </Trans>
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <FieldWithSuffix
                    disabled
                    name="Consumed_materials"
                    control={control}
                    errors={errors}
                    suffix={t("€")}
                    value={getConsumedMaterials(sig)}
                    register={register}
                  />
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <FieldWithSuffix
                    disabled
                    name="Consumed_materials_percentage"
                    control={control}
                    errors={errors}
                    suffix={t("%")}
                    value={getPercentageOnTotalProduct(
                      getTotalProduct(sig),
                      getConsumedMaterials(sig)
                    )}
                    register={register}
                  />
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <FieldWithSuffix
                    disabled
                    name="Consumed_materials_percentage"
                    control={control}
                    errors={errors}
                    suffix={t("€")}
                    value={getConsumedMaterialsDevisePartFixe(sig)}
                    register={register}
                  />
                </TableCell>
                <TableCell align="right" />
              </TableRow>
              <TableRow key="raw_material_purchases">
                <TableCell component="th" scope="row">
                  <Typography variant="h9" gutterBottom display="inline">
                    <Trans i18nKey="raw material purchases">
                      Achats de Matières
                    </Trans>
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <FieldWithSuffix
                    isEdit
                    disabled={false}
                    name="raw_material_purchases"
                    control={control}
                    errors={errors}
                    suffix={t("€")}
                    value={sig.raw_material_purchases}
                    onChangeField={
                      (e) =>
                        handleUserUpdateValue({
                          ...sig,
                          raw_material_purchases: e.target.value.slice(
                            0,
                            e.target.value.length - 1
                          ),
                        })
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    defaultValue={0}
                    register={register}
                  />
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <FieldWithSuffix
                    disabled
                    name="raw_material_purchases_percentage"
                    control={control}
                    errors={errors}
                    suffix={t("%")}
                    value={sig.raw_material_purchases_percentage}
                    defaultValue={0}
                    register={register}
                  />
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <FieldWithSuffix
                    isEdit
                    disabled={false}
                    name="raw_material_purchases_devise_part_fixe"
                    control={control}
                    errors={errors}
                    suffix={t("€")}
                    value={sig.raw_material_purchases_devise_part_fixe}
                    onChangeField={
                      (e) =>
                        handleUserUpdateDevisePartFixe({
                          ...sig,
                          raw_material_purchases_devise_part_fixe: e.target.value.slice(
                            0,
                            e.target.value.length - 1
                          ),
                        })
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    defaultValue={0}
                    register={register}
                  />
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <FieldWithSuffix
                    isEdit
                    disabled={false}
                    name="raw_material_purchases_percentage_part_fixe"
                    control={control}
                    errors={errors}
                    suffix={t("%")}
                    value={sig.raw_material_purchases_percentage_part_fixe}
                    onChangeField={
                      (e) =>
                        handleUserUpdatePercentagePartFixe({
                          ...sig,
                          raw_material_purchases_percentage_part_fixe: e.target.value.slice(
                            0,
                            e.target.value.length - 1
                          ),
                        })
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    defaultValue={0}
                    register={register}
                  />
                </TableCell>
              </TableRow>
              <TableRow key="change_in_inventories_raw_material">
                <TableCell component="th" scope="row">
                  <Typography variant="h9" gutterBottom display="inline">
                    <Trans i18nKey="Variations de Stocks">
                      Variations de Stocks
                    </Trans>
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <FieldWithSuffix
                    isEdit
                    disabled={false}
                    name="change_in_inventories_raw_material"
                    control={control}
                    errors={errors}
                    suffix={t("€")}
                    value={sig.change_in_inventories_raw_material}
                    onChangeField={
                      (e) =>
                        handleUserUpdateValue({
                          ...sig,
                          change_in_inventories_raw_material: e.target.value.slice(
                            0,
                            e.target.value.length - 1
                          ),
                        })
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    defaultValue={0}
                    register={register}
                  />
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <FieldWithSuffix
                    disabled
                    name="raw_material_purchases_percentage"
                    control={control}
                    errors={errors}
                    suffix={t("%")}
                    value={sig.change_in_inventories_raw_material_percentage}
                    defaultValue={0}
                    register={register}
                  />
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <FieldWithSuffix
                    isEdit
                    disabled={false}
                    name="change_in_inventories_raw_material_devise_part_fixe"
                    control={control}
                    errors={errors}
                    suffix={t("€")}
                    value={
                      sig.change_in_inventories_raw_material_devise_part_fixe
                    }
                    onChangeField={
                      (e) =>
                        handleUserUpdateDevisePartFixe({
                          ...sig,
                          change_in_inventories_raw_material_devise_part_fixe: e.target.value.slice(
                            0,
                            e.target.value.length - 1
                          ),
                        })
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    defaultValue={0}
                    register={register}
                  />
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <FieldWithSuffix
                    isEdit
                    disabled={false}
                    name="change_in_inventories_raw_material_percentage_part_fixe"
                    control={control}
                    errors={errors}
                    suffix={t("%")}
                    value={
                      sig.change_in_inventories_raw_material_percentage_part_fixe
                    }
                    onChangeField={
                      (e) =>
                        handleUserUpdatePercentagePartFixe({
                          ...sig,
                          change_in_inventories_raw_material_percentage_part_fixe: e.target.value.slice(
                            0,
                            e.target.value.length - 1
                          ),
                        })
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    defaultValue={0}
                    register={register}
                  />
                </TableCell>
              </TableRow>
              <TableRow key="purchase_goods">
                <TableCell component="th" scope="row">
                  <Typography variant="h9" gutterBottom display="inline">
                    <Trans i18nKey="Achats de Marchandises">
                      Achats de Marchandises
                    </Trans>
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <FieldWithSuffix
                    isEdit
                    disabled={false}
                    name="purchase_goods"
                    control={control}
                    errors={errors}
                    suffix={t("€")}
                    value={sig.purchase_goods}
                    onChangeField={
                      (e) =>
                        handleUserUpdateValue({
                          ...sig,
                          purchase_goods: e.target.value.slice(
                            0,
                            e.target.value.length - 1
                          ),
                        })
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    defaultValue={0}
                    register={register}
                  />
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <FieldWithSuffix
                    disabled
                    name="purchase_goods_percentage"
                    control={control}
                    errors={errors}
                    suffix={t("%")}
                    value={sig.purchase_goods_percentage}
                    defaultValue={0}
                    register={register}
                  />
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <FieldWithSuffix
                    isEdit
                    disabled={false}
                    name="purchase_goods_devise_part_fixe"
                    control={control}
                    errors={errors}
                    suffix={t("€")}
                    value={sig.purchase_goods_devise_part_fixe}
                    onChangeField={
                      (e) =>
                        handleUserUpdateDevisePartFixe({
                          ...sig,
                          purchase_goods_devise_part_fixe: e.target.value.slice(
                            0,
                            e.target.value.length - 1
                          ),
                        })
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    defaultValue={0}
                    register={register}
                  />
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <FieldWithSuffix
                    isEdit
                    disabled={false}
                    name="purchase_goods_percentage_part_fixe"
                    control={control}
                    errors={errors}
                    suffix={t("%")}
                    value={sig.purchase_goods_percentage_part_fixe}
                    onChangeField={
                      (e) =>
                        handleUserUpdatePercentagePartFixe({
                          ...sig,
                          purchase_goods_percentage_part_fixe: e.target.value.slice(
                            0,
                            e.target.value.length - 1
                          ),
                        })
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    defaultValue={0}
                    register={register}
                  />
                </TableCell>
              </TableRow>
              <TableRow key="change_in_inventories_purchase_goods">
                <TableCell component="th" scope="row">
                  <Typography variant="h9" gutterBottom display="inline">
                    <Trans i18nKey="Variations de Stocks">
                      Variations de Stocks
                    </Trans>
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <FieldWithSuffix
                    isEdit
                    disabled={false}
                    name="change_in_inventories_purchase_goods"
                    control={control}
                    errors={errors}
                    suffix={t("€")}
                    value={sig.change_in_inventories_purchase_goods}
                    onChangeField={
                      (e) =>
                        handleUserUpdateValue({
                          ...sig,
                          change_in_inventories_purchase_goods: e.target.value.slice(
                            0,
                            e.target.value.length - 1
                          ),
                        })
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    defaultValue={0}
                    register={register}
                  />
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <FieldWithSuffix
                    disabled
                    name="change_in_inventories_purchase_goods_percentage"
                    control={control}
                    errors={errors}
                    suffix={t("%")}
                    value={sig.change_in_inventories_purchase_goods_percentage}
                    defaultValue={0}
                    register={register}
                  />
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <FieldWithSuffix
                    isEdit
                    disabled={false}
                    name="change_in_inventories_purchase_goods_devise_part_fixe"
                    control={control}
                    errors={errors}
                    suffix={t("€")}
                    value={
                      sig.change_in_inventories_purchase_goods_devise_part_fixe
                    }
                    onChangeField={
                      (e) =>
                        handleUserUpdateDevisePartFixe({
                          ...sig,
                          change_in_inventories_purchase_goods_devise_part_fixe: e.target.value.slice(
                            0,
                            e.target.value.length - 1
                          ),
                        })
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    defaultValue={0}
                    register={register}
                  />
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <FieldWithSuffix
                    isEdit
                    disabled={false}
                    name="change_in_inventories_purchase_goods_percentage_part_fixe"
                    control={control}
                    errors={errors}
                    suffix={t("%")}
                    value={
                      sig.change_in_inventories_purchase_goods_percentage_part_fixe
                    }
                    onChangeField={
                      (e) =>
                        handleUserUpdatePercentagePartFixe({
                          ...sig,
                          change_in_inventories_purchase_goods_percentage_part_fixe: e.target.value.slice(
                            0,
                            e.target.value.length - 1
                          ),
                        })
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    defaultValue={0}
                    register={register}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};
export default ConsumedMaterials;
