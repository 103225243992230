/* eslint-disable radix */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import {
  Card as MuiCard,
  CardContent,
  Typography,
  makeStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

import { useForm } from "react-hook-form";

import { spacing } from "@material-ui/system";

import { useDispatch, useSelector } from "react-redux";
import { updateUserAction } from "../../redux/actions/userActions";
import { editingProjectIntermediateManagementThreshold } from "../../redux/actions/sigActions";
import FieldWithSuffix from "../ElementsField/FieldWithSuffix";
import {
  getSigWithPercentagetByValue,
  getSigWithPercentagePartFixeByDevisePartFixe,
  getSigWithDevisePartFixeByPercentagePartFixe,
  getProfitTax,
} from "../../services/sig/SigMethodService";
import {
  getTotalProduct,
  getPercentageOnTotalProduct,
  getOperatingResult,
  getCurrentResultBeforeTaxRCAI,
} from "../../services/sig/SigCalculService";

const Card = styled(MuiCard)(spacing);

const useStyles = makeStyles({
  margin: {
    marginTop: "12px",
  },
  input: {
    width: "90px!important",
  },
  gridInput: {
    borderLeft: "solid 1px",
    paddingLeft: "30px",
    borderBottom: "solid 1px",
  },
  gridTitle: {
    borderBottom: "solid 1px",
    paddingBottom: "11px",
    paddingTop: "21px",
  },
  tableEqualWidth: {
    "& th": { width: "20%!important" },
  },
  tableEqualWidthTotal: {
    "& th": { width: "20%!important", border: "0px!important" },
    "& td": { border: "0px!important" },
  },

  iconEdit: {
    fontSize: "1em",
    color: "#1876d1bd",
  },
  card: {
    paddingBottom: "0px!important",
  },
  gridColor: {
    backgroundColor: "#1876d121",
  },
  gridColorTotal: {
    backgroundColor: "#1b2430",
  },
  componentSig: {
    marginBottom: "0px!important",
  },
  noPadding: {
    paddingBottom: "0px",
    paddingTop: "0px",
  },
  noPaddingTh: {
    "& th": {
      padding: "0px",
    },
  },
  paddingThTd: {
    "& th": {
      paddingTop: "5px!important",
      paddingBottom: "5px!important",
    },
    "& td": {
      paddingTop: "5px!important",
      paddingBottom: "5px!important",
    },
  },
  colorShadow: {
    backgroundColor: "#1b2430",

    "& input": {
      color: "white",
      fontSize: "medium",
      fontWeight: "500",
      marginBottom: "5px",
    },
    "& h5": {
      color: "white",
    },
  },
});
const CurrentResultBeforeTaxRCAI = () => {
  const { register, control, errors } = useForm();
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sig = useSelector((state) => state.sig.sig);
  const workforce_costs_summary = useSelector(
    (state) => state.sig.workforce_costs_summary
  );
  const [currentResultBeforeTaxRCAI, setCurrentResultBeforeTaxRCAI] = useState({
    value: null,
    percentage: null,
  });

  useEffect(() => {
    setCurrentResultBeforeTaxRCAI({
      value: getCurrentResultBeforeTaxRCAI(
        getOperatingResult(),
        sig.exceptional_result,
        sig.financial_result
      ),
      percentage: getPercentageOnTotalProduct(
        getTotalProduct(sig),
        getCurrentResultBeforeTaxRCAI(
          getOperatingResult(),
          sig.exceptional_result,
          sig.financial_result
        )
      ),
    });
  }, [workforce_costs_summary, sig]);

  const handleUserUpdateValue = (ProjectIntermediateManagementThreshold) => {
    dispatch(
      editingProjectIntermediateManagementThreshold({
        ...getSigWithPercentagetByValue(ProjectIntermediateManagementThreshold),
        ...getProfitTax(ProjectIntermediateManagementThreshold),
      })
    );
    dispatch(updateUserAction(true));
  };
  const handleUserUpdateDevisePartFixe = (
    ProjectIntermediateManagementThreshold
  ) => {
    dispatch(
      editingProjectIntermediateManagementThreshold(
        getSigWithPercentagePartFixeByDevisePartFixe(
          ProjectIntermediateManagementThreshold
        )
      )
    );
    dispatch(updateUserAction(true));
  };
  const handleUserUpdatePercentagePartFixe = (
    ProjectIntermediateManagementThreshold
  ) => {
    dispatch(
      editingProjectIntermediateManagementThreshold(
        getSigWithDevisePartFixeByPercentagePartFixe(
          ProjectIntermediateManagementThreshold
        )
      )
    );
    dispatch(updateUserAction(true));
  };
  return (
    <>
      <Card mb={12} className={classes.componentSig}>
        <CardContent className={classes.card}>
          <TableContainer component={Paper}>
            <Table
              className={[classes.table, classes.tableEqualWidth]}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow className={[classes.noPaddingTh]}>
                  <TableCell />
                  <TableCell align="center">
                    <Trans i18nKey="Montant en (en €)">Montant en (en €)</Trans>
                  </TableCell>
                  <TableCell align="center">%</TableCell>
                  <TableCell align="center">
                    <Trans>Part fixe (en €)</Trans>
                  </TableCell>
                  <TableCell align="center">
                    <Trans>Part fixe (en %)</Trans>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.paddingThTd}>
                <TableRow key="financial_result">
                  <TableCell component="th" scope="row">
                    <Typography variant="h9" gutterBottom display="inline">
                      <Trans i18nKey="Résultat financier (produits - charges financières)">
                        Résultat financier (produits - charges financières)
                      </Trans>
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    <FieldWithSuffix
                      isEdit
                      disabled={false}
                      name="financial_result"
                      control={control}
                      errors={errors}
                      suffix={t("€")}
                      value={sig.financial_result}
                      onChangeField={
                        (e) =>
                          handleUserUpdateValue({
                            ...sig,
                            financial_result: e.target.value.slice(
                              0,
                              e.target.value.length - 1
                            ),
                          })
                        // eslint-disable-next-line react/jsx-curly-newline
                      }
                      defaultValue={0}
                      register={register}
                    />
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    <FieldWithSuffix
                      disabled
                      name="financial_result_percentage"
                      control={control}
                      errors={errors}
                      suffix={t("%")}
                      value={sig.financial_result_percentage}
                      defaultValue={0}
                      register={register}
                    />
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    <FieldWithSuffix
                      isEdit
                      disabled={false}
                      name="financial_result_devise_part_fixe"
                      control={control}
                      errors={errors}
                      suffix={t("€")}
                      value={sig.financial_result_devise_part_fixe}
                      onChangeField={
                        (e) =>
                          handleUserUpdateDevisePartFixe({
                            ...sig,
                            financial_result_devise_part_fixe: e.target.value.slice(
                              0,
                              e.target.value.length - 1
                            ),
                          })
                        // eslint-disable-next-line react/jsx-curly-newline
                      }
                      defaultValue={0}
                      register={register}
                    />
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    <FieldWithSuffix
                      isEdit
                      disabled={false}
                      name="financial_result_percentage_part_fixe"
                      control={control}
                      errors={errors}
                      suffix={t("%")}
                      value={sig.financial_result_percentage_part_fixe}
                      onChangeField={
                        (e) =>
                          handleUserUpdatePercentagePartFixe({
                            ...sig,
                            financial_result_percentage_part_fixe: e.target.value.slice(
                              0,
                              e.target.value.length - 1
                            ),
                          })
                        // eslint-disable-next-line react/jsx-curly-newline
                      }
                      defaultValue={0}
                      register={register}
                    />
                  </TableCell>
                </TableRow>
                <TableRow key="exceptional_result">
                  <TableCell component="th" scope="row">
                    <Typography variant="h9" gutterBottom display="inline">
                      <Trans i18nKey="Résultat exceptionnel (produits - charges exceptionnelles)">
                        Résultat exceptionnel (produits - charges
                        exceptionnelles)
                      </Trans>
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    <FieldWithSuffix
                      isEdit
                      disabled={false}
                      name="exceptional_result"
                      control={control}
                      errors={errors}
                      suffix={t("€")}
                      value={sig.exceptional_result}
                      onChangeField={
                        (e) =>
                          handleUserUpdateValue({
                            ...sig,
                            exceptional_result: e.target.value.slice(
                              0,
                              e.target.value.length - 1
                            ),
                          })
                        // eslint-disable-next-line react/jsx-curly-newline
                      }
                      defaultValue={0}
                      register={register}
                    />
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    <FieldWithSuffix
                      disabled
                      name="exceptional_result_percentage"
                      control={control}
                      errors={errors}
                      suffix={t("%")}
                      value={sig.exceptional_result_percentage}
                      defaultValue={0}
                      register={register}
                    />
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    <FieldWithSuffix
                      isEdit
                      disabled={false}
                      name="exceptional_result_devise_part_fixe"
                      control={control}
                      errors={errors}
                      suffix={t("€")}
                      value={sig.exceptional_result_devise_part_fixe}
                      onChangeField={
                        (e) =>
                          handleUserUpdateDevisePartFixe({
                            ...sig,
                            exceptional_result_devise_part_fixe: e.target.value.slice(
                              0,
                              e.target.value.length - 1
                            ),
                          })
                        // eslint-disable-next-line react/jsx-curly-newline
                      }
                      defaultValue={0}
                      register={register}
                    />
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    <FieldWithSuffix
                      isEdit
                      disabled={false}
                      name="exceptional_result_percentage_part_fixe"
                      control={control}
                      errors={errors}
                      suffix={t("%")}
                      value={sig.exceptional_result_percentage_part_fixe}
                      onChangeField={
                        (e) =>
                          handleUserUpdatePercentagePartFixe({
                            ...sig,
                            exceptional_result_percentage_part_fixe: e.target.value.slice(
                              0,
                              e.target.value.length - 1
                            ),
                          })
                        // eslint-disable-next-line react/jsx-curly-newline
                      }
                      defaultValue={0}
                      register={register}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <Card mb={12}>
        <CardContent
          className={[classes.card, classes.noPadding, classes.colorShadow]}
        >
          <TableContainer component={Paper}>
            <Table
              className={[classes.table, classes.tableEqualWidthTotal]}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow className={[classes.noPaddingTh]}>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody className={classes.paddingThTd}>
                <TableRow
                  key="current_result_before_tax_RCAI"
                  className={classes.gridColorTotal}
                >
                  <TableCell component="th" scope="row">
                    <Typography variant="h5" gutterBottom display="inline">
                      <Trans i18nKey="Résultat Courant avant Impôt RCAI :">
                        Résultat Courant avant Impôt RCAI :
                      </Trans>
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    <FieldWithSuffix
                      disabled
                      name="current_result_before_tax_RCAI"
                      control={control}
                      errors={errors}
                      suffix={t("€")}
                      value={currentResultBeforeTaxRCAI.value}
                      register={register}
                    />
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    <FieldWithSuffix
                      disabled
                      name="current_result_before_tax_RCAI_percentage"
                      control={control}
                      errors={errors}
                      suffix={t("%")}
                      value={currentResultBeforeTaxRCAI.percentage}
                      register={register}
                    />
                  </TableCell>
                  <TableCell align="right" />
                  <TableCell align="right" />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
};
export default CurrentResultBeforeTaxRCAI;
