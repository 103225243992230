import { CSER_URL } from "../../services/constants/urls";
import {
  FETCH_CSER,
  FETCH_CSER_ERROR,
  PUT_CSER,
  PUT_CSER_ERROR,
} from "../types/cserActionTypes";
import { LOADING_ACTION } from "../types/settingsActionTypes";

import apiClient from "../../services/api/apiClient";

export const fetchCser = (project_id, accessToken) => async (dispatch) => {
  await dispatch({
    type: LOADING_ACTION,
    payload: true,
  });
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${CSER_URL}?project_id=${project_id}`;
  try {
    const res = await apiClient({ method: "get", url, headers });
    if (res.data.length > 0) {
      await dispatch({
        type: FETCH_CSER,
        payload: res.data[0],
      });
    }
    await dispatch({
      type: LOADING_ACTION,
      payload: false,
    });
  } catch (e) {
    await dispatch({
      type: FETCH_CSER_ERROR,
      payload: e,
    });

    await dispatch({
      type: LOADING_ACTION,
      payload: false,
    });
  }
};

export const putCser = (cser_data, accessToken) => async (dispatch) => {
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${CSER_URL}${cser_data.uuid}/`;
  try {
    const res = await apiClient({
      method: "PUT",
      url,
      headers,
      data: cser_data,
    });
    dispatch({
      type: PUT_CSER,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: PUT_CSER_ERROR,
      payload: e,
    });
  }
};
