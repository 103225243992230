/* eslint-disable radix */
import React from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import {
  Card as MuiCard,
  CardContent,
  Typography,
  makeStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

import { useForm } from "react-hook-form";

import { spacing } from "@material-ui/system";

import { useDispatch, useSelector } from "react-redux";
import { updateUserAction } from "../../redux/actions/userActions";
import { editingProjectIntermediateManagementThreshold } from "../../redux/actions/sigActions";
import FieldWithSuffix from "../ElementsField/FieldWithSuffix";
import { getSigWithPercentagetByValue } from "../../services/sig/SigMethodService";
import { getTotalProduct } from "../../services/sig/SigCalculService";

const Card = styled(MuiCard)(spacing);

const useStyles = makeStyles({
  margin: {
    marginTop: "12px",
  },
  input: {
    width: "90px!important",
  },
  gridInput: {
    borderLeft: "solid 1px",
    paddingLeft: "30px",
    borderBottom: "solid 1px",
  },
  gridTitle: {
    borderBottom: "solid 1px",
    paddingBottom: "11px",
    paddingTop: "21px",
  },
  tableEqualWidth: {
    "& th": { width: "20%!important" },
  },
  iconEdit: {
    fontSize: "1em",
    color: "#1876d1bd",
  },
  card: {
    paddingBottom: "0px!important",
  },
  gridColor: {
    backgroundColor: "#1876d121",
  },
  componentSig: {
    marginBottom: "0px!important",
  },
  noPaddingTh: {
    "& th": {
      padding: "0px",
    },
  },
  paddingThTd: {
    "& th": {
      paddingTop: "5px!important",
      paddingBottom: "5px!important",
    },
    "& td": {
      paddingTop: "5px!important",
      paddingBottom: "5px!important",
    },
  },
  disableField: {
    "& td div": {
      color: "black!important",
      fontWeight: "600",
    },
  },
});
const TotalProduct = () => {
  const { register, control, errors } = useForm();
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sig = useSelector((state) => state.sig.sig);

  const handleUserUpdateValue = (ProjectIntermediateManagementThreshold) => {
    dispatch(
      editingProjectIntermediateManagementThreshold(
        getSigWithPercentagetByValue(ProjectIntermediateManagementThreshold)
      )
    );
    dispatch(updateUserAction(true));
  };

  return (
    <Card mb={12} className={classes.componentSig}>
      <CardContent className={classes.card}>
        <TableContainer component={Paper}>
          <Table
            className={[classes.table, classes.tableEqualWidth]}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow className={[classes.noPaddingTh]}>
                <TableCell />
                <TableCell align="center">
                  <Trans i18nKey="Montant en (en €)">Montant en (en €)</Trans>
                </TableCell>
                <TableCell align="center">%</TableCell>
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody className={classes.paddingThTd}>
              <TableRow
                key="total_product"
                className={[classes.gridColor, classes.disableField]}
              >
                <TableCell component="th" scope="row">
                  <Typography variant="h5" gutterBottom display="inline">
                    <Trans i18nKey="Total Produit">Total Produit</Trans>
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <FieldWithSuffix
                    disabled
                    name="total_product"
                    control={control}
                    errors={errors}
                    suffix={t("€")}
                    value={getTotalProduct(sig)}
                    register={register}
                  />
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <FieldWithSuffix
                    disabled
                    name="total_product_percentage"
                    control={control}
                    errors={errors}
                    suffix={t("%")}
                    defaultValue={100}
                    register={register}
                  />
                </TableCell>
                <TableCell align="right" />
                <TableCell align="right" />
              </TableRow>
              <TableRow key="Chiffre d'Affaires Net HT">
                <TableCell component="th" scope="row">
                  <Typography variant="h9" gutterBottom display="inline">
                    <Trans i18nKey="Chiffre d'Affaires Net HT">
                      Chiffre d&apos;Affaires Net HT
                    </Trans>
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <FieldWithSuffix
                    isEdit
                    disabled={false}
                    name="net_turnover_excl_tax"
                    control={control}
                    errors={errors}
                    value={sig.net_turnover_excl_tax}
                    onChangeField={
                      (e) =>
                        handleUserUpdateValue({
                          ...sig,
                          net_turnover_excl_tax: e.target.value.slice(
                            0,
                            e.target.value.length - 1
                          ),
                        })
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    suffix={t("€")}
                    defaultValue={0}
                    register={register}
                  />
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <FieldWithSuffix
                    disabled
                    name="net_turnover_excl_tax_percentage"
                    control={control}
                    errors={errors}
                    suffix={t("%")}
                    value={sig.net_turnover_excl_tax_percentage}
                    register={register}
                  />
                </TableCell>
                <TableCell align="right" />
                <TableCell align="right" />
              </TableRow>
              <TableRow key="Production Consommée ou Avantages en Nature">
                <TableCell component="th" scope="row">
                  <Typography variant="h9" gutterBottom display="inline">
                    <Trans i18nKey="Production Consommée ou Avantages en Nature">
                      Production Consommée ou Avantages en Nature
                    </Trans>
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <FieldWithSuffix
                    isEdit
                    disabled={false}
                    name="production_used"
                    control={control}
                    errors={errors}
                    value={sig.production_used}
                    onChangeField={
                      (e) =>
                        handleUserUpdateValue({
                          ...sig,
                          production_used: e.target.value.slice(
                            0,
                            e.target.value.length - 1
                          ),
                        })
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    suffix={t("€")}
                    defaultValue={0}
                    register={register}
                  />
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <FieldWithSuffix
                    disabled
                    name="production_used_percentage"
                    control={control}
                    errors={errors}
                    suffix={t("%")}
                    value={sig.production_used_percentage}
                    register={register}
                  />
                </TableCell>
                <TableCell align="right" />
                <TableCell align="right" />
              </TableRow>
              <TableRow key="others_products">
                <TableCell component="th" scope="row">
                  <Typography variant="h9" gutterBottom display="inline">
                    <Trans i18nKey="Autres Produits">Autres Produits</Trans>
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <FieldWithSuffix
                    isEdit
                    disabled={false}
                    name="others_products"
                    control={control}
                    errors={errors}
                    suffix={t("€")}
                    value={sig.others_products}
                    onChangeField={
                      (e) =>
                        handleUserUpdateValue({
                          ...sig,
                          others_products: e.target.value.slice(
                            0,
                            e.target.value.length - 1
                          ),
                        })
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    register={register}
                  />
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <FieldWithSuffix
                    disabled
                    name="others_products_percentage"
                    control={control}
                    errors={errors}
                    suffix={t("%")}
                    value={sig.others_products_percentage}
                    register={register}
                  />
                </TableCell>
                <TableCell align="right" />
                <TableCell align="right" />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};
export default TotalProduct;
