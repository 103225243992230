import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
  Divider,
  InputAdornment,
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import Edit from "@material-ui/icons/Edit";
import { useParams } from "react-router-dom";
import {
  COMPUTE_INITIAL_VALUES,
  EDIT_RANGE,
} from "../../redux/types/rangeActionTypes";

import {
  computeRangeInitialValues,
  computeRangeProductsInitialValues,
  switchPopser,
} from "../../redux/actions/rangeProductActions";
import { updateRangeAction } from "../../redux/actions/rangeActions";
import { getCserRange } from "../../services/range/RangeTurnoverCalculService";

const useStyles = makeStyles(() => ({
  ButtonRight: {
    width: "100%",
  },
  underlineTextField: {
    "& .MuiInput-underline:before": {
      borderBottom: "0px",
    },
  },
  input: {
    "& input": {
      width: "25px",
    },
    "& i.MuiInputBase-input::after": {
      content: "%",
    },
  },
  margin: {
    marginTop: "20px",
  },

  popserStyle: {
    fontWeight: "600",
    color: "#1876d1",
  },
  iconEdit: {
    fontSize: "1em",
    color: "#1876d1bd",
  },
}));

function TurnoverRangeDashboard() {
  // Tooling
  const classes = useStyles();
  const dispatch = useDispatch();

  // Range Data
  const popser = useSelector((state) => state.rangeProducts.popser);
  const {
    sales_revenue_TTC,
    average_material_ratio,
    sales_revenue_HT,
    range_multiplier_coefficient,
    material_consumption_HT,
    coefficient_k,
    gross_margin,
    cser_range,
    variation_popularity,
    average_price_offered_TTC,
    variation_rentability,
    average_price_requested_TTC,
  } = useSelector((state) => state.ranges.range);
  const { rangeInitialValuesComputed } = useSelector((state) => state.ranges);
  const { loading } = useSelector((state) => state.rangeProducts);
  const { rangeId } = useParams();
  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;
  const [computeAgain, setComputeAgain] = React.useState(false);
  useEffect(async () => {
    if (!loading && !rangeInitialValuesComputed) {
      dispatch({
        type: COMPUTE_INITIAL_VALUES,
        payload: {},
      });
      await dispatch(computeRangeInitialValues());
      await dispatch(computeRangeProductsInitialValues());
    }
  }, [loading, rangeInitialValuesComputed]);

  // Handlers
  const handleChangeCoefficientK = async (newcoefficient_k) => {
    updateRangeAction(
      { coefficient_k: newcoefficient_k },
      rangeId,
      user.accessToken
    );
    const cserGamme = getCserRange(
      range_multiplier_coefficient,
      newcoefficient_k
    );
    dispatch({
      type: EDIT_RANGE,
      payload: {
        range: {
          coefficient_k: newcoefficient_k,
          cser_range: cserGamme,
        },
      },
    });
  };

  const handleChangeVariationPopularity = async (newvariation_popularity) => {
    updateRangeAction(
      { variation_popularity: newvariation_popularity },
      rangeId,
      user.accessToken
    );
    dispatch({
      type: EDIT_RANGE,
      payload: {
        range: {
          variation_popularity: newvariation_popularity,
        },
      },
    });
    setComputeAgain(true);
  };
  const handleChangeVariationRentability = async (newvariation_rentability) => {
    updateRangeAction(
      { variation_rentability: newvariation_rentability },
      rangeId,
      user.accessToken
    );
    dispatch({
      type: EDIT_RANGE,
      payload: {
        range: {
          variation_rentability: newvariation_rentability,
        },
      },
    });
    setComputeAgain(true);
  };

  const handleComputeProductsValues = () => {
    if (computeAgain) {
      dispatch(computeRangeProductsInitialValues());
    }
  };

  const onChangeIsPopser = () => {
    const reversePopser = {
      isPopser: !popser.isPopser,
      name:
        popser.name === "Classement du Popser"
          ? "Classement du Popser"
          : "Classement du Boston Consulting Group",
    };
    dispatch(switchPopser(reversePopser));
  };

  return (
    <>
      <Grid container spacing={0}>
        <Table>
          <TableBody className={classes.root}>
            <TableRow>
              <TableCell component="th" scope="row">
                <Trans i18nKey="Chiffre d'affaires TTC (€)">
                  Chiffre d&apos;affaires TTC (€)
                </Trans>
              </TableCell>
              <TableCell>
                <NumberFormat
                  disabled
                  className={classes.underlineTextField}
                  id="turnoverTTC"
                  name="turnoverTTC"
                  value={sales_revenue_TTC}
                  customInput={TextField}
                  thousandSeparator={" "}
                  suffix="€"
                />
              </TableCell>
              <TableCell component="th" scope="row">
                <Trans i18nKey="Ratio matière moyen">Ratio matière moyen</Trans>
              </TableCell>
              <TableCell>
                <NumberFormat
                  disabled
                  value={average_material_ratio}
                  id="averageMaterialRatio"
                  name="averageMaterialRatio"
                  className={classes.underlineTextField}
                  customInput={TextField}
                  suffix="%"
                />
              </TableCell>
              <TableCell rowSpan={2} colSpan={2}>
                <FormGroup row>
                  <Grid container>
                    <Grid item xs={3} />

                    <Grid item xs={4}>
                      {" "}
                      Classement du Boston Consulting Group
                    </Grid>
                    <Grid item xs={2}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={popser.isPopser}
                            onChange={onChangeIsPopser}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        labelPlacement="start"
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.popserStyle}>
                      {" "}
                      Classement du Popser
                    </Grid>
                  </Grid>
                </FormGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <Trans i18nKey="Chiffre d'affaires HT (€)">
                  Chiffre d&apos;affaires HT (€)
                </Trans>
              </TableCell>
              <TableCell>
                <NumberFormat
                  disabled
                  className={classes.underlineTextField}
                  id="turnoverHT"
                  name="turnoverHT"
                  value={sales_revenue_HT}
                  customInput={TextField}
                  thousandSeparator={" "}
                  suffix="€"
                />
              </TableCell>
              <TableCell component="th" scope="row">
                <Trans i18nKey="Coefficient multiplicateur de gamme">
                  Coefficient multiplicateur de gamme
                </Trans>
              </TableCell>
              <TableCell>
                <TextField
                  disabled
                  className={(classes.input, classes.underlineTextField)}
                  id="multiplyingCoefficientRange"
                  name="multiplyingCoefficientRange"
                  value={range_multiplier_coefficient}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <Trans i18nKey="Consommation de matières HT (€)">
                  Consommation de matières HT (€)
                </Trans>
              </TableCell>
              <TableCell>
                <NumberFormat
                  disabled
                  className={classes.underlineTextField}
                  id="consumptionMaterialsHT"
                  name="consumptionMaterialsHT"
                  value={material_consumption_HT}
                  customInput={TextField}
                  thousandSeparator={" "}
                  suffix="€"
                />
              </TableCell>
              <TableCell component="th" scope="row">
                <Trans i18nKey="coefficient k">coefficient k</Trans>
              </TableCell>
              <TableCell>
                <TextField
                  className={(classes.input, classes.underlineTextField)}
                  id="coefficientK"
                  type="number"
                  onChange={(e) => handleChangeCoefficientK(e.target.value)}
                  name="coefficientK"
                  defaultValue={coefficient_k}
                  value={coefficient_k}
                  InputProps={{
                    endAdornment: (
                      <Edit className={classes.iconEdit} onClick={() => 0}>
                        x
                      </Edit>
                    ),
                  }}
                />
              </TableCell>
              <TableCell component="th" scope="row" colSpan={2}>
                <Trans i18nKey="Degré de tolérence des tranches moyennes">
                  Degré de tolérence des tranches &quot;moyennes&quot;
                </Trans>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <Trans i18nKey="Marge brute HT (€)">Marge brute HT (€)</Trans>
              </TableCell>
              <TableCell>
                <NumberFormat
                  disabled
                  className={classes.underlineTextField}
                  id="grossMarginHT"
                  name="grossMarginHT"
                  value={gross_margin}
                  customInput={TextField}
                  thousandSeparator={" "}
                  suffix="€"
                />
              </TableCell>
              <TableCell component="th" scope="row">
                <Trans i18nKey="Cser Gamme">Cser Gamme</Trans>
              </TableCell>
              <TableCell>
                <TextField
                  disabled
                  className={(classes.input, classes.underlineTextField)}
                  id="cserRange"
                  name="cserRange"
                  value={cser_range}
                />
              </TableCell>
              <TableCell component="th" scope="row">
                <Trans i18nKey="+ ou -">+ ou -</Trans>
              </TableCell>
              <TableCell>
                <TextField
                  className={(classes.input, classes.underlineTextField)}
                  id="variation_popularity"
                  // eslint-disable-next-line prettier/prettier
                  onChange={(e) => handleChangeVariationPopularity(e.target.value)}
                  onBlur={handleComputeProductsValues}
                  type="number"
                  name="variation_popularity"
                  defaultValue={variation_popularity}
                  value={variation_popularity}
                  InputProps={{
                    endAdornment: (
                      <>
                        <InputAdornment position="end">% </InputAdornment>
                        <Edit className={classes.iconEdit} onClick={() => 0}>
                          x
                        </Edit>
                      </>
                    ),
                  }}
                />
                <Trans i18nKey="en popularité"> en popularité</Trans>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <Trans i18nKey="Prix moyen offert TTC (€)">
                  Prix moyen offert TTC (€)
                </Trans>
              </TableCell>
              <TableCell>
                <NumberFormat
                  disabled
                  className={classes.underlineTextField}
                  id="averagePriceTTC"
                  name="averagePriceTTC"
                  value={average_price_offered_TTC}
                  customInput={TextField}
                  thousandSeparator={" "}
                  suffix="€"
                />
              </TableCell>
              <TableCell component="th" scope="row">
                <Trans i18nKey="Prix moyen demandé TTC">
                  Prix moyen demandé TTC
                </Trans>
              </TableCell>
              <TableCell>
                <NumberFormat
                  disabled
                  className={classes.underlineTextField}
                  id="averagePriceRequested"
                  name="averagePriceRequested"
                  value={average_price_requested_TTC}
                  customInput={TextField}
                  thousandSeparator={" "}
                  suffix="€"
                />
              </TableCell>
              <TableCell component="th" scope="row">
                <Trans i18nKey="+ ou -">+ ou -</Trans>
              </TableCell>
              <TableCell>
                <TextField
                  className={(classes.input, classes.underlineTextField)}
                  id="variation_rentability"
                  type="number"
                  // eslint-disable-next-line prettier/prettier
                  onChange={(e) => handleChangeVariationRentability(e.target.value)}
                  onBlur={handleComputeProductsValues}
                  name="variation_rentability"
                  defaultValue={variation_rentability}
                  value={variation_rentability}
                  InputProps={{
                    endAdornment: (
                      <>
                        <InputAdornment position="end">% </InputAdornment>
                        <Edit className={classes.iconEdit} onClick={() => 0}>
                          x
                        </Edit>
                      </>
                    ),
                  }}
                />
                <Trans i18nKey="de rentabilité"> de rentabilité</Trans>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Divider my={6} mr={24} className={classes.margin} />
    </>
  );
}

export default TurnoverRangeDashboard;
