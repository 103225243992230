/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import styled, { withTheme } from "styled-components";
import { Redirect, useHistory } from "react-router-dom";
import {
  Grid,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Tooltip,
  Badge,
  makeStyles,
  Typography,
  Button,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

import { Menu as MenuIcon } from "@material-ui/icons";
import Comment from "@material-ui/icons/Comment";

import { Power } from "react-feather";
import { auth } from "../services/firebase";
import ProjectSelect from "./HeaderComponents/ProjectSelect";
import {
  show_comments,
  hide_comments,
} from "../redux/actions/managerFeedbackAction";
import { updateAsReadProjectCommentRead } from "../redux/actions/projectCommentsActions";

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;
const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: white;
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
    color: white;
  }
`;
const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const useStyles = makeStyles((theme) => ({
  displayNone: {
    display: "none",
  },
  notificationCommentElement: {
    maxHeight: "200px",
    overflowY: "auto",
    top: "-2%",
    marginTop: "55px",
    overflowX: "hidden",
    border: "solid 1px #1876d2",
    position: "fixed",
    width: "300px",
    textAlign: "center",
    right: "11%",
    zIndex: "1101",
    borderRadius: "4px",
    background: "white",
    [theme.breakpoints.between("xs", "sm")]: {
      maxHeight: "200px",
      marginTop: "0px",
    },
    "& .MuiGrid-item": {
      padding: "8px",
      margin: "auto",
    },
  },
  notificationCommentTitle: {
    borderBottom: "solid 1px #1876d259",
  },
}));

function UserMenu() {
  const [anchorMenu, setAnchorMenu] = useState(null);

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };
  const signOut = () => {
    auth.signOut().then(() => {
      localStorage.clear();
      return <Redirect to="/auth/sign-in" />;
    });
  };
  return (
    <>
      <IconButton
        aria-owns={anchorMenu ? "menu-appbar" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
      >
        <Power />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={closeMenu}>Profil</MenuItem>
        <MenuItem onClick={signOut}>Déconnexion</MenuItem>
      </Menu>
    </>
  );
}

const Header = ({ onDrawerToggle }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const showComments = useSelector(
    (state) => state.managerFeedback.show_comments
  );
  const commentRead = useSelector((state) => state.projectComments.commentRead);
  const [commentsNoRead, setCommentsNoRead] = useState([]);
  const history = useHistory();
  const [commentNotificationHide, setCommentNotificationHide] = useState(false);
  const authenticate = useSelector((state) => state.firebase.auth);
  const user = authenticate.stsTokenManager;

  useEffect(() => {
    let listCommentNoRead = [];
    listCommentNoRead = commentRead.filter(
      (comment) => comment.is_read === false
    );
    setCommentsNoRead(listCommentNoRead);
  }, [commentRead]);

  const handleShowManagerFeedback = () => {
    if (showComments.size_comments_screen === 0) {
      dispatch(show_comments());
    } else {
      dispatch(hide_comments());
    }
  };
  const timeout = async (delay) => {
    return new Promise((res) => setTimeout(res, delay));
  };
  const onVisibleNotificationComment = async () => {
    setCommentNotificationHide(true);
    await timeout(6000);
    setCommentNotificationHide(false);
  };

  const onHiddenNotificationComment = () => {
    setCommentNotificationHide(false);
  };
  const redirectComment = (comment) => {
    dispatch(show_comments());
    dispatch(updateAsReadProjectCommentRead(user, comment.id));
    history.push(comment.comment.url);
  };

  return (
    <>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <ProjectSelect />
            </Grid>
            <Grid item xs />
            <Grid item>
              <Tooltip
                disableFocusListener
                title={t("Afficher les commentaires du professeur")}
              >
                <Toolbar>
                  <ToolbarTitle>
                    <IconButton
                      size="medium"
                      color="white"
                      onClick={handleShowManagerFeedback}
                      onMouseEnter={onVisibleNotificationComment}
                    >
                      <Indicator badgeContent={commentsNoRead.length}>
                        <Comment fontSizeSmall="small" />
                      </Indicator>
                    </IconButton>
                  </ToolbarTitle>
                </Toolbar>
              </Tooltip>
            </Grid>
            <Grid item>
              <UserMenu />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid container alignItems="center">
        <Grid
          item
          xs={12}
          className={[
            classes.notificationCommentElement,
            !commentNotificationHide && classes.displayNone,
          ]}
          onMouseLeave={onHiddenNotificationComment}
        >
          <Grid container>
            <Grid item xs={12} className={classes.notificationCommentTitle}>
              <Typography variant="p" gutterBottom display="inline">
                <Trans i18nKey="Vous avez x commentaires">
                  Vous avez
                  {` ${commentsNoRead.length} `}
                  nouveau(x) commentaire(s)
                </Trans>
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            {commentsNoRead.map((value) => (
              <>
                <Grid item xs={8}>
                  {value.comment.user.email}
                </Grid>
                <Grid item xs={4} alignContent="center">
                  <Button
                    color="primary"
                    onClick={() => redirectComment(value)}
                  >
                    <Trans i18nKey="voir">voir</Trans>
                  </Button>
                </Grid>
              </>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withTheme(Header);
