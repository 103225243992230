import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import { Trans } from "react-i18next";

import Helmet from "react-helmet";

import { Button as MuiButton, Paper, Typography } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { auth } from "../../services/firebase";
import EmailResetPassword from "../../components/AuthComponent/EmailResetPasswordComponent";

const Button = styled(MuiButton)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;
  width: 100%;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

function ResetPassword() {
  const { handleSubmit, register, errors } = useForm();
  // const [EmailHasBeenSent, setEmailHasBeenSent] = useState(false);
  const [notificationResetPassword, setNotificationResetPassword] = useState(
    null
  );
  const [isSendEmail, setIsSendEmail] = useState(false);

  const submitData = (data) => {
    auth
      .sendPasswordResetEmail(data.email)
      .then(() => {
        setIsSendEmail(true);
      })
      .catch(() => {
        setNotificationResetPassword(
          "Erreur de réinitialisation du mot de passe"
        );
      });
  };

  return (
    <Wrapper>
      {!isSendEmail && (
        <div>
          <Helmet title="Reset Password" />
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Réinitialisation du mot de passe
          </Typography>
          <Typography component="h2" variant="body1" align="center">
            Entrez votre adresse électronique pour réinitialiser votre mot de
            passe
          </Typography>
          <form onSubmit={handleSubmit(submitData)}>
            <TextField
              margin="normal"
              fullWidth
              id="email"
              label={<Trans i18nKey="Adresse Email">Adresse Email</Trans>}
              name="email"
              autoFocus
              inputRef={register({
                required: "Required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                },
              })}
              error={"email" in errors}
              helperText={
                "email" in errors ? (
                  <Trans i18nKey="Veuillez entrer votre email">
                    Veuillez entrer votre email
                  </Trans>
                ) : (
                  ""
                )
              }
            />
            {notificationResetPassword && <p>{notificationResetPassword}</p>}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              mt={2}
            >
              <Trans i18nKey="Réinitialiser">Réinitialiser</Trans>
            </Button>
          </form>
        </div>
      )}
      {isSendEmail && <EmailResetPassword setIsSendEmail={setIsSendEmail} />}
    </Wrapper>
  );
}

export default ResetPassword;
