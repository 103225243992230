function toFixedNumber(num, digits, base) {
  const pow = (base || 10) ** digits;
  return Math.round(num * pow) / pow;
}

export const getMaterialCosts = (listTotalPrice) => {
  if (listTotalPrice.length > 0) {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    return toFixedNumber(listTotalPrice.reduce(reducer), 2);
  }
  return 0;
};

export const getTotalMaterialCost = (extraCharge, materialCosts) => {
  return toFixedNumber(extraCharge * materialCosts + materialCosts, 2);
};

export const getTotalPricePortion = (totalMaterialCost, numberUnitProduced) => {
  return toFixedNumber(totalMaterialCost / numberUnitProduced, 2);
};

export const getTotalEnergy = (listTotalEnergy) => {
  if (listTotalEnergy.length > 0) {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    return listTotalEnergy.reduce(reducer);
  }
  return 0;
};
export const getPerUnitEnergy = (totalEnergy, numberUnitProduced) => {
  return toFixedNumber(totalEnergy / numberUnitProduced, 2);
};
export const getMultiplyingFactor = (sellingPriceHT, totalPricePortion) => {
  return toFixedNumber(sellingPriceHT / totalPricePortion, 2);
};

export const getSellingPriceHTByMultiplyingFactor = (
  totalPricePortion,
  multiplyingFactor
) => {
  return toFixedNumber(totalPricePortion * multiplyingFactor, 2);
};

export const getSellingPriceHTBySellingPriceTTC = (
  sellingPriceTTC,
  tvaRate
) => {
  const parsedSellingPriceTTC = parseFloat(sellingPriceTTC) || 0;
  return toFixedNumber(parsedSellingPriceTTC / (1 + tvaRate), 2);
};

export const getTvaByRate = (sellingPriceHT, tvaRate) => {
  const parsedSellingPriceHT = parseFloat(sellingPriceHT) || 0;
  const parsedTvaRate = parseFloat(tvaRate) || 0;
  return toFixedNumber(parsedSellingPriceHT * parsedTvaRate, 2);
};

export const getTvaBySellingPriceTTC = (sellingPriceTTC, sellingPriceHT) => {
  const parsedSellingPriceTTC = parseFloat(sellingPriceTTC) || 0;
  const parsedSellingPriceHT = parseFloat(sellingPriceHT) || 0;
  return toFixedNumber(parsedSellingPriceTTC - parsedSellingPriceHT, 2);
};

export const getTvaBySellingriceHT = (sellingPriceHT, tvaRate) => {
  const parsedSellingPriceHT = parseFloat(sellingPriceHT) || 0;
  const parsedTvaRate = parseFloat(tvaRate) || 0;
  return toFixedNumber(parsedSellingPriceHT * (parsedTvaRate / 100), 2);
};

export const getSellingPriceTTC = (sellingPriceHT, tva) => {
  console.log("ttt", sellingPriceHT, tva);
  const parsedSellingPriceHT = parseFloat(sellingPriceHT) || 0;
  return toFixedNumber(parsedSellingPriceHT + tva, 2);
};
