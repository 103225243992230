import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import {
  Typography,
  makeStyles,
  Modal,
  Fade,
  Backdrop,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Divider,
} from "@material-ui/core";
import PropTypes from "prop-types";
import ShareIcon from "@material-ui/icons/Share";
import { useForm } from "react-hook-form";
import { Delete as DeleteIcon, Add as AddIcon } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  addProjectMember,
  getProjetMembers,
} from "../../services/api/projectsApi";
import { openSnackbar } from "../../redux/actions/snackbarActions";

const useStyles = makeStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "5px",
    border: "solid",
    width: "600px",
  },
  floatRight: {
    float: "right",
  },
  width100: {
    width: "100%",
  },
  emailUserRole: {
    paddingTop: "23px!important",
  },
  margin12: {
    marginTop: "12px",
  },
  blueBackground: {
    marginTop: "12px",
    marginBottom: "12px",
    backgroundColor: "#1975d1",
  },
});
const ShareProject = ({
  setOpenShareProjectModal,
  openShareProjectModal,
  project,
}) => {
  const classes = useStyles();
  const { register, errors } = useForm();
  const [role, setRole] = React.useState("Superviseur");
  const [inputErrors, setInputErrors] = useState({});
  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;
  const dispatch = useDispatch();
  const [members, setMembers] = useState([]);
  const [isOwner, setIsOwner] = useState(false);

  const [newMember, setNewMember] = useState({
    email: "",
    project: project.id,
  });

  const [creationErrors, setCreationErrors] = useState([]);

  const handleChange = (event) => {
    setRole(event.target.value);
  };
  const handleClose = () => {
    setOpenShareProjectModal(false);
  };
  const shareProject = async () => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(newMember.email)) {
      setInputErrors({});
      const res = await addProjectMember(
        user.accessToken,
        newMember.project,
        newMember.email
      );
      if ("error_code" in res) {
        setCreationErrors([res.error_code]);
      } else {
        dispatch(
          openSnackbar(
            "Le projet à été partagé avec l'utilisateur. Il recevra bientot un e-mail de confirmation"
          )
        );
        setOpenShareProjectModal(false);
      }
    } else {
      setInputErrors({
        ...inputErrors,
        email: "Merci de saisir une adresse e-mail valide",
      });
      setCreationErrors([]);
    }
  };
  const handleChangeRoleUser = (event) => {
    if (event.target.value === "Supprimer") {
      handleClose();
    }
  };

  const handleChangeShareEmail = (email) =>
    setNewMember({ ...newMember, email });

  useEffect(async () => {
    if (openShareProjectModal === true) {
      const project_members = await getProjetMembers(
        user.accessToken,
        project.id
      );
      setMembers(project_members);
      project_members.forEach((membership) => {
        if (membership.member.email === auth.email) {
          if (membership.role === "OWNER") {
            setIsOwner(true);
          }
        }
      });
    }
  }, [project, openShareProjectModal]);

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className={classes.modal}
      open={openShareProjectModal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openShareProjectModal} className={classes.paper}>
        <div className={classes.paper}>
          {creationErrors.includes("Unknown email") ? (
            <div style={{ color: "red" }}>
              Impossible de partager, cette adresse e-mail nous est inconnue.
            </div>
          ) : (
            <></>
          )}

          {creationErrors.includes("Share already exists") ? (
            <div style={{ color: "red" }}>
              Impossible de partager, cette adresse e-mail existe déjà dans le
              projet.
            </div>
          ) : (
            <></>
          )}

          <Typography variant="h5" gutterBottom display="inline">
            <Trans i18nKey="Partager">Partager</Trans>
            {` ${project.name} `}
            <Trans i18nKey="avec des personnes">avec des personnes</Trans>
          </Typography>
          <ShareIcon className={classes.floatRight} />
          <Grid container spacing={1} className={classes.margin12}>
            {isOwner ? (
              <>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    id="email"
                    label={<Trans i18nKey="Adresse email">Adresse email</Trans>}
                    type="email"
                    name="email"
                    onChange={(e) => handleChangeShareEmail(e.target.value)}
                    inputRef={register({
                      required: "Required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      },
                    })}
                    error={"email" in errors}
                    helperText={
                      "email" in errors ? (
                        <Trans i18nKey="Veuillez entrer votre email">
                          Veuillez entrer votre email
                        </Trans>
                      ) : (
                        ""
                      )
                    }
                  />
                  {"email" in inputErrors ? (
                    <div style={{ color: "red" }}>
                      Veuillez entrer une adresse e-mail valide
                    </div>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <FormControl variant="standard" className={classes.width100}>
                    <InputLabel id="demo-simple-select-standard-label">
                      <Trans i18nKey="Rôle">Rôle</Trans>
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={role}
                      onChange={handleChange}
                      disabled
                      label="Role"
                    >
                      <MenuItem value="Editeur">Propriétaire</MenuItem>
                      <MenuItem value="Superviseur">Superviseur</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={1}>
                  <IconButton aria-label="delete">
                    <AddIcon onClick={shareProject} />
                  </IconButton>
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <i>Seul le propriétaire du projet modifier les partages</i>
              </Grid>
            )}
            <Grid item xs={12}>
              <Divider my={9} className={classes.blueBackground} />
            </Grid>
            {members.map((membership) => (
              <>
                <Grid item xs={9} className={classes.emailUserRole}>
                  {membership.member.email}
                </Grid>
                <Grid item xs={3}>
                  <FormControl variant="standard" className={classes.width100}>
                    <InputLabel id="demo-simple-select-standard-label">
                      <Trans i18nKey="Rôle">Rôle</Trans>
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={
                        membership.role === "OWNER" ? "Editeur" : "Superviseur"
                      }
                      onChange={handleChangeRoleUser}
                      disabled
                      label="roleUser"
                    >
                      <MenuItem value="Editeur">Propriétaire</MenuItem>
                      <MenuItem value="Superviseur">Superviseur</MenuItem>
                      <Divider my={9} />
                      <MenuItem value="Supprimer">
                        <DeleteIcon />
                        Supprimer
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </>
            ))}
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};

ShareProject.propTypes = {
  setOpenShareProjectModal: PropTypes.func.isRequired,
  openShareProjectModal: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  project: PropTypes.object.isRequired,
};
export default ShareProject;
