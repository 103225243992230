export const FETCH_RANGE = "FETCH_RANGE";
export const FETCH_RANGE_SUCCESS = "FETCH_RANGE_SUCCESS";
export const FETCH_RANGE_ERROR = "FETCH_RANGE_EROOR";
export const FETCH_RANGES_SUCCESS = "FETCH_RANGES_SUCCESS";
export const FETCH_RANGES_ERROR = "FETCH_RANGES_EROOR";
export const UPDATE_RANGE = "UPDATE_RANGE";
export const UPDATE_RANGE_SUCCESS = "UPDATE_RANGE";
export const PUT_RANGE = "PUT_RANGE";
export const PUT_RANGE_SUCCESS = "PUT_RANGE_SUCCESS";
export const PUT_RANGE_ERROR = "PUT_RANGE_ERROR";
export const UPDATE_RANGE_ERROR = "UPDATE_RANGE";
export const CLEAR_RANGES = "CLEAR_RANGES";
export const CLEAR_RANGE = "CLEAR_RANGE";

export const CLEAN_RANGE = "CLEAN_RANGE";

export const EDIT_RANGE = "EDIT_RANGE";
export const EDIT_RANGE_SUCCESS = "EDIT_RANGE_SUCCESS";

export const COMPUTE_RANGE_VALUES = "COMPUTE_RANGE_VALUES";
export const COMPUTE_RANGE_VALUES_SUCCESS = "COMPUTE_RANGE_VALUES_SUCCESS";

export const SAVE_RANGE = "SAVE_RANGE";
export const SAVE_RANGE_SUCCESS = "SAVE_RANGE_SUCCESS";
export const SAVE_RANGE_ERROR = "SAVE_RANGE_ERROR";
export const COMPUTE_INITIAL_VALUES = "COMPUTE_INITIAL_VALUES";
