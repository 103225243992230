import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Grid, Divider } from "@material-ui/core";
import TurnoverRangeDashboard from "./TurnoverRangeDashboard";

const useStyles = makeStyles(() => ({
  ButtonRight: {
    width: "100%",
  },
  underlineTextField: {
    "& .MuiInput-underline:before": {
      borderBottom: "0px",
    },
  },
  input: {
    "& input": {
      width: "25px",
    },
    "& i.MuiInputBase-input::after": {
      content: "%",
    },
  },
  margin: {
    marginTop: "20px",
  },
}));

function RangeDashboard() {
  const classes = useStyles();
  return (
    <Card>
      <TurnoverRangeDashboard />
      <Divider my={6} mr={24} className={classes.margin} />
      <Grid container spacing={6} />
    </Card>
  );
}

export default RangeDashboard;
