import React, { useState } from "react";

import { Trans } from "react-i18next";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { TextareaAutosize, Toolbar, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { updateProduct } from "../../redux/actions/productActions";

const Spacer = styled.div`
  flex: 1 1 100%;
`;
const ToolbarTitle = styled.div`
  min-width: 150px;
`;
const useStyles = makeStyles(() => ({
  textArea: {
    width: "100%",
  },
}));
function ProductionTechniques({ productId }) {
  const classes = useStyles();
  const product = useSelector((state) => state.product.product);
  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;

  const [productionTechnique, setProductionTechnique] = useState(
    product.production_techniques
  );
  const dispatch = useDispatch();
  const currentProject = useSelector((state) => state.projects.currentProject);
  const updatingProduct = async (data) => {
    dispatch(updateProduct(data, productId, user, currentProject));
  };
  const handleChangeProductionTechniques = (e) => {
    updatingProduct({
      ...product,
      production_techniques: e.target.value,
    });
  };

  return (
    <div className="container-production-techniques">
      <Toolbar>
        <ToolbarTitle>
          <Typography variant="h6" id="tableTitle">
            <Trans i18nKey="Techniques de production">
              Techniques de production
            </Trans>
          </Typography>
        </ToolbarTitle>
      </Toolbar>

      <Spacer />

      <TextareaAutosize
        className={classes.textArea}
        rowsMin={42}
        rowsMax={42}
        aria-label="maximum height"
        placeholder=""
        defaultValue={product.production_techniques}
        value={productionTechnique}
        onChange={(e) => setProductionTechnique(e.target.value)}
        onBlur={handleChangeProductionTechniques}
      />
    </div>
  );
}

export default ProductionTechniques;

ProductionTechniques.propTypes = {
  productId: PropTypes.number.isRequired,
};
