import axios from "../../services/api/apiClient";
import { RANGE_PRODUCTS } from "../../services/constants/urls";
import {
  FETCH_RANGE_PRODUCTS,
  ERROR_FETCHING_RANGE_PRODUCTS,
  DELETE_RANGE_PRODUCT_ERROR,
  DELETE_RANGE_PRODUCT_SUCCESS,
  ADD_PRODUCT_TO_RANGE_SUCCESS,
  ADD_PRODUCT_TO_RANGE_ERROR,
  FETCH_RANGES_OF_A_PRODUCT_SUCCESS,
  FETCH_RANGES_OF_A_PRODUCT_ERROR,
  UPDATE_RANGE_PRODUCT_SUCCESS,
  UPDATE_RANGE_PRODUCT_ERROR,
  UPDATE_RANGE_PRODUCTS_SUCCESS,
  SWITCH_POPSER,
  FETCH_RANGE_PRODUCTS_STARTED,
  EDIT_RANGE_PRODUCTS,
} from "../types/rangeProductsActionTypes";
import { store } from "../store";
import {
  getListUnitGrossMargin,
  getListDisplayNumber,
  getListQuantitySold,
  getListTotalGrossMargin,
} from "../../services/range/RangeMethodService";
import { interactionTurnoverRange } from "../../services/range/RangeCalculInteractionService";
import { EDIT_RANGE } from "../types/rangeActionTypes";
import {
  getAverageUnitGrossMargin,
  getBostonLowerLimitsPopularity,
  getBostonLowerLimitsRentability,
  getBostonRank,
  getDisplayIndex,
  getGrossMarginHtTotal,
  getGrossMarginHtUnit,
  getIlesHigherLimitsPopularity,
  getIlesHigherLimitsRentability,
  getIlesLowerLimitsPopularity,
  getIlesLowerLimitsRentability,
  getParsedFloatNumber,
  getPercentageSold,
  getPopserRank,
  getPopularityBostonSchool,
  getPopularityIndex,
  getPopularityPopser,
  getRentabilityBostonSchool,
  getRentabilityPopser,
  getSumGrossMarginHtTotal,
  getSumQuantitySold,
  getTotalDisplayNumber,
} from "../../services/range/RangeService";

export const computeRangeInitialValues = () => async (dispatch) => {
  const { rangeProducts } = store.getState();
  const allProductQuantitiesSold = getListQuantitySold(
    rangeProducts.rangeProducts
  );
  const resTurnover = interactionTurnoverRange(
    rangeProducts.rangeProducts,
    allProductQuantitiesSold
  );
  dispatch({
    type: EDIT_RANGE,
    payload: {
      range: resTurnover,
    },
  });
};

const computeRangeProductInitialValues = (
  rangeProduct,
  allDaysOfPresentation,
  allProductQuantitiesSold,
  averageUnitGrossMargin,
  countOfRangeProducts,
  sumOfTotalGrossMargin,
  sumOfQuantitiesSold,
  range
) => {
  const percent_of_sells = getPercentageSold(
    rangeProduct.quantity_sold,
    getSumQuantitySold(allProductQuantitiesSold)
  );
  const display_index = getDisplayIndex(
    rangeProduct.number_of_days_of_presentation,
    getTotalDisplayNumber(allDaysOfPresentation)
  );

  const popularity_index = getParsedFloatNumber(
    getPopularityIndex(percent_of_sells, display_index)
  );
  const unit_gross_margin_vat_excluded = getGrossMarginHtUnit(
    rangeProduct.selling_price_vat_excl,
    rangeProduct.raw_material_cost_vat_excl
  );
  const total_unit_gross_margin_vat_exluded = getGrossMarginHtTotal(
    unit_gross_margin_vat_excluded,
    rangeProduct.quantity_sold
  );
  const iles_higher_limits_popularity = getIlesHigherLimitsPopularity(
    range.variation_popularity
  );
  const iles_lower_limits_popularity = getIlesLowerLimitsPopularity(
    range.variation_popularity
  );
  const iles_popularity = getPopularityPopser(
    popularity_index,
    iles_higher_limits_popularity,
    iles_lower_limits_popularity
  );
  const iles_lower_limits_rentability = getIlesLowerLimitsRentability(
    averageUnitGrossMargin,
    range.variation_rentability
  );
  const iles_higher_limits_rentability = getIlesHigherLimitsRentability(
    averageUnitGrossMargin,
    range.variation_rentability
  );
  const iles_profitability = getRentabilityPopser(
    iles_lower_limits_rentability,
    iles_higher_limits_rentability,
    unit_gross_margin_vat_excluded
  );
  const iles_rank = getPopserRank(iles_profitability, iles_popularity);
  const bostonLowerLimitsPopularity = getBostonLowerLimitsPopularity(
    countOfRangeProducts
  );
  const bcg_popularity = getPopularityBostonSchool(
    bostonLowerLimitsPopularity,
    percent_of_sells
  );
  const bostonLowerLimitRentability = getBostonLowerLimitsRentability(
    sumOfTotalGrossMargin,
    sumOfQuantitiesSold
  );
  const bcg_profitability = getRentabilityBostonSchool(
    bostonLowerLimitRentability,
    unit_gross_margin_vat_excluded
  );
  const bcg_rank = getBostonRank(bcg_popularity, bcg_profitability);
  return {
    ...rangeProduct,
    percent_of_sells,
    display_index,
    popularity_index,
    unit_gross_margin_vat_excluded,
    total_unit_gross_margin_vat_exluded,
    iles_popularity,
    iles_profitability,
    iles_rank,
    bcg_popularity,
    bcg_profitability,
    bcg_rank,
  };
};

export const computeRangeProductsInitialValues = () => async (dispatch) => {
  const { rangeProducts, ranges } = store.getState();
  const allProductQuantitiesSold = getListQuantitySold(
    rangeProducts.rangeProducts
  );
  const allDaysOfPresentation = getListDisplayNumber(
    rangeProducts.rangeProducts
  );
  const averageUnitGrossMargin = getAverageUnitGrossMargin(
    getListUnitGrossMargin(rangeProducts.rangeProducts)
  );
  const countOfRangeProducts = rangeProducts.rangeProducts.length;
  const sumOfTotalGrossMargin = getSumGrossMarginHtTotal(
    getListTotalGrossMargin(rangeProducts.rangeProducts)
  );
  const sumOfQuantitiesSold = getSumQuantitySold(
    getListQuantitySold(rangeProducts.rangeProducts)
  );
  const initializedRangeProducts = [];
  rangeProducts.rangeProducts.forEach((rangeProduct) => {
    initializedRangeProducts.push(
      computeRangeProductInitialValues(
        rangeProduct,
        allDaysOfPresentation,
        allProductQuantitiesSold,
        averageUnitGrossMargin,
        countOfRangeProducts,
        sumOfTotalGrossMargin,
        sumOfQuantitiesSold,
        ranges.range
      )
    );
  });

  dispatch({
    type: EDIT_RANGE_PRODUCTS,
    payload: initializedRangeProducts,
  });
};

const replaceListRangeProducts = (updatedRangeProduct) => {
  const { rangeProducts } = store.getState();
  const newRangeProducts = [...rangeProducts.rangeProducts];
  updatedRangeProduct.forEach((rangeProduct) => {
    const indexTable = newRangeProducts.findIndex(
      (oldRangeProduct) => oldRangeProduct.id === rangeProduct.id
    );
    newRangeProducts.splice(indexTable, 1, rangeProduct);
  });

  return newRangeProducts;
};

export const fetchRangeProducts = (rangeID, accessToken) => async (
  dispatch
) => {
  dispatch({
    type: FETCH_RANGE_PRODUCTS_STARTED,
    payload: {},
  });
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${RANGE_PRODUCTS}?range_id=${rangeID}`;
  try {
    const res = await axios({ method: "get", url, headers });
    dispatch({
      type: FETCH_RANGE_PRODUCTS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: ERROR_FETCHING_RANGE_PRODUCTS,
      payload: { error: e },
    });
  }
};

export const fetchRangesOfAProduct = (productId, accessToken) => async (
  dispatch
) => {
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${RANGE_PRODUCTS}?product_id=${productId}`;
  try {
    const res = await axios({ method: "get", url, headers });

    dispatch({
      type: FETCH_RANGES_OF_A_PRODUCT_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: FETCH_RANGES_OF_A_PRODUCT_ERROR,
      payload: { error: e },
    });
  }
};

export const updateRangeProduct = (rangeProduct, accessToken) => async (
  dispatch
) => {
  const rangeProductData = {
    ...rangeProduct,
    product: rangeProduct.product.id,
    range: rangeProduct.range.id,
    project: rangeProduct.project.id,
  };
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${RANGE_PRODUCTS}${rangeProduct.uuid}/`;
  try {
    const res = await axios({
      method: "patch",
      url,
      headers,
      data: rangeProductData,
    });
    await dispatch({
      type: UPDATE_RANGE_PRODUCT_SUCCESS,
      payload: res.data,
    });
    dispatch(computeRangeProductsInitialValues());
    dispatch(computeRangeInitialValues());
  } catch (e) {
    dispatch({
      type: UPDATE_RANGE_PRODUCT_ERROR,
      payload: { error: e },
    });
  }
};
export const putRangeProducts = (rangeProducts, accessToken) => async (
  dispatch
) => {
  const updatedRangeProducts = [];
  let errorLog = { isError: false, message: null };

  await Promise.all(
    rangeProducts.map(async (rangeProduct) => {
      const rangeProductData = {
        ...rangeProduct,
        product: rangeProduct.product.id,
        range: rangeProduct.range.id,
        project: rangeProduct.project.id,
      };

      const headers = {
        "Content-Type": `application/json;`,
        Authorization: `Bearer ${accessToken}`,
      };
      const url = `${RANGE_PRODUCTS}${rangeProduct.uuid}/`;
      try {
        const res = await axios({
          method: "patch",
          url,
          headers,
          data: rangeProductData,
        });
        updatedRangeProducts.push(res.data);
      } catch (e) {
        errorLog = { isError: true, message: e };
      }
    })
  );
  if (errorLog.isError) {
    dispatch({
      type: UPDATE_RANGE_PRODUCT_ERROR,
      payload: { error: errorLog.message },
    });
  } else {
    dispatch({
      type: UPDATE_RANGE_PRODUCTS_SUCCESS,
      payload: replaceListRangeProducts(updatedRangeProducts),
    });
  }
};

export const updateRangeProducts = (rangeProducts) => async (dispatch) => {
  dispatch({
    type: UPDATE_RANGE_PRODUCTS_SUCCESS,
    payload: replaceListRangeProducts(rangeProducts),
  });
};

export const addProductToRange = (
  project_id,
  range_id,
  product_id,
  accessToken,
  rangeProduct
) => async (dispatch) => {
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${RANGE_PRODUCTS}`;
  try {
    const res = await axios({
      method: "post",
      url,
      headers,
      data: {
        project: project_id,
        range: range_id,
        product: product_id,
        ...rangeProduct,
      },
    });
    dispatch({
      type: ADD_PRODUCT_TO_RANGE_SUCCESS,
      payload: res.data,
    });
    dispatch(computeRangeInitialValues());
    dispatch(computeRangeProductsInitialValues());
  } catch (e) {
    dispatch({
      type: ADD_PRODUCT_TO_RANGE_ERROR,
      payload: { error: e },
    });
  }
};

export const removeProductFromRange = (rangeProductUUID, accessToken) => async (
  dispatch
) => {
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${RANGE_PRODUCTS}${rangeProductUUID}`;
  try {
    await axios({ method: "delete", url, headers });
    dispatch({
      type: DELETE_RANGE_PRODUCT_SUCCESS,
      payload: rangeProductUUID,
    });
    dispatch(computeRangeInitialValues());
    dispatch(computeRangeProductsInitialValues());
  } catch (e) {
    dispatch({
      type: DELETE_RANGE_PRODUCT_ERROR,
      payload: { error: e },
    });
  }
};

export const switchPopser = (popser) => async (dispatch) => {
  dispatch({
    type: SWITCH_POPSER,
    payload: popser,
  });
};
