import { PROJECT_MEMBERSHIP_URL } from "../constants/urls";
import axios from "./apiClient";

export const getProjetMembers = async (accessToken, project_id) => {
  let result = null;
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${PROJECT_MEMBERSHIP_URL}?project_id=${project_id}`;
  try {
    const res = await axios({
      method: "get",
      url,
      headers,
    });
    if (res.status === 200) {
      result = res.data;
    }
  } catch (error) {
    result = [];
  }
  return result;
};

export const addProjectMember = async (accessToken, project, member_email) => {
  let result;
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${PROJECT_MEMBERSHIP_URL}`;
  try {
    const res = await axios({
      method: "post",
      url,
      headers,
      data: {
        project,
        member_email,
      },
    });
    if (res.status === 201) {
      result = { result: res.data, isError: false };
    }
  } catch (error) {
    console.log(error);
    result = {
      result: null,
      isError: true,
      error_code: error.response.data.error_code,
    };
  }
  return result;
};

export default getProjetMembers;
