import React from "react";

import styled from "styled-components";
import {
  makeStyles,
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
} from "@material-ui/core";
import Comment from "@material-ui/icons/Comment";
import QuestionAnswer from "@material-ui/icons/QuestionAnswer";
import { Trans } from "react-i18next";
import ChatBoxComponent from "./component/chatBoxComponent";

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: "center",
    color: "#188038",
  },
  iconSize: {
    padding: "30px",
    "& svg": {
      fontSize: "60px",
      color: "#188038",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      padding: "15px",
      "& svg": {
        fontSize: "40px",
      },
    },
  },
}));
function managerFeedback() {
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();
  return (
    <Card mb={12}>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" id="tableTitle">
              <Comment fontSizeSmall="small" color="green" />
              <Trans i18nKey="Commentaires">Commentaires</Trans>
            </Typography>
            <Divider my={9} />
          </Grid>

          <Grid item xs={12}>
            <Spacer />
            <Grid container className={classes.center}>
              <Grid item xs={12} className={classes.iconSize}>
                <QuestionAnswer color="primary" />
              </Grid>
              <Grid item xs={12} />
              <Trans i18nKey="Qui voit les commentaires ? Vos commentaires seront visibles par tous les utilisateurs pouvant accéder à votre projet.">
                Qui voit les commentaires ? Vos commentaires seront visibles par
                tous les utilisateurs pouvant accéder à votre projet.
              </Trans>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardContent>
        <ChatBoxComponent />
      </CardContent>
    </Card>
  );
}

export default managerFeedback;
