import React from "react";
import styled from "styled-components";
import { Trans } from "react-i18next";

import {
  Card as MuiCard,
  CardContent,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
  TextField as MuiTextField,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import PropTypes from "prop-types";

const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
}));

function AddRangeDetail({ register, errors }) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    rangeForecast: false,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  return (
    <Card mb={6}>
      <CardContent>
        <Grid className={classes.root} container spacing={2}>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              fullWidth
              id="name"
              label={
                <Trans i18nKey="Nom de la gamme *">Nom de la gamme *</Trans>
              }
              name="name"
              autoFocus
              inputRef={register({
                required: "Required",
              })}
              error={"name" in errors}
              helperText={
                "name" in errors ? (
                  <Trans i18nKey="Veuillez entrer le nom de la gamme">
                    Veuillez entrer le nom de la gamme
                  </Trans>
                ) : (
                  ""
                )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              fullWidth
              id="tva_rate"
              defaultValue="10"
              label={<Trans i18nKey="Taux de TVA(%)">Taux de TVA(%)</Trans>}
              name="tva_rate"
              inputRef={register()}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={state.rangeForecast}
                  onChange={handleChange}
                  inputRef={register}
                  name="rangeForecast"
                  color="primary"
                />
              }
              label="Gamme prévisionnelle"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
AddRangeDetail.propTypes = {
  register: PropTypes.func.isRequired,
  // setImg: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object.isRequired,
  // control: PropTypes.func.isRequired,
};
export default AddRangeDetail;
