import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { Add as AddIcon } from "@material-ui/icons";
import {
  TableFooter,
  IconButton,
  TableRow,
  TablePagination,
  TableCell,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../../redux/actions/productActions";

const FooterAddLine = ({
  count,
  page,
  rowsPerPage,
  changeRowsPerPage,
  changePage,
  disabled,
  addProduct,
  hiddenPagination,
}) => {
  const [open, setOpen] = React.useState(false);
  const products = useSelector((state) => state.product.products);
  const currentProject = useSelector((state) => state.projects.currentProject);
  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;
  useEffect(async () => {
    if (open) {
      await dispatch(fetchProducts(currentProject.id, user.accessToken));
      setOptions(
        products.map((product) => ({
          value: product.name,
          label: product.name,
          product,
        }))
      );
    }
  }, [open]);
  return (
    <TableFooter>
      <TableRow>
        {open ? (
          <TableCell colSpan={6}>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isDisabled={false}
              isLoading={loading}
              isClearable
              isSearchable
              name="productAdd"
              options={options}
              onMenuOpen={() => setOpen(true)}
              onMenuClose={() => setOpen(false)}
              menuIsOpen={open}
              onChange={(value) => addProduct(value.product)}
            />
          </TableCell>
        ) : (
          <TableCell align="center">
            <IconButton
              onClick={() => setOpen(true)}
              aria-label="ajouter une ligne"
              disabled={disabled}
            >
              <AddIcon />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        {!hiddenPagination && (
          <TablePagination
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            // eslint-disable-next-line no-shadow
            onChangePage={(_, page) => changePage(page)}
            onChangeRowsPerPage={(event) =>
              // eslint-disable-next-line prettier/prettier
              changeRowsPerPage(event.target.value)}
            rowsPerPageOptions={[10, 15, 100]}
          />
        )}
      </TableRow>
    </TableFooter>
  );
};
FooterAddLine.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  changeRowsPerPage: PropTypes.func,
  changePage: PropTypes.func,
  disabled: PropTypes.bool,
  addProduct: PropTypes.func.isRequired,
  hiddenPagination: PropTypes.bool,
};

FooterAddLine.defaultProps = {
  changeRowsPerPage: null,
  changePage: null,
  disabled: false,
  hiddenPagination: false,
};
export default FooterAddLine;
