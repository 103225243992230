import {
  FETCH_RANGE,
  FETCH_RANGE_ERROR,
  FETCH_RANGES_SUCCESS,
  FETCH_RANGES_ERROR,
  CLEAR_RANGE,
  CLEAR_RANGES,
} from "../types/rangeActionTypes";
import { LOADING_ACTION } from "../types/settingsActionTypes";

// eslint-disable-next-line import/no-cycle
import apiClient from "../../services/api/apiClient";
import { RANGE_URL } from "../../services/constants/urls";

export const fetchRange = (rangeId, accessToken) => async (dispatch) => {
  await dispatch({
    type: LOADING_ACTION,
    payload: true,
  });
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${RANGE_URL}${rangeId}`;
  try {
    const res = await apiClient({ method: "get", url, headers });
    await dispatch({
      type: FETCH_RANGE,
      payload: { range: res.data, rangeIsLoading: false },
    });
    await dispatch({
      type: LOADING_ACTION,
      payload: false,
    });
  } catch (e) {
    await dispatch({
      type: FETCH_RANGE_ERROR,
      payload: { error: e },
    });
    await dispatch({
      type: LOADING_ACTION,
      payload: false,
    });
  }
};

export const fetchProjectRanges = (projectId, accessToken) => async (
  dispatch
) => {
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${RANGE_URL}?project_id=${projectId}`;
  try {
    const res = await apiClient({ method: "get", url, headers });
    dispatch({
      type: FETCH_RANGES_SUCCESS,
      payload: res.data.results,
    });
  } catch (e) {
    dispatch({
      type: FETCH_RANGES_ERROR,
      payload: { error: e },
    });
  }
};

export const clearRanges = () => async (dispatch) => {
  dispatch({
    type: CLEAR_RANGE,
    payload: {},
  });
  dispatch({
    type: CLEAR_RANGES,
    payload: [],
  });
};

export const updateRangeAction = async (data, rangeId, accessToken) => {
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${RANGE_URL}${rangeId}`;

  const res = await apiClient({ method: "patch", url, headers, data });
  return res;
};
