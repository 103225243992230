import {
  FETCH_PROJECT_COMMENTS,
  ADD_PROJECT_COMMENTS,
  ERROR_FETCHING_PROJECT_COMMENTS,
  UPDATE_PROJECT_COMMENTS,
  FETCH_PROJECT_COMMENT_READ,
  ADD_PROJECT_COMMENT_READ,
  ERROR_FETCHING_PROJECT_COMMENT_READ,
  UPDATE_PROJECT_COMMENT_READ,
} from "../types/projectCommentsActionTypes";
import { LOADING_ACTION } from "../types/settingsActionTypes";

import { openSnackbar } from "./snackbarActions";
import apiClient from "../../services/api/apiClient";

export const fetchProjectComments = (user, project_id, pathname) => async (
  dispatch
) => {
  await dispatch({
    type: LOADING_ACTION,
    payload: true,
  });
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${user.accessToken}`,
  };
  const url = `${process.env.REACT_APP_POPSER_API_URL}/comments/?project_id=${project_id}&pathname=${pathname}`;
  try {
    const res = await apiClient({ method: "get", url, headers });
    dispatch({
      type: FETCH_PROJECT_COMMENTS,
      payload: res.data,
    });
    await dispatch({
      type: LOADING_ACTION,
      payload: false,
    });
  } catch (e) {
    dispatch({
      type: ERROR_FETCHING_PROJECT_COMMENTS,
      payload: { error: e },
    });
    await dispatch({
      type: LOADING_ACTION,
      payload: false,
    });
  }
};
export const addProjectComments = (user, data) => async (dispatch) => {
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${user.accessToken}`,
  };
  const url = `${process.env.REACT_APP_POPSER_API_URL}/comments/`;

  try {
    const res = await apiClient({ method: "post", url, headers, data });
    dispatch({
      type: ADD_PROJECT_COMMENTS,
      payload: res.data,
    });
    dispatch(openSnackbar("Commentaire envoyé avec succès", "Success"));
  } catch (e) {
    dispatch({
      type: ERROR_FETCHING_PROJECT_COMMENTS,
      payload: { error: e },
    });
  }
};
export const updateProjectComments = (user, data, commentId) => async (
  dispatch
) => {
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${user.accessToken}`,
  };
  const url = `${process.env.REACT_APP_POPSER_API_URL}/comments/${commentId}/`;

  try {
    const res = await apiClient({ method: "patch", url, headers, data });
    dispatch({
      type: UPDATE_PROJECT_COMMENTS,
      payload: res.data,
    });
    dispatch(openSnackbar("Commentaire modifié avec succès", "Success"));
  } catch (e) {
    dispatch({
      type: ERROR_FETCHING_PROJECT_COMMENTS,
      payload: { error: e },
    });
  }
};

export const fetchProjectCommentRead = (user, project_id) => async (
  dispatch
) => {
  await dispatch({
    type: LOADING_ACTION,
    payload: true,
  });
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${user.accessToken}`,
  };
  const url = `${process.env.REACT_APP_POPSER_API_URL}/commentRead/?project_id=${project_id}`;
  try {
    const res = await apiClient({ method: "get", url, headers });
    dispatch({
      type: FETCH_PROJECT_COMMENT_READ,
      payload: res.data,
    });
    await dispatch({
      type: LOADING_ACTION,
      payload: false,
    });
  } catch (e) {
    dispatch({
      type: ERROR_FETCHING_PROJECT_COMMENT_READ,
      payload: { error: e },
    });
    await dispatch({
      type: LOADING_ACTION,
      payload: false,
    });
  }
};

export const addProjectCommentRead = (user, data) => async (dispatch) => {
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${user.accessToken}`,
  };
  const url = `${process.env.REACT_APP_POPSER_API_URL}/commentRead/`;

  try {
    const res = await apiClient({ method: "post", url, headers, data });
    dispatch({
      type: ADD_PROJECT_COMMENT_READ,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: ERROR_FETCHING_PROJECT_COMMENT_READ,
      payload: { error: e },
    });
  }
};
export const updateProjectCommentRead = (user, data, commentId) => async (
  dispatch
) => {
  console.log(user);
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${user.accessToken}`,
  };
  const url = `${process.env.REACT_APP_POPSER_API_URL}/commentRead/${commentId}/`;

  try {
    const res = await apiClient({ method: "patch", url, headers, data });
    dispatch({
      type: UPDATE_PROJECT_COMMENT_READ,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: ERROR_FETCHING_PROJECT_COMMENT_READ,
      payload: { error: e },
    });
  }
};

export const updateAsReadProjectCommentRead = (user, commentId) => async (
  dispatch
) => {
  dispatch(updateProjectCommentRead(user, { is_read: true }, commentId));
};
export default fetchProjectComments;
