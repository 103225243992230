/* eslint-disable radix */
import React from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import {
  Card as MuiCard,
  CardContent,
  Typography,
  makeStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

import { useForm } from "react-hook-form";

import { spacing } from "@material-ui/system";

import { useSelector } from "react-redux";
import FieldWithSuffix from "../ElementsField/FieldWithSuffix";
import {
  getTotalProduct,
  getConsumedMaterials,
  getAddedValue,
  getGrossMargin,
  getAddedValuePercentage,
} from "../../services/sig/SigCalculService";

const Card = styled(MuiCard)(spacing);

const useStyles = makeStyles({
  margin: {
    marginTop: "12px",
  },
  input: {
    width: "90px!important",
  },
  gridInput: {
    borderLeft: "solid 1px",
    paddingLeft: "30px",
    borderBottom: "solid 1px",
  },
  gridTitle: {
    borderBottom: "solid 1px",
    paddingBottom: "11px",
    paddingTop: "21px",
  },
  tableEqualWidth: {
    "& th": { width: "20%!important" },
  },
  tableEqualWidthTotal: {
    "& th": { width: "20%!important", border: "0px!important" },
    "& td": { border: "0px!important" },
  },

  iconEdit: {
    fontSize: "1em",
    color: "#1876d1bd",
  },
  card: {
    paddingBottom: "0px!important",
  },
  gridColor: {
    backgroundColor: "#1876d121",
  },
  gridColorTotal: {
    backgroundColor: "#1b2430",
  },
  componentSig: {
    marginBottom: "0px!important",
  },
  noPadding: {
    paddingBottom: "0px",
    paddingTop: "0px",
  },
  noPaddingTh: {
    "& th": {
      padding: "0px",
    },
  },
  paddingThTd: {
    "& th": {
      paddingTop: "5px!important",
      paddingBottom: "5px!important",
    },
    "& td": {
      paddingTop: "5px!important",
      paddingBottom: "5px!important",
    },
  },
  colorShadow: {
    backgroundColor: "#1b2430",

    "& input": {
      color: "white",
      fontSize: "medium",
      fontWeight: "500",
      marginBottom: "5px",
    },
    "& h5": {
      color: "white",
    },
  },
});
const AddedValue = () => {
  const { register, control, errors } = useForm();
  const classes = useStyles();
  const { t } = useTranslation();
  const sig = useSelector((state) => state.sig.sig);
  const external_costs_summary = useSelector(
    (state) => state.sig.external_costs_summary
  );
  const addedValue = getAddedValue(
    getGrossMargin(getTotalProduct(sig), getConsumedMaterials(sig)),
    external_costs_summary.external_cost_total
  );
  return (
    <>
      <Card mb={12}>
        <CardContent
          className={[classes.card, classes.noPadding, classes.colorShadow]}
        >
          <TableContainer component={Paper}>
            <Table
              className={[classes.table, classes.tableEqualWidthTotal]}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow className={[classes.noPaddingTh]}>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody className={classes.paddingThTd}>
                <TableRow key="gross_margin" className={classes.gridColorTotal}>
                  <TableCell component="th" scope="row">
                    <Typography variant="h5" gutterBottom display="inline">
                      <Trans i18nKey="Valeur ajoutée :">Valeur ajoutée :</Trans>
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    <FieldWithSuffix
                      disabled
                      name="operating_result"
                      control={control}
                      errors={errors}
                      suffix={t("€")}
                      value={addedValue}
                      register={register}
                    />
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    <FieldWithSuffix
                      disabled
                      name="operating_result_percentage"
                      control={control}
                      errors={errors}
                      suffix={t("%")}
                      value={getAddedValuePercentage(
                        addedValue,
                        getTotalProduct(sig)
                      )}
                      register={register}
                    />
                  </TableCell>
                  <TableCell align="right" />
                  <TableCell align="right" />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
};
export default AddedValue;
