export const FETCH_PROJECT_COMMENTS = "FETCH_PROJECT_COMMENTS";
export const ADD_PROJECT_COMMENTS = "ADD_PROJECT_COMMENTS";
export const UPDATE_PROJECT_COMMENTS = "UPDATE_PROJECT_COMMENTS";
export const ERROR_FETCHING_PROJECT_COMMENTS =
  "ERROR_FETCHING_PROJECT_COMMENTS";
export const FETCH_PROJECT_COMMENT_READ = "FETCH_PROJECT_COMMENT_READ";
export const ADD_PROJECT_COMMENT_READ = "ADD_PROJECT_COMMENT_READ";
export const ERROR_FETCHING_PROJECT_COMMENT_READ =
  "ERROR_FETCHING_PROJECT_COMMENT_READ";
export const UPDATE_PROJECT_COMMENT_READ = "UPDATE_PROJECT_COMMENT_READ";
