import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { isLoaded, isEmpty } from "react-redux-firebase";
import { makeStyles } from "@material-ui/core/styles";
import Loading from "../Loading/Loading";

const useStyles = makeStyles(() => ({
  hidden: {
    "& .MuiPaper-elevation1": {
      visibility: "hidden",
    },
  },
}));
const ProtectedRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const classes = useStyles();
  const auth = useSelector((state) => state.firebase.auth);
  const isLoading = useSelector((state) => state.settings.is_loading);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoaded(auth) && !isEmpty(auth)) {
          return (
            <>
              {isLoading ? <Loading /> : null}
              <div className={isLoading ? classes.hidden : null}>
                <Layout>
                  <Component {...props} />
                </Layout>
              </div>
            </>
          );
        }
        return <Redirect to="/auth/sign-in" />;
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  layout: PropTypes.func.isRequired,
};

export default ProtectedRoute;
