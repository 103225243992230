import { RANGE_URL } from "../constants/urls";
import axios from "./apiClient";

export const addRange = async (user, data) => {
  let result;
  const headers = {
    "content-type": "multipart/form-data",
    Authorization: `Bearer ${user.accessToken}`,
  };

  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("project", localStorage.getItem("project_id"));
  formData.append("tva_rate", data.tva_rate);

  try {
    const res = await axios({
      method: "post",
      url: `${process.env.REACT_APP_POPSER_API_URL}/range/`,
      data: formData,
      headers,
    });
    if (res.status === 201) {
      result = res.data;
    }
  } catch (error) {
    result = error;
  }
  return result;
};

export const updateRange = async (user, data, rangeId) => {
  let result;

  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${user.accessToken}`,
  };

  try {
    const res = await axios({
      method: "patch",
      url: `${process.env.REACT_APP_POPSER_API_URL}/range/${rangeId}`,
      data: {
        name: data.name,
        tva_rate: data.tva_rate,
        number_check_total: data.number_check_total,
        number_custom_by_day: data.number_custom_by_day,
        number_day_by_year: data.number_day_by_year,
        number_option_check_range: data.number_option_check_range,
        number_custom_by_year_number_check_total:
          data.number_custom_by_year_number_check_total,
        number_custom_by_year_number_option_check_range:
          data.number_custom_by_year_number_option_check_range,
        coefficient_k: data.coefficient_k || null,
        variation_popularity: data.variation_popularity || null,
        variation_rentability: data.variation_rentability || null,
      },
      headers,
    });
    if (res.status === 200) {
      result = res.data;
    }
  } catch (error) {
    result = error;
  }
  return result;
};

export const fetchRangeApi = async (rangeId, accessToken) => {
  let range = {};
  const headers = {
    "Content-Type": `application/json;`,
    Authorization: `Bearer ${accessToken}`,
  };
  const url = `${RANGE_URL}${rangeId}`;
  try {
    const res = await axios({ method: "get", url, headers });
    range = res.data;
  } catch (e) {
    console.log(e);
  }
  return range;
};
