import React, { useEffect } from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import {
  Card as MuiCard,
  CardContent,
  makeStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Typography,
  IconButton,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import AddIcon from "@material-ui/icons/Add";
import ExternalCostModel from "../../models/ExternalCostModel";
import {
  postNewWorkforceCost,
  putWorkforceCost,
} from "../../redux/actions/sigActions";
import FieldWithSuffix from "../ElementsField/FieldWithSuffix";
import {
  getExternalCostTotal,
  getExternalCostTotalFixedPart,
  getExternalCostTotalFixedPartPercentage,
  getExternalCostTotalPercentage,
} from "../../services/external_costs/external_costs_summary";
import { UPDATE_WORKFORCE_COSTS_SUMMARY } from "../../redux/types/sigActionTypes";
import { getIndicatorPercentage } from "../../services/external_costs/external_costs_service";
import { getTotalProduct } from "../../services/sig/SigCalculService";
import WorkforceCost from "./WorkforceCost";

const Card = styled(MuiCard)(spacing);

const useStyles = makeStyles({
  margin: {
    marginTop: "12px",
  },
  componentSig: {
    marginBottom: "0px!important",
  },
  input: {
    width: "90px!important",
  },
  gridInput: {
    borderLeft: "solid 1px",
    paddingLeft: "30px",
    borderBottom: "solid 1px",
  },
  gridTitle: {
    borderBottom: "solid 1px",
    paddingBottom: "11px",
    paddingTop: "21px",
  },
  tableEqualWidth: {
    "& th": { width: "20%!important" },
  },
  iconEdit: {
    fontSize: "1em",
    color: "#1876d1bd",
  },
  card: {
    paddingBottom: "0px!important",
  },
  gridColor: {
    backgroundColor: "#1876d121",
  },
  disableField: {
    "& td div": {
      color: "black!important",
      fontWeight: "600",
    },
  },
  buttonStyle: {
    height: "100%",
    padding: "16px",
    background: "transparent",
    width: "100%",
    borderRadius: "0",
  },
});
const WorkforceCosts = () => {
  const classes = useStyles();
  const workforce_costs = useSelector((state) => state.sig.workforce_costs);
  const sig = useSelector((state) => state.sig.sig);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;
  const currentProject = useSelector((state) => state.projects.currentProject);
  const { register, control, errors } = useForm();
  const { t } = useTranslation();
  const workforce_costs_summary = useSelector(
    (state) => state.sig.workforce_costs_summary
  );

  useEffect(async () => {
    await dispatch({
      type: UPDATE_WORKFORCE_COSTS_SUMMARY,
      payload: {
        ...workforce_costs_summary,
        workforce_cost_total_percentage: getExternalCostTotalPercentage(
          workforce_costs_summary.workforce_cost_total,
          sig
        ),
      },
    });
    // Pour chaque cout externe, modifier le pourcentage de l'indicateur
    const external_costs_copy = [...workforce_costs];
    external_costs_copy.map(async (ec) => {
      const indicator_percentage = getIndicatorPercentage(
        ec.indicator,
        getTotalProduct(sig)
      );
      await dispatch(
        putWorkforceCost(
          {
            ...ec,
            indicator_percentage,
          },
          workforce_costs,
          user.accessToken
        )
      );
    });
  }, [sig.net_turnover_excl_tax, sig.others_products]);

  useEffect(async () => {
    if (workforce_costs.length > 0) {
      const external_cost_total = getExternalCostTotal(workforce_costs);
      dispatch({
        type: UPDATE_WORKFORCE_COSTS_SUMMARY,
        payload: {
          workforce_cost_total: external_cost_total,
          workforce_cost_total_percentage: getExternalCostTotalPercentage(
            external_cost_total,
            sig
          ),
          workforce_cost_total_fixed_part: getExternalCostTotalFixedPart(
            workforce_costs
          ),
          workforce_cost_total_fixed_part_percentage: getExternalCostTotalFixedPartPercentage(
            workforce_costs
          ),
        },
      });
    }
  }, [workforce_costs.length]);

  const add_external_costs = () => {
    const externalCost = new ExternalCostModel();
    externalCost.line_name = "Nouvelle Charge de Personnel";
    externalCost.project = currentProject.id;
    externalCost.cost_type = "STAFF";
    externalCost.intermediate_management_threshold = sig.id;

    dispatch(postNewWorkforceCost(externalCost, user.accessToken));
  };

  return (
    <Card mb={12} className={classes.componentSig}>
      <CardContent className={classes.card}>
        <TableContainer component={Paper}>
          <Table
            className={[classes.table, classes.tableEqualWidth]}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell align="center">
                  {" "}
                  <Trans i18nKey="Montant en (en €)">Montant en (en €)</Trans>
                </TableCell>
                <TableCell align="center">%</TableCell>
                <TableCell align="center">
                  <Trans>Part fixe (en €)</Trans>
                </TableCell>
                <TableCell align="center">
                  <Trans>Part fixe (en %)</Trans>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                key="total_product"
                className={[classes.gridColor, classes.disableField]}
              >
                <TableCell component="th" scope="row">
                  <Typography variant="h5" gutterBottom display="inline">
                    <Trans i18nKey="Charges de personnel">
                      Charges de personnel
                    </Trans>
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <FieldWithSuffix
                    disabled
                    name="workforce_cost_total"
                    control={control}
                    errors={errors}
                    suffix={t("€")}
                    value={workforce_costs_summary.workforce_cost_total}
                    register={register}
                  />
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <FieldWithSuffix
                    disabled
                    name="workforce_cost_total_percentage"
                    control={control}
                    errors={errors}
                    suffix={t("%")}
                    value={
                      workforce_costs_summary.workforce_cost_total_percentage
                    }
                    register={register}
                  />
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <FieldWithSuffix
                    disabled
                    name="workforce_cost_total_fixed_part"
                    control={control}
                    errors={errors}
                    suffix={t("€")}
                    value={
                      workforce_costs_summary.workforce_cost_total_fixed_part
                    }
                    register={register}
                  />
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <FieldWithSuffix
                    disabled
                    name="workforce_cost_total_fixed_part_percentage"
                    control={control}
                    errors={errors}
                    suffix={t("%")}
                    value={
                      workforce_costs_summary.workforce_cost_total_fixed_part_percentage
                    }
                    register={register}
                  />
                </TableCell>
                <TableCell align="right" />
              </TableRow>
              {workforce_costs.map((external_cost) => {
                return (
                  <WorkforceCost
                    external_cost_uuid={external_cost.uuid}
                    key={external_cost.uuid}
                  />
                );
              })}
              <TableRow key="add_external_cost">
                <TableCell colspan={6} component="th" scope="row">
                  <Tooltip
                    disableFocusListener
                    title={t("Ajouter une charge de personnel")}
                  >
                    <IconButton
                      color="inherit"
                      aria-label="Open drawer"
                      onClick={add_external_costs}
                      className={classes.buttonStyle}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default WorkforceCosts;
