// eslint-disable-next-line no-unused-vars
import React, { useEffect } from "react";
import styled from "styled-components";

import {
  makeStyles,
  Grid,
  Card as MuiCard,
  CardContent,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
// import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { spacing } from "@material-ui/system";

import { useSelector } from "react-redux";
import FieldWithSuffix from "../ElementsField/FieldWithSuffix";
import DailyIndicator from "./DailyIndicator";
import WeeklyIndicator from "./WeeklyIndicator";
import YearlyIndicator from "./YearlyIndicators";
import KIndicator from "./KIndicator";
import {
  handleChangeAveragePriceIncreasedForSerForecast,
  handleChangeAveragePriceIncreasedForSerGrowTurnover,
} from "../../services/cser/cserInteractions";

const Card = styled(MuiCard)(spacing);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  componentSimulationTable: {
    "& input": {
      textAlign: "center",
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
  margin: {
    marginTop: "24px",
    marginBottom: "24px",
  },
  tableEqualWidth: {
    "& th": {},
  },
  disableField: {
    "& td div": {
      color: "black!important",
    },
  },
  inline: {
    "& div": {
      display: "inline-grid",
      marginLeft: "12px",
    },
  },
  titleHeaderCell: {
    fontSize: "0.875rem",
  },
  noPaddingThTd: {
    "& th": {
      padding: "5px",
    },
    "& td": {
      padding: "5px",
    },
  },
  borderRight: {
    borderRight: "solid 2px #1876d2",
  },
  width10: {
    width: "10%",
  },
  width20: {
    width: "20%",
  },
  width8: {
    width: "8%",
  },
}));

const IndicatorTable = () => {
  const { register, control, errors } = useForm();
  const { t } = useTranslation();

  const classes = useStyles();
  const ser_growth_turnover = useSelector(
    (state) => state.cser.computed_cser.ser_growth_turnover
  );

  const ser_forecast = useSelector(
    (state) => state.cser.computed_cser.ser_forecast
  );
  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Card mb={12} className={classes.componentSimulationTable}>
            <CardContent className={classes.card}>
              <TableContainer component={Paper}>
                <Table
                  className={
                    ([classes.table, classes.tableEqualWidth],
                    classes.disableField,
                    classes.noPaddingThTd)
                  }
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        className={[classes.borderRight, classes.width10]}
                      />
                      <TableCell align="center" className={classes.width10}>
                        <Trans i18nKey="SIG retraité">SIG retraité</Trans>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={[classes.borderRight, classes.width10]}
                      >
                        <Trans i18nKey="%">%</Trans>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={[classes.borderRight, classes.width10]}
                      >
                        <Trans i18nKey="Seuil de rentabilité (en €)">
                          Seuil de rentabilité (en €)
                        </Trans>
                      </TableCell>
                      <Tooltip
                        disableFocusListener
                        title={t("Avec la seule croissance du CA HT")}
                      >
                        <TableCell
                          align="center"
                          className={[classes.borderRight, classes.width20]}
                          colSpan={3}
                        >
                          <Trans i18nKey="Simulation du Seuil d'Excédent Recherché (SER)">
                            Simulation du Seuil d&apos;Excédent Recherché (SER)
                          </Trans>
                        </TableCell>
                      </Tooltip>
                      <Tooltip
                        disableFocusListener
                        title={t(
                          "Variation du CA (en %), du Taux de CV (en %) et des Charges Fixes (en €)"
                        )}
                      >
                        <TableCell
                          align="center"
                          className={classes.width10}
                          colSpan={2}
                        >
                          <Trans i18nKey="Variations estimées">
                            Variations estimées
                          </Trans>
                        </TableCell>
                      </Tooltip>
                      <Tooltip
                        disableFocusListener
                        title={t(
                          "Seuil d'Excédent Recherché en fonction de la Variation du CA (en %), du Taux de CV (en %) et des Charges Fixes (en €)"
                        )}
                      >
                        <TableCell
                          align="left"
                          className={[classes.borderRight, classes.width10]}
                        >
                          <Trans i18nKey="Prévisionnel (SER)">
                            Prévisionnel (SER)
                          </Trans>
                        </TableCell>
                      </Tooltip>
                      <Tooltip
                        disableFocusListener
                        title={t(
                          "En fonction du coefficient multiplicateur estimé"
                        )}
                      >
                        <TableCell align="center" className={classes.width10}>
                          <Trans i18nKey="Simulation SER">Simulation SER</Trans>
                        </TableCell>
                      </Tooltip>
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    <TableRow className={[classes.noPaddingTh]}>
                      <TableCell
                        align="center"
                        className={classes.borderRight}
                      />
                      <TableCell align="center" />
                      <TableCell
                        align="center"
                        className={classes.borderRight}
                      />
                      <TableCell
                        align="center"
                        className={classes.borderRight}
                      />
                      <TableCell
                        colSpan={3}
                        align="center"
                        className={classes.borderRight}
                      >
                        <Trans i18nKey="Dans l'hypothèse :">
                          Dans l&apos;hypothèse :
                        </Trans>
                      </TableCell>
                      <TableCell
                        colSpan={3}
                        align="center"
                        className={classes.borderRight}
                      >
                        <Trans i18nKey="Dans l'hypothèse :">
                          Dans l&apos;hypothèse :
                        </Trans>
                      </TableCell>
                      <TableCell align="center" />
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    <TableRow className={[classes.noPaddingTh]}>
                      <TableCell
                        align="center"
                        className={classes.borderRight}
                      />
                      <TableCell align="center" />
                      <TableCell
                        align="center"
                        className={classes.borderRight}
                      />
                      <TableCell
                        align="center"
                        className={classes.borderRight}
                      />
                      <TableCell align="center">
                        <Trans i18nKey="D'un prix moyen stable">
                          D&apos;un prix moyen stable
                        </Trans>
                      </TableCell>
                      <TableCell align="center">
                        <Trans i18nKey="D'un taux d'occupation stable">
                          D&apos;un taux d&apos;occupation stable
                        </Trans>
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <Trans i18nKey="D'un prix moyen augmenté de">
                          D&apos;un prix moyen augmenté de
                        </Trans>
                        <FieldWithSuffix
                          isEdit
                          name="ser_growth_turnover_average_price_increase"
                          control={control}
                          errors={errors}
                          suffix={t("%")}
                          onChangeField={
                            handleChangeAveragePriceIncreasedForSerGrowTurnover
                          }
                          value={
                            ser_growth_turnover.average_price_increase
                              .price_increase
                          }
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Trans i18nKey="D'un prix moyen stable">
                          D&apos;un prix moyen stable
                        </Trans>
                      </TableCell>
                      <TableCell align="center">
                        <Trans i18nKey="D'un taux d'occupation stable">
                          D&apos;un taux d&apos;occupation stable
                        </Trans>
                      </TableCell>
                      <TableCell align="center" className={classes.borderRight}>
                        <Trans i18nKey="D'un prix moyen augmenté de">
                          D&apos;un prix moyen augmenté de
                        </Trans>
                        <FieldWithSuffix
                          isEdit
                          name="turnover_before_tax_adjusted_imt"
                          control={control}
                          errors={errors}
                          suffix={t("%")}
                          value={
                            ser_forecast.average_price_increase.price_increase
                          }
                          onChangeField={
                            handleChangeAveragePriceIncreasedForSerForecast
                          }
                          register={register}
                        />
                      </TableCell>
                      <TableCell align="center" />
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.paddingThTd}>
                    <DailyIndicator />
                    <WeeklyIndicator />
                    <YearlyIndicator />
                    <KIndicator />
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default IndicatorTable;
