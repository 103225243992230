/* eslint-disable react/jsx-one-expression-per-line */
import { Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

const RangeTitle = () => {
  const { t } = useTranslation();
  const { real_mode, name } = useSelector((state) => state.ranges.range);
  return (
    <>
      <Helmet>
        <title>{`${t("Gamme")} ${name}`}</title>
      </Helmet>
      <Typography variant="h3" gutterBottom display="inline">
        {t("Gamme")}: {name}
        &nbsp;
        {`(mode ${real_mode ? t("Réel") : t("Prévisionnel")})`}
      </Typography>
    </>
  );
};

export default RangeTitle;
