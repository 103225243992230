/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";

import {
  Button,
  Divider as MuiDivider,
  Grid,
  Typography,
} from "@material-ui/core";

import { Add as AddIcon } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import RangeTable from "../../components/Ranges/RangeTable";
import RangeDetails from "../../components/Ranges/RangeDetails";

const Divider = styled(MuiDivider)(spacing);

const useStyles = makeStyles(() => ({
  margin: {
    marginTop: "24px",
    marginBottom: "24px",
  },
}));

function RangeList() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [rangeDetails, setRangeDetails] = useState({
    id: null,
    name: null,
    owner: null,
    seasonnality: null,
    tva_rate: null,
  });
  return (
    <>
      <Helmet>
        <title>{t("Liste des Gammes")}</title>
      </Helmet>
      <Grid justify="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            <Trans i18nKey="Gammes">Gammes</Trans>
          </Typography>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/range/add"
            >
              <AddIcon />
              <Trans i18nKey="Créer">Créer</Trans>
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} mr={24} className={classes.margin} />

      <Grid container>
        <Grid item xs={8} className={classes.margin}>
          <RangeTable setRangeDetails={setRangeDetails} />
        </Grid>
        <Grid item xs={4} className={classes.margin}>
          <RangeDetails rangeDetails={rangeDetails} />
        </Grid>
      </Grid>
    </>
  );
}

export default RangeList;
