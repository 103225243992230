import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Card,
  CardContent,
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Divider,
} from "@material-ui/core";

import { Trans } from "react-i18next";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import computeCserData from "../../services/cser/computeCserData";
import {
  fetchSig,
  fetchExternalCosts,
  fetchWorkforceCosts,
} from "../../redux/actions/sigActions";
import { fetchCser } from "../../redux/actions/cserActions";

const useStyles = makeStyles(() => ({
  input: {
    "&::before": {},
    width: "100%",
  },
  root: {
    "& .MuiInput-underline:before": {
      borderBottom: "solid 1px", //
    },
  },
}));

const offerGoal = () => {
  const classes = useStyles();
  const currentProject = useSelector((state) => state.projects.currentProject);
  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;
  const computed_cser = useSelector((state) => state.cser.computed_cser);
  const dispatch = useDispatch();
  useEffect(async () => {
    await dispatch(fetchSig(currentProject.id, user.accessToken));
    await dispatch(fetchCser(currentProject.id, user.accessToken));
    await dispatch(
      fetchExternalCosts(currentProject.id, user.accessToken, "EXTERNAL")
    );
    await dispatch(
      fetchWorkforceCosts(currentProject.id, user.accessToken, "STAFF")
    );
    await computeCserData();
  }, [currentProject]);

  return (
    <>
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom display="inline">
            <Trans i18nKey="Objectifs">Objectifs</Trans>
          </Typography>
          <Divider my={6} mr={24} className={classes.margin} />

          <Grid container spacing={2}>
            <Table>
              <TableBody className={classes.root}>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Trans i18nKey="Marge Brute annuelle du SER">
                      Marge Brute annuelle du SER
                    </Trans>
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      disabled
                      value={
                        computed_cser.adjusted_imt
                          .indicators_gross_margin_in_euros_excluding_consumed_production
                      }
                      thousandSeparator={" "}
                      name="MBserAnnual"
                      customInput={TextField}
                      className={classes.input}
                      suffix="€"
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Trans i18nKey="Marge Brute hebdo du SER">
                      Marge Brute hebdo du SER
                    </Trans>
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      disabled
                      value={
                        computed_cser.adjusted_imt
                          .indicators_gross_margin_in_euros
                      }
                      name="MBserWeek"
                      customInput={TextField}
                      className={classes.input}
                      thousandSeparator={" "}
                      suffix="€"
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Trans i18nKey="Coefficient multiplicateur du SER (CSER)">
                      Coefficient multiplicateur du SER (CSER)
                    </Trans>
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      disabled
                      value={computed_cser.adjusted_imt.indicators_coefficients}
                      name="Cser"
                      customInput={TextField}
                      className={classes.input}
                      suffix=""
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Trans i18nKey="Ratio matière du SER">
                      Ratio matière du SER
                    </Trans>
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      disabled
                      name="Ratio ser"
                      customInput={TextField}
                      className={classes.input}
                      value={
                        computed_cser.adjusted_imt.indicators_ratio_of_materials
                      }
                      suffix="%"
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Trans i18nKey="Nombre de clients hebdomadaire du SER">
                      Nombre de clients hebdomadaire du SER
                    </Trans>
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      disabled
                      name="NumberCustomersWeeks"
                      customInput={TextField}
                      className={classes.input}
                      thousandSeparator={" "}
                      value={
                        computed_cser.adjusted_imt
                          .indicators_number_of_customers_by_week
                      }
                      suffix=""
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default offerGoal;
