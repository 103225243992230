import React, { useEffect } from "react";
import Helmet from "react-helmet";

import { Divider, Typography, makeStyles, Grid } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import SimulationTable from "../../components/Cser/SimulationTable";
import IndicatorTable from "../../components/Cser/IndicatorTable";

import {
  fetchExternalCosts,
  fetchSig,
  fetchWorkforceCosts,
} from "../../redux/actions/sigActions";

import { fetchCser } from "../../redux/actions/cserActions";
import computeCserData from "../../services/cser/computeCserData";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
  margin: {
    marginTop: "24px",
    marginBottom: "24px",
  },
}));

const ShowCser = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const currentProject = useSelector((state) => state.projects.currentProject);
  const sig = useSelector((state) => state.sig.sig);
  const cser_data = useSelector((state) => state.cser.cser_data);
  const workforce_costs = useSelector((state) => state.sig.workforce_costs);
  const external_costs = useSelector((state) => state.sig.external_costs);
  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;

  useEffect(async () => {
    await dispatch(fetchSig(currentProject.id, user.accessToken));
    await dispatch(fetchCser(currentProject.id, user.accessToken));
    await dispatch(
      fetchExternalCosts(currentProject.id, user.accessToken, "EXTERNAL")
    );
    await dispatch(
      fetchWorkforceCosts(currentProject.id, user.accessToken, "STAFF")
    );
  }, [currentProject]);

  useEffect(async () => {
    computeCserData();
  }, [sig, cser_data, workforce_costs, external_costs]);
  return (
    <>
      <Helmet>
        <title>{t("Cser")}</title>
      </Helmet>

      <Typography variant="h3" gutterBottom display="inline">
        <Trans i18nKey="Rentabilité actuelle, Seuil de Rentabilité et Seuil d'Excédent Recherché">
          Rentabilité actuelle, Seuil de Rentabilité et Seuil d&apos;Excédent
          Recherché
        </Trans>
      </Typography>
      <Divider my={6} mr={24} className={classes.margin} />
      <Grid container spacing={2} className={classes.disableField}>
        <Grid xs={12}>
          <SimulationTable />
        </Grid>
        <Grid xs={12}>
          <IndicatorTable />
        </Grid>
        <Grid xs={3} />
      </Grid>
    </>
  );
};

export default ShowCser;
