import React, { useState } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Helmet from "react-helmet";
import { useForm } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useFirebase, isLoaded, isEmpty } from "react-redux-firebase";
import {
  Avatar,
  Button as MuiButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import Loading from "../../components/Loading/Loading";

const Button = styled(MuiButton)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

const BigAvatar = styled(Avatar)`
  width: 92px;
  height: 92px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)}px;
`;

function SignIn() {
  const firebase = useFirebase();
  const auth = useSelector((state) => state.firebase.auth);
  const history = useHistory();

  const { handleSubmit, register, errors } = useForm();
  const [notificationSignIn, setNotificationSignIn] = useState(null);
  const { t } = useTranslation();
  const signInWithEmailAndPasswordHandler = (data) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(data.email, data.password)
      .then((user) => {
        if (user.user.emailVerified) {
          history.push("/");
        } else {
          firebase.auth.signOut();
          setNotificationSignIn(
            t(
              "Please validate your account by clicking the link you received by email (remember to check your junk mail or SPAM)"
            )
          );
        }
      })
      .catch(() => {
        setNotificationSignIn(
          t("Votre identifiant ou votre mot de passe n'a pas été reconnu")
        );
      });
  };

  const submitData = (data) => {
    signInWithEmailAndPasswordHandler(data);
  };

  if (!isLoaded(auth)) {
    return <Loading />;
  }
  if (isEmpty(auth)) {
    return (
      <Wrapper>
        <Helmet title="Sign In" />
        <BigAvatar>
          <LockOutlinedIcon />
        </BigAvatar>

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          <Trans i18nKey="Connexion">Connexion</Trans>
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          <Trans i18nKey="Connectez-vous pour continuer">
            Connectez-vous pour continuer
          </Trans>
        </Typography>
        <form onSubmit={handleSubmit(submitData)}>
          <TextField
            margin="normal"
            fullWidth
            id="email"
            label={<Trans i18nKey="Adresse Email">Adresse Email</Trans>}
            name="email"
            autoFocus
            inputRef={register({
              required: "Required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              },
            })}
            error={"email" in errors}
            helperText={
              "email" in errors ? (
                <Trans i18nKey="Veuillez entrer votre email">
                  Veuillez entrer votre email
                </Trans>
              ) : (
                ""
              )
            }
          />
          <TextField
            fullWidth
            name="password"
            label={<Trans i18nKey="Mot de passe">Mot de passe</Trans>}
            type="password"
            id="password"
            error={"password" in errors}
            inputRef={register({
              required: "Required",
            })}
            helperText={
              "password" in errors ? (
                <Trans i18nKey="Veuillez entrer votre mot de passe">
                  Veuillez entrer votre mot de passe
                </Trans>
              ) : (
                ""
              )
            }
            autoComplete="current-password"
          />
          {notificationSignIn && (
            <p style={{ color: "red" }}>{notificationSignIn}</p>
          )}
          <Button margin="normal" type="submit" fullWidth variant="contained">
            <Trans i18nKey="Connexion">Connexion</Trans>
          </Button>
          <Grid container>
            <Grid item xs>
              <Button
                color="primary"
                component={Link}
                to="/auth/reset-password"
              >
                <Trans i18nKey="Mot de passe oublié ?">
                  Mot de passe oublié ?
                </Trans>
              </Button>
            </Grid>
            <Grid item>
              <Button color="primary" component={Link} to="/auth/sign-up">
                <Trans i18nKey="Pas de compte ? Inscrivez-vous">
                  Pas de compte ? Inscrivez-vous
                </Trans>
              </Button>
            </Grid>
          </Grid>
          <Box mt={5} />
        </form>
      </Wrapper>
    );
  }
  history.push("/");
  return null;
}

export default SignIn;
