/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {
  fetchProjects,
  addCurrentProject,
} from "../../redux/actions/projectActions";
import { resetStoreAction } from "../../redux/actions/userActions";
import { openSnackbar } from "../../redux/actions/snackbarActions";
import { fetchProjectCommentRead } from "../../redux/actions/projectCommentsActions";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  projectSelect: {
    "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "&:before": {
      borderBottom: "none",
    },
    "& div": {
      color: "white",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& input": {},
    "& svg": {
      color: "white",
    },
  },
}));

export default function ProjectSelect() {
  const classes = useStyles();
  const object = { name: "Séléctionner votre projet :" };
  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects);
  const [project, setProject] = useState({ id: -999, name: "" });
  const currentProject = useSelector((state) => state.projects.currentProject);
  const notificationComment = useSelector(
    (state) => state.projectComments.commentRead
  );

  const history = useHistory();
  useEffect(() => {
    dispatch(fetchProjects(user));
    setProject(currentProject);
    dispatch(fetchProjectCommentRead(user, currentProject.id));
  }, [currentProject]);

  const handleChange = async (event) => {
    setProject(event.target.value);
    await dispatch(
      addCurrentProject({
        id: event.target.value.id,
        name: event.target.value.name,
      })
    );
    await dispatch(resetStoreAction());
    history.push("/projects");
    dispatch(
      openSnackbar(
        `${event.target.value.name} est le nouveau projet en cours`,
        "info"
      )
    );
  };
  return (
    <FormControl className={classes.formControl}>
      <Select
        className={classes.projectSelect}
        id="simple-select"
        value={project.name}
        displayEmpty
        renderValue={(selected) => {
          return selected || "Séléctionner votre projet :";
        }}
        onChange={handleChange}
      >
        <MenuItem value="" disabled>
          Séléctionner votre projet :
        </MenuItem>
        {projects.projects.map((data) => {
          return (
            <MenuItem name={data.name} key={data.id} value={data}>
              {data.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
