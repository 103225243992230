/* eslint-disable guard-for-in */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from "react";
import {
  Grid,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
  TableBody as MuiTableBody,
  IconButton,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";

import { useDispatch, useSelector } from "react-redux";

import NumberFormat from "react-number-format";
import { fetchProjectRanges } from "../../redux/actions/rangeActions";
import {
  registerOfferRanges,
  deleteOfferRanges,
} from "../../redux/actions/offerRangeActions";

import PanelAutoComplete from "../ElementsTable/FieldPanelAutoCompleted";
import OfferRangeModel from "../../models/OfferRangeModel";
import OfferModel from "../../models/OfferModel";
import OfferFooterAddLine from "./OfferFooterAddLine";
import { fetchRangeProducts } from "../../services/api/rangeProductAPI";
import { getListQuantitySold } from "../../services/range/RangeMethodService";
import { interactionTurnoverRange } from "../../services/range/RangeCalculInteractionService";
import {
  getParsedFloatNumber,
  toFixedNumber,
} from "../../services/range/RangeService";

const useStyles = makeStyles(() => ({
  margin: {
    marginTop: "24px!important",
    marginBottom: "24px!important",
    marginRight: "0px!important",
  },
  nameColumn: {
    // width: "100px",
  },
  buttonAdd: {
    width: "100%",
    borderRadius: "0",
  },
  inputList: {
    "&.MuiInputBase-input::before": {
      borderBottom: "0px",
    },
  },
  input: {
    "& input": {
      color: "#000000",
    },
    width: "100%",
  },

  HiddenIdShowColumn: {},
}));
const getTableTheme = () =>
  createMuiTheme({
    overrides: {
      MuiTableCell: {
        root: {
          border: "1px solid #ddd",
        },
      },

      MuiFormControlLabel: {
        root: {
          "&:first-child": { display: "none" },
        },
      },
    },
  });

const rangeOfferDetails = ({ offer, offerRanges }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const currentProject = useSelector((state) => state.projects.currentProject);
  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;
  const ranges = useSelector((state) => state.ranges.ranges);
  const [displayOfferSynthesis, setDisplayOfferSynthesis] = useState(true);
  const [offerSynthesis, setOfferSynthesis] = useState({
    total_customer_number: 0,
    total_sales_revenue_HT: 0,
    total_number_day: 0,
    total_material_consumption: 0,
    sum_gross_margin: 0,
    average_range_multiplier_coefficient: 0,
    average_material_ratio: 0,
  });
  useEffect(() => {
    dispatch(fetchProjectRanges(currentProject.id, user.accessToken));
  }, [currentProject]);

  const [listOfferRanges, setListOfferRanges] = useState([]);
  const [computedOfferRanges, setComputedOfferRanges] = useState([]);

  const computeOfferSynthesis = async (listOfComputedRanges) => {
    const resultOfferSynthesis = {
      total_customer_number: 0,
      total_sales_revenue_HT: 0,
      total_number_day: 0,
      total_material_consumption: 0,
      sum_gross_margin: 0,
      average_range_multiplier_coefficient: 0,
      average_material_ratio: 0,
    };
    for (const computedRange in listOfComputedRanges) {
      const rangeItem = listOfComputedRanges[computedRange].range;
      resultOfferSynthesis.total_customer_number += toFixedNumber(
        getParsedFloatNumber(rangeItem.customers_by_year),
        0
      );
      resultOfferSynthesis.total_sales_revenue_HT += toFixedNumber(
        getParsedFloatNumber(rangeItem.sales_revenue_HT),
        2
      );
      resultOfferSynthesis.total_number_day += toFixedNumber(
        getParsedFloatNumber(rangeItem.days_a_year),
        0
      );
      resultOfferSynthesis.total_material_consumption += toFixedNumber(
        getParsedFloatNumber(rangeItem.material_consumption_HT),
        2
      );
      resultOfferSynthesis.sum_gross_margin += toFixedNumber(
        getParsedFloatNumber(rangeItem.gross_margin),
        2
      );
      resultOfferSynthesis.average_range_multiplier_coefficient += toFixedNumber(
        getParsedFloatNumber(rangeItem.range_multiplier_coefficient),
        2
      );
      if (rangeItem.average_material_ratio !== Infinity) {
        resultOfferSynthesis.average_material_ratio += toFixedNumber(
          getParsedFloatNumber(rangeItem.average_material_ratio) / 100,
          6
        );
      }
    }
    resultOfferSynthesis.average_range_multiplier_coefficient = toFixedNumber(
      resultOfferSynthesis.average_range_multiplier_coefficient /
        listOfComputedRanges.length,
      2
    );
    resultOfferSynthesis.average_material_ratio = toFixedNumber(
      (resultOfferSynthesis.average_material_ratio /
        listOfComputedRanges.length) *
        100,
      2
    );
    setOfferSynthesis(resultOfferSynthesis);
  };

  const computeOfferRanges = async (_offerRanges) => {
    const listOfComputedRanges = [];
    // eslint-disable-next-line guard-for-in
    for (const offerRange in _offerRanges) {
      const offerRangeItem = _offerRanges[offerRange];

      if ("id" in offerRangeItem) {
        const products = await fetchRangeProducts(
          offerRangeItem.range.id,
          user.accessToken
        );
        if (products) {
          const rangeProducts = products.results;
          const listOfQuantitySoldsForEachProductsOfARange = await getListQuantitySold(
            rangeProducts
          );
          const computedRange = await interactionTurnoverRange(
            rangeProducts,
            listOfQuantitySoldsForEachProductsOfARange
          );
          listOfComputedRanges.push({
            range: { ...offerRangeItem.range, ...computedRange },
            uuid: offerRangeItem.uuid,
          });
        }
      }
    }
    computeOfferSynthesis(listOfComputedRanges);
    setComputedOfferRanges(listOfComputedRanges);
  };

  useEffect(() => {
    if (offerRanges) {
      computeOfferRanges(offerRanges);
    }
  }, [offerRanges]);

  useEffect(async () => {
    if (computedOfferRanges.length > 0) {
      setListOfferRanges([...computedOfferRanges]);
    }
  }, [computedOfferRanges]);

  const onChangeRangeName = (event, range) => {
    if (range) {
      dispatch(
        registerOfferRanges(
          currentProject.id,
          range.id,
          offer.id,
          user.accessToken
        )
      );
    }
  };

  const columns = [
    {
      name: "uuid",
      options: {
        display: false,
      },
    },
    {
      name: "range",
      label: t("Offre"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <PanelAutoComplete
              nameColumn={`name${tableMeta.rowData[0]}`}
              value={value}
              onChangeField={onChangeRangeName}
              tableMeta={tableMeta}
              listObject={ranges}
              updateValue={updateValue}
            />
          );
        },
      },
    },
    {
      name: "range.customers_by_year",
      label: t("Nombre de clients"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <NumberFormat
            disabled
            value={value}
            thousandSeparator={" "}
            name="range.customers_by_year"
            customInput={TextField}
            className={classes.input}
          />
        ),
      },
    },
    {
      name: "range.days_a_year",
      label: t("Nombre de jours"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <NumberFormat
            disabled
            value={value}
            thousandSeparator={" "}
            name="range.days_a_year"
            customInput={TextField}
            className={classes.input}
          />
        ),
      },
    },
    {
      name: "range.sales_revenue_HT",
      label: t("CA HT €"),

      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <NumberFormat
            disabled
            value={value}
            thousandSeparator={" "}
            name="range.sales_revenue_HT"
            customInput={TextField}
            className={classes.input}
            suffix="€"
          />
        ),
      },
    },
    {
      name: "range.material_consumption_HT",
      label: t("Consommation de matière (€)"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <NumberFormat
            disabled
            value={value}
            thousandSeparator={" "}
            name="range.material_consumption_HT"
            customInput={TextField}
            className={classes.input}
            suffix="€"
          />
        ),
      },
    },
    {
      name: "range.gross_margin",
      label: t("Marge Brute totale (€)"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <NumberFormat
            disabled
            value={value}
            thousandSeparator={" "}
            name="range.gross_margin"
            customInput={TextField}
            className={classes.input}
            suffix="€"
          />
        ),
      },
    },
    {
      name: "range.range_multiplier_coefficient",
      label: t("Coeffcient multiplicateur (Coeff)"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "range.average_material_ratio",
      label: t("Ratio matière (%)"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <NumberFormat
            disabled
            value={value}
            thousandSeparator={" "}
            name="range.average_material_ratio"
            customInput={TextField}
            className={classes.input}
            suffix="%"
          />
        ),
      },
    },
  ];

  const deleteRows = async (RowsDeleted) => {
    const ids = RowsDeleted.data.map((d) => d.dataIndex);
    const idsToDelete = ids.map((index) => listOfferRanges[index]);
    console.log(idsToDelete);
    const listOfferRangeUuid = [];
    idsToDelete.map(async (rangeToDelete) => {
      console.log("rangeToDelete", rangeToDelete);
      listOfferRanges.splice(listOfferRanges.indexOf(rangeToDelete), 1);
      setListOfferRanges(listOfferRanges.concat([]));
      listOfferRangeUuid.push(rangeToDelete.uuid);
    });
    console.log("listOfferRangeUuid", listOfferRangeUuid);
    dispatch(deleteOfferRanges(listOfferRangeUuid, user.accessToken));
  };

  const addLine = () => {
    setListOfferRanges(
      listOfferRanges.concat({
        offer: {},
        range: {
          id: 0,
          uuid: null,
          name: null,
          period: null,
          number_custom_by_year_number_check_total: null,
          number_day_by_year: null,
          sales_revenue_HT: null,
          material_consumption_HT: null,
          gross_margin: null,
          range_multiplier_coefficient: null,
          average_material_ratio: null,
        },
        project: {},
      })
    );
  };
  const options = {
    sort: false,
    filter: false,
    filterType: "checkbox",
    responsive: "simple",
    enableNestedDataAccess: ".",
    onRowsDelete: deleteRows,
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => (
      <OfferFooterAddLine
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        // eslint-disable-next-line no-shadow
        onChangePage={(_, page) => changePage(page)}
        onChangeRowsPerPage={(event) => changeRowsPerPage(event.target.value)}
        rowsPerPageOptions={[10, 15, 100]}
        addLine={addLine}
        title={t("ajouter une gamme d'offre")}
      />
    ),
  };

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {!displayOfferSynthesis && (
            <Typography variant="h6" gutterBottom>
              {t("Synthèse de l'offre")}
              <IconButton onClick={() => setDisplayOfferSynthesis(true)}>
                <VisibilityIcon />
              </IconButton>
            </Typography>
          )}
          {displayOfferSynthesis && (
            <>
              <Typography variant="h6" gutterBottom>
                {t("Synthèse de l'offre")}
                <IconButton onClick={() => setDisplayOfferSynthesis(false)}>
                  <VisibilityOffIcon />
                </IconButton>
              </Typography>
              <Table>
                <MuiTableBody>
                  <TableRow>
                    <TableCell>{t("Nombre de clients total")}</TableCell>
                    <TableCell>
                      <NumberFormat
                        disabled
                        value={offerSynthesis.total_customer_number}
                        thousandSeparator={" "}
                        name="offer.total_customer_number"
                        customInput={TextField}
                        className={classes.input}
                        suffix=""
                      />
                    </TableCell>
                    <TableCell>{t("CA HT Total")}</TableCell>
                    <TableCell>
                      <NumberFormat
                        disabled
                        value={offerSynthesis.total_sales_revenue_HT}
                        thousandSeparator={" "}
                        name="total_sales_revenue_HT"
                        customInput={TextField}
                        className={classes.input}
                        suffix="€"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("Nombre de jour(s) au total")}</TableCell>
                    <TableCell>
                      <NumberFormat
                        disabled
                        value={offerSynthesis.total_number_day}
                        thousandSeparator={" "}
                        name="offer.total_number_day"
                        customInput={TextField}
                        className={classes.input}
                        suffix=""
                      />
                    </TableCell>
                    <TableCell>{t("Consommation de matière totale")}</TableCell>
                    <TableCell>
                      <NumberFormat
                        disabled
                        value={offerSynthesis.total_material_consumption}
                        thousandSeparator={" "}
                        name="offer.total_material_consumption"
                        customInput={TextField}
                        className={classes.input}
                        suffix="€"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("Somme des marges brutes")}</TableCell>
                    <TableCell>
                      <NumberFormat
                        disabled
                        value={offerSynthesis.sum_gross_margin}
                        thousandSeparator={" "}
                        name="offer.sum_gross_margin"
                        customInput={TextField}
                        className={classes.input}
                        suffix=""
                      />
                    </TableCell>
                    <TableCell>
                      {t("Coefficient multiplicateur moyen")}
                    </TableCell>
                    <TableCell>
                      <NumberFormat
                        disabled
                        value={
                          offerSynthesis.average_range_multiplier_coefficient
                        }
                        thousandSeparator={" "}
                        name="offer.average_range_multiplier_coefficient"
                        customInput={TextField}
                        className={classes.input}
                        suffix=""
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("Ratio matière moyen")}</TableCell>
                    <TableCell>
                      <NumberFormat
                        disabled
                        value={offerSynthesis.average_material_ratio}
                        thousandSeparator={" "}
                        name="offer.average_material_ratio"
                        customInput={TextField}
                        className={classes.input}
                        suffix="%"
                      />
                    </TableCell>
                  </TableRow>
                </MuiTableBody>
              </Table>
            </>
          )}
        </Grid>

        <Grid item xs={12}>
          <MuiThemeProvider theme={getTableTheme()}>
            <MUIDataTable
              className={classes.HiddenIdShowColumn}
              data={listOfferRanges}
              columns={columns}
              options={options}
            />
          </MuiThemeProvider>
        </Grid>
      </Grid>
    </>
  );
};

rangeOfferDetails.propTypes = {
  offerRanges: PropTypes.objectOf(OfferRangeModel).isRequired,
  offer: PropTypes.objectOf(OfferModel).isRequired,
};

export default rangeOfferDetails;
