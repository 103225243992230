import React, { useState } from "react";
import {
  makeStyles,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Tooltip,
  TextField,
  Button,
} from "@material-ui/core";
import UpdateIcon from "@material-ui/icons/Update";
import Edit from "@material-ui/icons/Edit";
import { useTranslation, Trans } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { updateProjectComments } from "../../../redux/actions/projectCommentsActions";

const useStyles = makeStyles(() => ({
  center: {
    textAlign: "center",
    color: "#188038",
  },
  textComponent: {
    padding: "0px!important",
  },
  myMessage: {
    background: "#178038",
    color: "white",
    padding: "8px",
    width: "100%",
    "& button": {
      color: "white",
    },
    "& svg": {
      color: "white",
    },
    "& .MuiTextField-root": {
      background: "white",
    },
  },
  iconEdit: {
    fontSize: "10px",
    color: "#1876d1bd",
  },
  iconUpdate: {
    fontSize: "11px",
  },
  buttonUpdate: {
    width: "100%",
    height: "50%",
  },
  theirMessages: {
    background: "#d5fff1",
    padding: "8px",
    width: "100%",
  },
  nameMessage: {
    fontSize: "9px",
  },
  dateTimeMessage: {
    fontSize: "9px",
  },
  floatRight: {
    float: "right",
  },
  text: {
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
  },
}));

function messageComponent({ isMyMessage, comment }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const date = new Date(comment.created_on).toLocaleString("fr");
  const updatedOn = new Date(comment.updated_on).toLocaleString("fr");

  const [isEditing, setIsEditing] = useState(false);
  const [myMessageEdited, setMyMessageEdited] = useState(comment.comment);
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.firebase.auth);
  const currentProject = useSelector((state) => state.projects.currentProject);

  const user = auth.stsTokenManager;
  const updateMessage = async () => {
    await dispatch(
      updateProjectComments(
        user,
        {
          comment: myMessageEdited,
          project: currentProject.id,
        },
        comment.id
      )
    );
    setIsEditing(false);
  };

  return (
    <Card
      mb={12}
      className={[isMyMessage ? classes.myMessage : classes.theirMessages]}
    >
      <CardContent className={classes.textComponent}>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="p" className={classes.nameMessage}>
              {comment.user.email}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="p" className={classes.dateTimeMessage}>
              {date !== updatedOn && (
                <Tooltip disableFocusListener title={`créé le ${date}`}>
                  <UpdateIcon className={classes.iconUpdate} />
                </Tooltip>
              )}
              {updatedOn}
            </Typography>
          </Grid>
          {isMyMessage && (
            <Grid item xs={1}>
              <Tooltip disableFocusListener title={t("Modifier mon message")}>
                <IconButton
                  className={classes.buttonUpdate}
                  color="primary"
                  aria-label={t("Modifier mon message")}
                  onClick={() => setIsEditing(true)}
                >
                  <Edit className={classes.iconEdit} />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
          <Grid item xs={12}>
            {isEditing ? (
              <>
                <TextField
                  multiline
                  fullWidth
                  rows={3}
                  rowsMax={6}
                  variant="outlined"
                  onChange={(e) => setMyMessageEdited(e.target.value)}
                  value={myMessageEdited}
                />
                <Button
                  className={classes.floatRight}
                  size="small"
                  color="primary"
                  type="button"
                  onClick={() => updateMessage()}
                >
                  <Trans i18nKey="Enregistrer">Enregistrer</Trans>
                </Button>
              </>
            ) : (
              <Typography
                noWrap={false}
                className={classes.text}
                variant="p"
                id="tableTitle"
              >
                {comment.comment}
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default messageComponent;
