import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import {
  Divider,
  Typography,
  Grid,
  Button,
  Card as MuiCard,
  CardContent,
  makeStyles,
  TextField as MuiTextField,
} from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { spacing } from "@material-ui/system";
import { useForm } from "react-hook-form";
import { addOfferInteraction } from "../../services/offer/OfferInteractionService";

const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
  margin: {
    marginTop: "24px",
    marginBottom: "24px",
  },
}));

const offerCreate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const currentProject = useSelector((state) => state.projects.currentProject);
  const { handleSubmit, register, errors } = useForm();

  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;
  const submitData = async (data) => {
    const res = await addOfferInteraction(
      currentProject.id,
      user.accessToken,
      { name: data.name },
      dispatch
    );
    history.push(`/offer/${res.uuid}`);
  };

  return (
    <>
      <Helmet>
        <title>{t("Ajouter une Offre")}</title>
      </Helmet>

      <Typography variant="h3" gutterBottom display="inline">
        <Trans i18nKey="Créer une offre">Créer une offre</Trans>
      </Typography>

      <Divider my={6} mr={24} className={classes.margin} />
      <form onSubmit={handleSubmit(submitData)}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card mb={6}>
              <CardContent>
                <Grid className={classes.root} container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="name"
                      label={
                        <Trans i18nKey="Nom de l'offre">
                          Nom de l&apos;offre *
                        </Trans>
                      }
                      name="name"
                      autoFocus
                      inputRef={register({
                        required: "Required",
                      })}
                      error={"name" in errors}
                      helperText={
                        "name" in errors ? (
                          <Trans i18nKey="Veuillez entrer le nom de la gamme">
                            Veuillez entrer le nom de la gamme
                          </Trans>
                        ) : (
                          ""
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={12} m={5}>
          <Button type="submit" fullWidth variant="contained" color="primary">
            <Trans i18nKey="Créer">Créer</Trans>
          </Button>
        </Grid>
      </form>
    </>
  );
};

export default offerCreate;
