import React from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import {
  Card as MuiCard,
  CardContent,
  Typography,
  makeStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { useForm } from "react-hook-form";

import { spacing } from "@material-ui/system";

import { useDispatch, useSelector } from "react-redux";
import { updateUserAction } from "../../redux/actions/userActions";
import { editingProjectIntermediateManagementThreshold } from "../../redux/actions/sigActions";
import FieldWithSuffix from "../ElementsField/FieldWithSuffix";
import { interactionGeneraInformation } from "../../services/sig/SigMethodService";

const Card = styled(MuiCard)(spacing);

const useStyles = makeStyles({
  margin: {
    marginTop: "12px",
  },
  input: {
    width: "90px!important",
  },
  gridInput: {
    borderLeft: "solid 1px",
    paddingLeft: "30px",
  },
  iconEdit: {
    fontSize: "1em",
    color: "#1876d1bd",
  },
  card: {
    paddingBottom: "0px!important",
  },
  tableEqualWidth: {
    "& th": { width: "20%!important" },
  },
  noPaddingTh: {
    "& th": {
      padding: "0px",
    },
  },
  paddingThTd: {
    "& th": {
      paddingTop: "5px!important",
      paddingBottom: "5px!important",
    },
    "& td": {
      paddingTop: "5px!important",
      paddingBottom: "5px!important",
    },
  },
});
const GeneralInformation = () => {
  const { register, control, errors } = useForm();
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleUserUpdate = (ProjectIntermediateManagementThreshold) => {
    dispatch(
      editingProjectIntermediateManagementThreshold(
        interactionGeneraInformation(ProjectIntermediateManagementThreshold)
      )
    );
    dispatch(updateUserAction(true));
  };
  const sig = useSelector((state) => state.sig.sig);

  return (
    <Card mb={12}>
      <CardContent className={classes.card}>
        <TableContainer component={Paper}>
          <Table
            className={[classes.table, classes.tableEqualWidth]}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow className={[classes.noPaddingTh]}>
                <TableCell>
                  <Typography variant="h5" gutterBottom display="inline">
                    <Trans i18nKey="Informations générales">
                      Informations générales
                    </Trans>
                  </Typography>
                </TableCell>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody className={classes.paddingThTd}>
              <TableRow key="Nombre de jours d'ouverture par an">
                <TableCell component="th" scope="row">
                  <Typography variant="h9" gutterBottom display="inline">
                    <Trans i18nKey="Nombre de jours d'ouverture par an">
                      Nombre de jours d&apos;ouverture par an
                    </Trans>
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <FieldWithSuffix
                    isEdit
                    disabled={false}
                    name="number_days_open_per_year"
                    control={control}
                    errors={errors}
                    onChangeField={
                      (e) =>
                        handleUserUpdate({
                          ...sig,
                          number_days_open_per_year: e.target.value,
                        })
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    suffix={t("")}
                    value={parseInt(sig.number_days_open_per_year, 10)}
                    register={register}
                  />
                </TableCell>
                <TableCell align="right" />
                <TableCell align="right" />
                <TableCell align="right" />
              </TableRow>
              <TableRow key="Nombre de semaines d'ouverture par an">
                <TableCell component="th" scope="row">
                  <Typography variant="h9" gutterBottom display="inline">
                    <Trans i18nKey="Nombre de semaines d'ouverture par an">
                      Nombre de semaines d&apos;ouverture par an
                    </Trans>
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <FieldWithSuffix
                    isEdit
                    disabled={false}
                    name="number_weeks_open_per_year"
                    control={control}
                    errors={errors}
                    onChangeField={
                      (e) =>
                        handleUserUpdate({
                          ...sig,
                          number_weeks_open_per_year: e.target.value,
                        })
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    suffix={t("")}
                    value={parseInt(sig.number_weeks_open_per_year, 10)}
                    register={register}
                  />
                </TableCell>
                <TableCell align="right" />
                <TableCell align="right" />
                <TableCell align="right" />
              </TableRow>
              <TableRow key="Capacité d'accueil par jour">
                <TableCell component="th" scope="row">
                  <Typography variant="h9" gutterBottom display="inline">
                    <Trans i18nKey="Capacité d'accueil par jour">
                      Capacité d&apos;accueil par jour
                    </Trans>
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <FieldWithSuffix
                    isEdit
                    disabled={false}
                    name="capacity_per_day"
                    control={control}
                    errors={errors}
                    onChangeField={
                      (e) =>
                        handleUserUpdate({
                          ...sig,
                          capacity_per_day: e.target.value,
                        })
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    suffix={t("")}
                    value={parseInt(sig.capacity_per_day, 10)}
                    register={register}
                  />
                </TableCell>
                <TableCell align="right" />
                <TableCell align="right" />
                <TableCell align="right" />
              </TableRow>
              <TableRow key="Prix moyen HT">
                <TableCell component="th" scope="row">
                  <Typography variant="h9" gutterBottom display="inline">
                    <Trans i18nKey="Prix moyen HT">Prix moyen HT</Trans>
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <FieldWithSuffix
                    isEdit
                    disabled={false}
                    name="average_price_excl_tax"
                    control={control}
                    errors={errors}
                    onChangeField={
                      (e) =>
                        handleUserUpdate({
                          ...sig,
                          average_price_excl_tax: e.target.value.slice(
                            0,
                            e.target.value.length - 1
                          ),
                        })
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    suffix={t("€")}
                    value={sig.average_price_excl_tax}
                    register={register}
                  />
                </TableCell>
                <TableCell align="right" />
                <TableCell align="right" />
                <TableCell align="right" />
              </TableRow>
              <TableRow key="Nombre de clients par jour">
                <TableCell component="th" scope="row">
                  <Typography variant="h9" gutterBottom display="inline">
                    <Trans i18nKey="Nombre de clients par jour">
                      Nombre de clients par jour
                    </Trans>
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <FieldWithSuffix
                    disabled
                    name="number_customers_per_day"
                    control={control}
                    errors={errors}
                    suffix={t("")}
                    value={parseInt(sig.number_customers_per_day, 10)}
                    register={register}
                  />
                </TableCell>
                <TableCell align="right" />
                <TableCell align="right" />
                <TableCell align="right" />
              </TableRow>
              <TableRow key="Nombre de clients par an">
                <TableCell component="th" scope="row">
                  <Typography variant="h9" gutterBottom display="inline">
                    <Trans i18nKey="Nombre de clients par an">
                      Nombre de clients par an
                    </Trans>
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <FieldWithSuffix
                    isEdit
                    disabled={false}
                    name="number_customers_per_year"
                    control={control}
                    errors={errors}
                    onChangeField={
                      (e) =>
                        handleUserUpdate({
                          ...sig,
                          number_customers_per_year: e.target.value,
                        })
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    suffix={t("")}
                    value={parseInt(sig.number_customers_per_year, 10)}
                    register={register}
                  />
                </TableCell>
                <TableCell align="right" />
                <TableCell align="right" />
                <TableCell align="right" />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};
export default GeneralInformation;
