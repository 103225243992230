import {
  FETCH_RANGE_PRODUCTS,
  DELETE_RANGE_PRODUCT_SUCCESS,
  ADD_PRODUCT_TO_RANGE_SUCCESS,
  FETCH_RANGES_OF_A_PRODUCT_SUCCESS,
  UPDATE_RANGE_PRODUCT_SUCCESS,
  UPDATE_RANGE_PRODUCTS_SUCCESS,
  SWITCH_POPSER,
  CLEAN_RANGE_PRODUCTS,
  EDIT_RANGE_PRODUCT,
  FETCH_RANGE_PRODUCTS_STARTED,
  EDIT_RANGE_PRODUCTS,
} from "../types/rangeProductsActionTypes";

const initialState = {
  rangeProducts: [],
  loading: true,
  popser: { isPopser: true, name: "Classement du Popser" },
  add_button_disabled: false,
};

const replaceRangeProduct = (updatedRangeProduct, state) => {
  console.log("ICI");
  console.log("updated project", updatedRangeProduct);
  console.log("uuid of updated project", updatedRangeProduct.uuid);
  const indexOfUpdatedRangeProduct = state.rangeProducts
    .map((e) => {
      return e.uuid;
    })
    .indexOf(updatedRangeProduct.uuid);
  console.log("index of", indexOfUpdatedRangeProduct);
  const newRangeProductList = [...state.rangeProducts];
  console.log("newRangeProductList before", newRangeProductList);
  newRangeProductList[indexOfUpdatedRangeProduct] = updatedRangeProduct;
  console.log("newRangeProductList after", newRangeProductList);

  return newRangeProductList;
};

const rangeProductsReducer = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_RANGE_PRODUCT:
      return {
        ...state,
        rangeProducts: replaceRangeProduct(action.payload, state),
      };
    case EDIT_RANGE_PRODUCTS:
      return {
        ...state,
        rangeProducts: action.payload,
      };
    case FETCH_RANGE_PRODUCTS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case FETCH_RANGE_PRODUCTS:
      return {
        ...state,
        rangeProducts: action.payload,
        loading: false,
      };
    case FETCH_RANGES_OF_A_PRODUCT_SUCCESS:
      return {
        ...state,
        rangeProducts: action.payload,
        loading: false,
      };
    case DELETE_RANGE_PRODUCT_SUCCESS:
      return {
        ...state,
        rangeProducts: state.rangeProducts.filter(
          (rangeProduct) => rangeProduct.uuid !== action.payload
        ),
        loading: false,
      };
    case ADD_PRODUCT_TO_RANGE_SUCCESS:
      return {
        ...state,
        rangeProducts: [...state.rangeProducts, action.payload],
        loading: false,
      };
    case UPDATE_RANGE_PRODUCT_SUCCESS:
      return {
        ...state,
        rangeProducts: replaceRangeProduct(action.payload, state),
      };
    case UPDATE_RANGE_PRODUCTS_SUCCESS:
      return {
        ...state,
        rangeProducts: action.payload,
      };
    case SWITCH_POPSER:
      return {
        ...state,
        popser: action.payload,
      };
    case CLEAN_RANGE_PRODUCTS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default rangeProductsReducer;
