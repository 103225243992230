import { LOADING_ACTION } from "../types/settingsActionTypes";

const initialState = {
  is_loading: false,
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_ACTION:
      return {
        ...state,
        is_loading: action.payload,
      };
    default:
      return state;
  }
};

export default settingsReducer;
