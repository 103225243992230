import { UPDATE_COMPUTED_CSER } from "../../redux/types/cserActionTypes";
import {
  getCashFlowForAdjustedImt,
  getCashFlowForPercentageAdjustedImt,
  getCorporateTaxesForAdjustedImt,
  getCurrentIncomeBeforeTaxesForAdjustedImt,
  getCurrentIncomeBeforeTaxesPercentageForAdjustedImt,
  getFixedCostsForAdjustedImt,
  getGrossMarginInEurosExcludingConsumedProductionForAdjustedImt,
  getIndicatorsAveragePriceInEuros,
  getIndicatorsCoefficientForAdjustedImt,
  getIndicatorsFillRateForAdjustedImt,
  getIndicatorsGrossMarginInEuros,
  getIndicatorsNumberOfCustomersByWeek,
  getIndicatorsRatioOfMaterials,
  getIndicatorsTurnoverDayForAdjustedImt,
  getLoanRepaymentPercentageForAdjustedImt,
  getMarginOnVariableCostsForAdjustedImt,
  getMarginOnVariableCostsPercentageForAdjustedImt,
  getNetProfitAfterTaxForAdjustedImt,
  getNetProfitAfterTaxPercentageForAdjustedImt,
  getNetSurplusForAdjustedImt,
  getSimulationTurnoverBeforeTaxProfitabilityThreshold,
  getSimulationVariableCostsProfitabilityThreshold,
  getTurnoverBeforeTaxPercentageForAdjustedImt,
  getVariableCostsForAdjustedImt,
  getVariableCostsPercentageForAdjustedImt,
  getTurnoverBeforeTaxSerGrowthTurnover,
  getSerGrowthTurnoverSimulationMarginOnVariableCosts,
  getTurnoverBeforeTaxPercentageSerGrowthTurnover,
  getCashflowForProfitabilityThreshold,
  getSerGrowthTurnoverSimulationVariableCosts,
  getIndicatorsNumberOfCustomersByDayForProfitabilityThreshold,
  getIndicatorsNumberOfCustomersByWeekForProfitabilityThreshold,
  getSimulationCashfFlowForSerGrowthTurnover,
  getSimulationCurrentIncomeBeforeTaxesForSerGrowthTurnover,
  getSimulationNetProfitAfterTaxForSerGrowthTurnover,
  getAveragePriceForSerGrowthTurnoverWithAveragePriceIncrease,
  getNumberOfCustomerByDayForSerGrowthTurnoverAvPriceStable,
  getTurnoverBeforeTaxPercentageForSerForecast,
  getNewVariableCostsRateFromPercentageReductionMaterialConsumption,
  getVariableCostsForSerForecast,
  getMarginOnVariableCostsPercentageForSerForecast,
  getSimulationMarginOnVariableCostsForSerForecast,
  getIndicatorsCoefficientForSerForecast,
  getCserK,
  getVariableCostsForSerCoeff,
} from "./cserService";
import { getParsedFloatNumber, toFixedNumber } from "../range/RangeService";
import { store } from "../../redux/store";
import { getTotalProduct } from "../sig/SigCalculService";

const computeCserData = async () => {
  const state = store.getState();
  const { sig } = state.sig;
  const { workforce_costs, external_costs } = state.sig;
  const { cser_data } = state.cser;
  const adjusted_imt_simulation_turnover_before_tax = sig.net_turnover_excl_tax;
  const { computed_cser } = state.cser;
  const adjusted_imt_simulation_turnover_before_tax_percentage = getTurnoverBeforeTaxPercentageForAdjustedImt(
    adjusted_imt_simulation_turnover_before_tax
  );
  const adjusted_imt_simulation_variable_costs = getVariableCostsForAdjustedImt(
    sig,
    workforce_costs,
    external_costs
  );
  const adjusted_imt_simulation_variable_costs_percentage = getVariableCostsPercentageForAdjustedImt(
    adjusted_imt_simulation_variable_costs,
    adjusted_imt_simulation_turnover_before_tax
  );
  const adjusted_imt_simulation_margin_on_variable_costs = getMarginOnVariableCostsForAdjustedImt(
    adjusted_imt_simulation_turnover_before_tax,
    adjusted_imt_simulation_variable_costs
  );
  const adjusted_imt_simulation_margin_on_variable_costs_percentage = getMarginOnVariableCostsPercentageForAdjustedImt(
    adjusted_imt_simulation_variable_costs_percentage,
    adjusted_imt_simulation_turnover_before_tax_percentage
  );
  const adjusted_imt_simulation_fixed_costs = getFixedCostsForAdjustedImt(
    sig,
    workforce_costs,
    external_costs
  );
  const adjusted_imt_simulation_current_income_before_taxes = getCurrentIncomeBeforeTaxesForAdjustedImt(
    adjusted_imt_simulation_margin_on_variable_costs,
    adjusted_imt_simulation_fixed_costs
  );
  const adjusted_imt_simulation_current_income_before_taxes_percentage = getCurrentIncomeBeforeTaxesPercentageForAdjustedImt(
    adjusted_imt_simulation_current_income_before_taxes,
    adjusted_imt_simulation_turnover_before_tax_percentage,
    adjusted_imt_simulation_turnover_before_tax
  );
  const adjusted_imt_simulation_corporate_taxes = getCorporateTaxesForAdjustedImt(
    sig.tax_rate_lower_tier,
    sig.tax_rate_upper_tier,
    sig.tax_lower_tier,
    adjusted_imt_simulation_current_income_before_taxes
  );
  const adjusted_imt_simulation_net_profit_after_tax = getNetProfitAfterTaxForAdjustedImt(
    adjusted_imt_simulation_current_income_before_taxes,
    adjusted_imt_simulation_corporate_taxes
  );
  const adjusted_imt_simulation_net_profit_after_tax_percentage = getNetProfitAfterTaxPercentageForAdjustedImt(
    adjusted_imt_simulation_net_profit_after_tax,
    adjusted_imt_simulation_turnover_before_tax_percentage,
    adjusted_imt_simulation_turnover_before_tax
  );
  const adjusted_imt_simulation_cash_flow = getCashFlowForAdjustedImt(
    adjusted_imt_simulation_net_profit_after_tax,
    sig.depreciation_amortization
  );
  const adjusted_imt_simulation_cash_flow_percentage = getCashFlowForPercentageAdjustedImt(
    adjusted_imt_simulation_cash_flow,
    adjusted_imt_simulation_turnover_before_tax_percentage,
    adjusted_imt_simulation_turnover_before_tax
  );
  const adjusted_imt_simulation_loan_repayments =
    cser_data.simulation_adjusted_imt_loan_repayments;
  const adjusted_imt_simulation_loan_repayments_percentage = getLoanRepaymentPercentageForAdjustedImt(
    adjusted_imt_simulation_loan_repayments,
    adjusted_imt_simulation_turnover_before_tax_percentage,
    adjusted_imt_simulation_turnover_before_tax
  );
  const adjusted_imt_simulation_net_surplus = getNetSurplusForAdjustedImt(
    adjusted_imt_simulation_cash_flow,
    cser_data.simulation_adjusted_imt_loan_repayments
  );
  const adjusted_imt_simulation_net_surplus_percentage = getLoanRepaymentPercentageForAdjustedImt(
    adjusted_imt_simulation_net_surplus,
    adjusted_imt_simulation_turnover_before_tax_percentage,
    adjusted_imt_simulation_turnover_before_tax
  );
  const adjusted_imt_indicators_number_of_customers_by_day = toFixedNumber(
    sig.number_customers_per_day,
    0
  );
  const adjusted_imt_indicators_fill_rate = getIndicatorsFillRateForAdjustedImt(
    adjusted_imt_indicators_number_of_customers_by_day,
    sig.capacity_per_day
  );
  const adjusted_imt_indicators_turnover_day = getIndicatorsTurnoverDayForAdjustedImt(
    adjusted_imt_simulation_turnover_before_tax,
    sig.number_days_open_per_year
  );
  const adjusted_imt_indicators_average_price_in_euros = getIndicatorsAveragePriceInEuros(
    adjusted_imt_indicators_turnover_day,
    adjusted_imt_indicators_number_of_customers_by_day
  );
  const adjusted_imt_indicators_number_of_customers_by_week = getIndicatorsNumberOfCustomersByWeek(
    getTotalProduct(sig),
    sig.average_price_excl_tax,
    sig.number_days_open_per_year
  );
  const adjusted_imt_indicators_coefficients = getIndicatorsCoefficientForAdjustedImt(
    sig,
    adjusted_imt_simulation_turnover_before_tax
  );
  const getTurnoverByWeek = (turnoverBeforeTax, number_weeks_opened) => {
    let weeks = getParsedFloatNumber(number_weeks_opened, 2);
    const turnover = getParsedFloatNumber(turnoverBeforeTax, 2);
    if (number_weeks_opened === 0 || number_weeks_opened === null) {
      weeks = 52;
    }
    return toFixedNumber(getParsedFloatNumber(turnover / weeks), 2);
  };
  const adjusted_imt_indicators_turnover_week = getTurnoverByWeek(
    adjusted_imt_simulation_turnover_before_tax,
    sig.number_weeks_open_per_year
  );
  // adjusted_imt_indicators_turnover_day;
  const adjusted_imt_indicators_ratio_of_materials = getIndicatorsRatioOfMaterials(
    adjusted_imt_indicators_coefficients
  );

  const adjusted_imt_indicators_gross_margin_in_euros_excluding_consumed_production = getGrossMarginInEurosExcludingConsumedProductionForAdjustedImt(
    adjusted_imt_simulation_turnover_before_tax,
    adjusted_imt_indicators_coefficients
  );
  const adjusted_imt_indicators_gross_margin_in_euros = getIndicatorsGrossMarginInEuros(
    adjusted_imt_indicators_gross_margin_in_euros_excluding_consumed_production,
    sig.number_days_open_per_year
  );

  const profitability_threshold_simulation_variable_costs = getSimulationVariableCostsProfitabilityThreshold(
    adjusted_imt_simulation_variable_costs_percentage,
    adjusted_imt_simulation_fixed_costs,
    adjusted_imt_simulation_margin_on_variable_costs_percentage
  );

  const profitability_threshold_simulation_turnover_before_tax = getSimulationTurnoverBeforeTaxProfitabilityThreshold(
    adjusted_imt_simulation_fixed_costs,
    adjusted_imt_simulation_margin_on_variable_costs_percentage
  );

  const profitability_threshold_simulation_cash_flow = getCashflowForProfitabilityThreshold(
    sig.depreciation_amortization
  );
  const profitability_threshold_simulation_loan_repayments =
    cser_data.simulation_profitability_threshold_loan_repayments;

  const profitability_threshold_net_surplus = getNetSurplusForAdjustedImt(
    profitability_threshold_simulation_cash_flow,
    profitability_threshold_simulation_loan_repayments
  );

  const profitability_threshold_indicators_turnover_day = getIndicatorsTurnoverDayForAdjustedImt(
    profitability_threshold_simulation_turnover_before_tax,
    sig.number_days_open_per_year
  );

  const profitability_threshold_indicators_number_of_customers_by_day = getIndicatorsNumberOfCustomersByDayForProfitabilityThreshold(
    profitability_threshold_indicators_turnover_day,
    adjusted_imt_indicators_average_price_in_euros
  );

  const profitability_threshold_indicators_fill_rate = getIndicatorsFillRateForAdjustedImt(
    profitability_threshold_indicators_number_of_customers_by_day,
    sig.capacity_per_day
  );

  const profitability_threshold_indicators_number_of_customers_by_week = getIndicatorsNumberOfCustomersByWeekForProfitabilityThreshold(
    profitability_threshold_indicators_number_of_customers_by_day,
    sig.number_days_open_per_year
  );

  const profitability_threshold_indicators_turnover_week = getTurnoverByWeek(
    profitability_threshold_simulation_turnover_before_tax,
    sig.number_weeks_open_per_year
  );
  const profitability_threshold_indicators_gross_margin_in_euros_excluding_consumed_production = getGrossMarginInEurosExcludingConsumedProductionForAdjustedImt(
    profitability_threshold_simulation_turnover_before_tax,
    adjusted_imt_indicators_coefficients
  );
  const profitability_threshold_indicators_gross_margin_in_euros = getIndicatorsGrossMarginInEuros(
    profitability_threshold_indicators_gross_margin_in_euros_excluding_consumed_production,
    sig.number_days_open_per_year
  );

  const ser_growth_turnover_simulation_fixed_costs = adjusted_imt_simulation_fixed_costs;

  const ser_growth_turnover_simulation_loan_repayments =
    cser_data.simulation_ser_growth_turnover_loan_repayments;
  const ser_growth_turnover_simulation_net_surplus =
    cser_data.simulation_ser_growth_turnover_net_surplus;
  const ser_growth_turnover_simulation_cash_flow = getSimulationCashfFlowForSerGrowthTurnover(
    ser_growth_turnover_simulation_loan_repayments,
    ser_growth_turnover_simulation_net_surplus
  );
  const ser_growth_turnover_simulation_current_income_before_taxes = getSimulationCurrentIncomeBeforeTaxesForSerGrowthTurnover(
    sig.depreciation_amortization,
    ser_growth_turnover_simulation_cash_flow,
    sig.tax_lower_tier,
    sig.tax_rate_lower_tier,
    sig.tax_rate_upper_tier
  );
  const ser_growth_turnover_simulation_net_profit_after_tax = getSimulationNetProfitAfterTaxForSerGrowthTurnover(
    sig.depreciation_amortization,
    ser_growth_turnover_simulation_current_income_before_taxes,
    ser_growth_turnover_simulation_cash_flow
  );

  const ser_growth_turnover_simulation_corporate_taxes = getCorporateTaxesForAdjustedImt(
    sig.tax_rate_lower_tier,
    sig.tax_rate_upper_tier,
    sig.tax_lower_tier,
    ser_growth_turnover_simulation_current_income_before_taxes
  );

  const ser_growth_turnover_simulation_margin_on_variable_costs = getSerGrowthTurnoverSimulationMarginOnVariableCosts(
    ser_growth_turnover_simulation_fixed_costs,
    ser_growth_turnover_simulation_current_income_before_taxes
  );

  const ser_growth_turnover_simulation_variable_costs = getSerGrowthTurnoverSimulationVariableCosts(
    adjusted_imt_simulation_variable_costs_percentage,
    ser_growth_turnover_simulation_margin_on_variable_costs,
    adjusted_imt_simulation_margin_on_variable_costs_percentage
  );

  const ser_growth_turnover_simulation_turnover_before_tax = getTurnoverBeforeTaxSerGrowthTurnover(
    ser_growth_turnover_simulation_margin_on_variable_costs,
    adjusted_imt_simulation_margin_on_variable_costs_percentage
  );

  const ser_growth_turnover_simulation_turnover_before_tax_percentage = getTurnoverBeforeTaxPercentageSerGrowthTurnover(
    ser_growth_turnover_simulation_turnover_before_tax,
    adjusted_imt_simulation_turnover_before_tax
  );

  const ser_growth_turnover_indicators_turnover_day = getIndicatorsTurnoverDayForAdjustedImt(
    ser_growth_turnover_simulation_turnover_before_tax,
    sig.number_days_open_per_year
  );

  const ser_growth_turnover_indicators_average_price_stable_average_price_in_euros = adjusted_imt_indicators_average_price_in_euros;

  const ser_growth_turnover_indicators_average_price_stable_number_of_customers_by_day = getNumberOfCustomerByDayForSerGrowthTurnoverAvPriceStable(
    ser_growth_turnover_indicators_turnover_day,
    ser_growth_turnover_indicators_average_price_stable_average_price_in_euros
  );

  const ser_growth_turnover_indicators_average_price_stable_fill_rate = getIndicatorsFillRateForAdjustedImt(
    ser_growth_turnover_indicators_average_price_stable_number_of_customers_by_day,
    sig.capacity_per_day
  );

  const ser_growth_turnover_indicators_coefficients = adjusted_imt_indicators_coefficients;
  const ser_growth_turnover_indicators_ratio_of_materials = adjusted_imt_indicators_ratio_of_materials;
  const ser_growth_turnover_indicators_gross_margin_in_euros_excluding_consumed_production = getGrossMarginInEurosExcludingConsumedProductionForAdjustedImt(
    ser_growth_turnover_simulation_turnover_before_tax,
    ser_growth_turnover_indicators_coefficients
  );
  const ser_growth_turnover_average_price_stable_indicators_gross_margin_in_euros = getIndicatorsGrossMarginInEuros(
    ser_growth_turnover_indicators_gross_margin_in_euros_excluding_consumed_production,
    sig.number_days_open_per_year
  );

  // F20
  const ser_growth_turnover_occupancy_rate_stable_indicators_number_of_customers_by_day = adjusted_imt_indicators_number_of_customers_by_day;
  // F21
  const ser_growth_turnover_occupancy_rate_stable_indicators_fill_rate = adjusted_imt_indicators_fill_rate;
  // F22
  const ser_growth_turnover_occupancy_rate_stable_indicators_average_price_in_euros = getIndicatorsAveragePriceInEuros(
    ser_growth_turnover_indicators_turnover_day,
    ser_growth_turnover_occupancy_rate_stable_indicators_number_of_customers_by_day
  );
  // F26 // COUCOU
  const ser_growth_turnover_occupancy_rate_stable_indicators_number_of_customers_by_week = getIndicatorsNumberOfCustomersByWeekForProfitabilityThreshold(
    ser_growth_turnover_occupancy_rate_stable_indicators_number_of_customers_by_day,
    sig.number_days_open_per_year
  );

  // G18
  const ser_growth_turnover_average_price_increase_price_increase =
    cser_data.indicators_ser_growth_turnover_average_price_increase;

  // G22
  const ser_growth_turnover_average_price_increase_indicators_average_price_in_euros = getAveragePriceForSerGrowthTurnoverWithAveragePriceIncrease(
    ser_growth_turnover_average_price_increase_price_increase,
    adjusted_imt_indicators_average_price_in_euros
  );

  // G20
  const ser_growth_turnover_average_price_increase_indicators_number_of_customers_by_day = getNumberOfCustomerByDayForSerGrowthTurnoverAvPriceStable(
    ser_growth_turnover_indicators_turnover_day,
    ser_growth_turnover_average_price_increase_indicators_average_price_in_euros
  );

  // G21
  const ser_growth_turnover_average_price_increase_indicators_fill_rate = getIndicatorsFillRateForAdjustedImt(
    ser_growth_turnover_average_price_increase_indicators_number_of_customers_by_day,
    sig.capacity_per_day
  );
  // G26
  const ser_growth_turnover_average_price_increase_indicators_number_of_customers_by_week = getIndicatorsNumberOfCustomersByWeekForProfitabilityThreshold(
    ser_growth_turnover_average_price_increase_indicators_number_of_customers_by_day,
    sig.number_days_open_per_year
  );
  // G27
  const ser_growth_turnover_average_price_increase_indicators_gross_margin_in_euros = ser_growth_turnover_average_price_stable_indicators_gross_margin_in_euros;
  // H5
  const ser_forecast_simulation_percentage_of_change_in_revenues =
    cser_data.simulation_ser_forecast_percentage_of_change_in_revenues;
  // I5
  const ser_forecast_simulation_turnover_before_tax = getTurnoverBeforeTaxPercentageForSerForecast(
    adjusted_imt_simulation_turnover_before_tax,
    ser_forecast_simulation_percentage_of_change_in_revenues
  );
  // H8
  const ser_forecast_simulation_fixed_costs_variation_in_euros =
    cser_data.simulation_ser_forecast_fixed_costs;
  // I8
  const ser_forecast_simulation_fixed_costs = ser_forecast_simulation_fixed_costs_variation_in_euros;
  // H6
  const ser_forecast_simulation_percentge_of_variable_load_rate =
    cser_data.simulation_ser_forecast_percentage_of_variable_load_rate;

  // Start Tooltip to compute the new variable load rate
  const ser_forecast_percentage_reduction_material_consumption =
    cser_data.percentage_reduction_material_consumption;

  const ser_forecast_new_variable_costs_rate = getNewVariableCostsRateFromPercentageReductionMaterialConsumption(
    ser_forecast_percentage_reduction_material_consumption
  );
  // End Tooltip to compute the new variable load rate

  // I6
  const ser_forecast_variable_costs = getVariableCostsForSerForecast(
    ser_forecast_simulation_turnover_before_tax,
    ser_forecast_simulation_percentge_of_variable_load_rate
  );

  // H7
  const ser_forecast_simulation_margin_on_variable_costs_percentage = getMarginOnVariableCostsPercentageForSerForecast(
    ser_forecast_simulation_percentge_of_variable_load_rate
  );

  // I7
  const ser_forecast_simulation_margin_on_variable_costs = getSimulationMarginOnVariableCostsForSerForecast(
    ser_forecast_simulation_margin_on_variable_costs_percentage,
    ser_forecast_simulation_turnover_before_tax
  );

  // I9
  const ser_forecast_simulation_current_income_before_taxes = getCurrentIncomeBeforeTaxesForAdjustedImt(
    ser_forecast_simulation_margin_on_variable_costs,
    ser_forecast_simulation_fixed_costs
  );

  // I10
  const ser_forecast_simulation_corporate_taxes = getCorporateTaxesForAdjustedImt(
    sig.tax_rate_lower_tier,
    sig.tax_rate_upper_tier,
    sig.tax_lower_tier,
    ser_forecast_simulation_current_income_before_taxes
  );

  // I11
  const ser_forecast_simulation_net_profit_after_tax = getNetProfitAfterTaxForAdjustedImt(
    ser_forecast_simulation_current_income_before_taxes,
    ser_forecast_simulation_corporate_taxes
  );

  // I12
  const ser_forecast_simulation_cash_flow = getCashFlowForAdjustedImt(
    ser_forecast_simulation_net_profit_after_tax,
    sig.depreciation_amortization
  );

  // I13
  const ser_forecast_simulation_loan_repayments =
    cser_data.simulation_ser_forecast_loan_repayments;

  // I14
  const ser_forecast_simulation_net_surplus = getNetSurplusForAdjustedImt(
    ser_forecast_simulation_cash_flow,
    ser_forecast_simulation_loan_repayments
  );

  // H24
  const ser_forecast_indicators_turnover_day = getIndicatorsTurnoverDayForAdjustedImt(
    ser_forecast_simulation_turnover_before_tax,
    sig.number_days_open_per_year
  );

  // H22
  const ser_forecast_indicators_average_price_stable_average_price_in_euros = adjusted_imt_indicators_average_price_in_euros;

  // H20
  const ser_forecast_indicators_average_price_stable_number_of_customers_by_day = getIndicatorsNumberOfCustomersByDayForProfitabilityThreshold(
    ser_forecast_indicators_turnover_day,
    ser_forecast_indicators_average_price_stable_average_price_in_euros
  );

  // Indicateur number_of_customers_by_week_ser_growth_turnover_average_price_stable
  const ser_growth_turnover_indicators_average_price_stable_number_of_customers_by_week = getIndicatorsNumberOfCustomersByWeekForProfitabilityThreshold(
    ser_growth_turnover_indicators_average_price_stable_number_of_customers_by_day,
    sig.number_days_open_per_year
  );

  // H21
  const ser_forecast_indicators_average_price_stable_fill_rate = getIndicatorsFillRateForAdjustedImt(
    ser_forecast_indicators_average_price_stable_number_of_customers_by_day,
    sig.capacity_per_day
  );

  // H26
  const ser_forecast_indicators_average_price_stable_number_of_customers_by_week = getIndicatorsNumberOfCustomersByWeekForProfitabilityThreshold(
    ser_forecast_indicators_average_price_stable_number_of_customers_by_day,
    sig.number_days_open_per_year
  );
  // H30
  const ser_forecast_indicators_coefficients = getIndicatorsCoefficientForSerForecast(
    ser_forecast_simulation_turnover_before_tax,
    sig,
    ser_forecast_variable_costs
  );

  // H31
  const ser_forecast_indicators_ratio_of_materials = getIndicatorsRatioOfMaterials(
    ser_forecast_indicators_coefficients
  );

  // H32
  const ser_forecast_indicators_gross_margin_in_euros_excluding_consumed_production = getGrossMarginInEurosExcludingConsumedProductionForAdjustedImt(
    ser_forecast_simulation_turnover_before_tax,
    ser_forecast_indicators_coefficients
  );

  // H27
  const ser_forecast_indicators_average_price_stable_gross_margin_in_euros = getIndicatorsGrossMarginInEuros(
    ser_forecast_indicators_gross_margin_in_euros_excluding_consumed_production,
    sig.number_days_open_per_year
  );

  // I20
  const ser_forecast_indicators_occupancy_rate_stable_number_of_customers_by_day = adjusted_imt_indicators_number_of_customers_by_day;

  // I21
  const ser_forecast_indicators_occupancy_rate_stable_fill_rate = adjusted_imt_indicators_fill_rate;

  // I22
  const ser_forecast_indicators_occupancy_rate_stable_average_price_in_euros = getIndicatorsAveragePriceInEuros(
    ser_forecast_indicators_turnover_day,
    ser_forecast_indicators_occupancy_rate_stable_number_of_customers_by_day
  );

  // I26
  const ser_forecast_indicators_occupancy_rate_stable_number_of_customers_by_week = getIndicatorsNumberOfCustomersByWeekForProfitabilityThreshold(
    ser_forecast_indicators_occupancy_rate_stable_number_of_customers_by_day,
    sig.number_days_open_per_year
  );

  // J18
  const ser_forecast_indicators_average_price_increase_price_increase =
    cser_data.indicators_ser_forecast_average_price_increase;

  // J22
  const ser_forecast_indicators_average_price_increase_average_price_in_euros = getAveragePriceForSerGrowthTurnoverWithAveragePriceIncrease(
    ser_forecast_indicators_average_price_increase_price_increase,
    adjusted_imt_indicators_average_price_in_euros
  );

  // J20
  const ser_forecast_indicators_average_price_increase_number_of_customers_by_day = getIndicatorsNumberOfCustomersByDayForProfitabilityThreshold(
    ser_forecast_indicators_turnover_day,
    ser_forecast_indicators_average_price_increase_average_price_in_euros
  );

  // J21
  const ser_forecast_indicators_average_price_increase_fill_rate = getIndicatorsFillRateForAdjustedImt(
    ser_forecast_indicators_average_price_increase_number_of_customers_by_day,
    sig.capacity_per_day
  );

  // J26
  const ser_forecast_indicators_average_price_increase_number_of_customers_by_week = getIndicatorsNumberOfCustomersByWeekForProfitabilityThreshold(
    ser_forecast_indicators_average_price_increase_number_of_customers_by_day,
    sig.number_days_open_per_year
  );

  // J27
  // FIXME : A mon avis c'est une connerie d'utiliser la Marge du seuil de renta mais c'est marqué comme ça dans Excel ...
  const ser_forecast_indicators_average_price_increase_gross_margin_in_euros = getIndicatorsGrossMarginInEuros(
    profitability_threshold_indicators_gross_margin_in_euros_excluding_consumed_production,
    sig.number_days_open_per_year
  );

  // F36
  const cser_k = getCserK(
    ser_forecast_indicators_coefficients,
    adjusted_imt_indicators_coefficients
  );

  // K5
  const ser_coeff_simulation_turnover_before_tax = ser_forecast_simulation_turnover_before_tax;

  // K30
  const ser_coeff_indicators_coefficients =
    cser_data.indicators_ser_coeff_coefficient;

  // K6
  const ser_coeff_simulation_variable_costs = getVariableCostsForSerCoeff(
    ser_forecast_variable_costs,
    ser_forecast_indicators_coefficients,
    ser_coeff_indicators_coefficients
  );

  // K7
  const ser_coeff_simulation_margin_on_variable_costs = getMarginOnVariableCostsForAdjustedImt(
    ser_coeff_simulation_turnover_before_tax,
    ser_coeff_simulation_variable_costs
  );

  // K8
  const ser_coeff_simulation_fixed_costs = ser_forecast_simulation_fixed_costs;

  // K9
  const ser_coeff_simulation_current_income_before_taxes = getCurrentIncomeBeforeTaxesForAdjustedImt(
    ser_coeff_simulation_margin_on_variable_costs,
    ser_coeff_simulation_fixed_costs
  );
  // K10
  const ser_coeff_simulation_corporate_taxes = getCorporateTaxesForAdjustedImt(
    sig.tax_rate_lower_tier,
    sig.tax_rate_upper_tier,
    sig.tax_lower_tier,
    ser_coeff_simulation_current_income_before_taxes
  );

  // K11
  const ser_coeff_simulation_net_profit_after_tax = getNetProfitAfterTaxForAdjustedImt(
    ser_coeff_simulation_current_income_before_taxes,
    ser_coeff_simulation_corporate_taxes
  );

  // K12
  const ser_coeff_simulation_cash_flow = getCashFlowForAdjustedImt(
    ser_coeff_simulation_net_profit_after_tax,
    sig.depreciation_amortization
  );
  // K13
  const ser_coeff_simulation_loan_repayments =
    cser_data.simulation_ser_coeff_loan_repayments;

  // K14
  const ser_coeff_simulation_net_surplus = getNetSurplusForAdjustedImt(
    ser_coeff_simulation_cash_flow,
    ser_coeff_simulation_loan_repayments
  );
  // K31
  const ser_coeff_indicators_ratio_of_materials = getIndicatorsRatioOfMaterials(
    ser_coeff_indicators_coefficients
  );
  // K32
  const ser_coeff_indicators_gross_margin_in_euros_excluding_consumed_production = getGrossMarginInEurosExcludingConsumedProductionForAdjustedImt(
    ser_coeff_simulation_turnover_before_tax,
    ser_coeff_indicators_coefficients
  );
  await store.dispatch({
    type: UPDATE_COMPUTED_CSER,
    payload: {
      cser_k,
      adjusted_imt: {
        ...computed_cser.adjusted_imt,
        simulation_turnover_before_tax: adjusted_imt_simulation_turnover_before_tax,
        simulation_turnover_before_tax_percentage: adjusted_imt_simulation_turnover_before_tax_percentage,
        simulation_variable_costs: adjusted_imt_simulation_variable_costs,
        simulation_variable_costs_percentage: adjusted_imt_simulation_variable_costs_percentage,
        simulation_margin_on_variable_costs: adjusted_imt_simulation_margin_on_variable_costs,
        simulation_margin_on_variable_costs_percentage: adjusted_imt_simulation_margin_on_variable_costs_percentage,
        simulation_fixed_costs: adjusted_imt_simulation_fixed_costs,
        simulation_current_income_before_taxes: adjusted_imt_simulation_current_income_before_taxes,
        simulation_current_income_before_taxes_percentage: adjusted_imt_simulation_current_income_before_taxes_percentage,
        simulation_corporate_taxes: adjusted_imt_simulation_corporate_taxes,
        simulation_net_profit_after_tax: adjusted_imt_simulation_net_profit_after_tax,
        simulation_net_profit_after_tax_percentage: adjusted_imt_simulation_net_profit_after_tax_percentage,
        simulation_cash_flow: adjusted_imt_simulation_cash_flow,
        simulation_cash_flow_percentage: adjusted_imt_simulation_cash_flow_percentage,
        simulation_loan_repayments: adjusted_imt_simulation_loan_repayments,
        simulation_loan_repayments_percentage: adjusted_imt_simulation_loan_repayments_percentage,
        simulation_net_surplus: adjusted_imt_simulation_net_surplus,
        simulation_net_surplus_percentage: adjusted_imt_simulation_net_surplus_percentage,
        indicators_number_of_customers_by_day: adjusted_imt_indicators_number_of_customers_by_day,
        indicators_fill_rate: adjusted_imt_indicators_fill_rate,
        indicators_turnover_day: adjusted_imt_indicators_turnover_day,
        indicators_average_price_in_euros: adjusted_imt_indicators_average_price_in_euros,
        indicators_number_of_customers_by_week: adjusted_imt_indicators_number_of_customers_by_week,
        indicators_turnover_week: adjusted_imt_indicators_turnover_week,
        indicators_coefficients: adjusted_imt_indicators_coefficients,
        indicators_ratio_of_materials: adjusted_imt_indicators_ratio_of_materials,
        indicators_gross_margin_in_euros_excluding_consumed_production: adjusted_imt_indicators_gross_margin_in_euros_excluding_consumed_production,
        indicators_gross_margin_in_euros: adjusted_imt_indicators_gross_margin_in_euros,
      },
      profitability_threshold: {
        simulation_fixed_costs: adjusted_imt_simulation_fixed_costs,
        simulation_margin_on_variable_costs: adjusted_imt_simulation_fixed_costs,
        simulation_variable_costs: profitability_threshold_simulation_variable_costs,
        simulation_cash_flow: profitability_threshold_simulation_cash_flow,
        simulation_turnover_before_tax: profitability_threshold_simulation_turnover_before_tax,
        simulation_loan_repayments: profitability_threshold_simulation_loan_repayments,
        simulation_net_surplus: profitability_threshold_net_surplus,
        indicators_turnover_day: profitability_threshold_indicators_turnover_day,
        indicators_number_of_customers_by_day: profitability_threshold_indicators_number_of_customers_by_day,
        indicators_fill_rate: profitability_threshold_indicators_fill_rate,
        indicators_number_of_customers_by_week: profitability_threshold_indicators_number_of_customers_by_week,
        indicators_turnover_week: profitability_threshold_indicators_turnover_week,
        indicators_coefficients: adjusted_imt_indicators_coefficients,
        indicators_ratio_of_materials: adjusted_imt_indicators_ratio_of_materials,
        indicators_gross_margin_in_euros_excluding_consumed_production: profitability_threshold_indicators_gross_margin_in_euros_excluding_consumed_production,
        indicators_gross_margin_in_euros: profitability_threshold_indicators_gross_margin_in_euros,
      },
      ser_growth_turnover: {
        simulation_turnover_before_tax: ser_growth_turnover_simulation_turnover_before_tax,
        simulation_percentage_of_change_in_revenues: ser_growth_turnover_simulation_turnover_before_tax_percentage,
        simulation_variable_costs: ser_growth_turnover_simulation_variable_costs,
        simulation_margin_on_variable_costs: ser_growth_turnover_simulation_margin_on_variable_costs,
        simulation_fixed_costs: ser_growth_turnover_simulation_fixed_costs,
        simulation_loan_repayments: ser_growth_turnover_simulation_loan_repayments,
        simulation_net_surplus: ser_growth_turnover_simulation_net_surplus,
        simulation_cash_flow: ser_growth_turnover_simulation_cash_flow,
        simulation_current_income_before_taxes: ser_growth_turnover_simulation_current_income_before_taxes,
        simulation_net_profit_after_tax: ser_growth_turnover_simulation_net_profit_after_tax,
        simulation_corporate_taxes: ser_growth_turnover_simulation_corporate_taxes,
        indicators_turnover_day: ser_growth_turnover_indicators_turnover_day,
        indicators_turnover_week: getTurnoverByWeek(
          ser_growth_turnover_simulation_turnover_before_tax,
          sig.number_weeks_open_per_year
        ),
        average_price_stable: {
          indicators_number_of_customers_by_day: ser_growth_turnover_indicators_average_price_stable_number_of_customers_by_day,
          indicators_fill_rate: ser_growth_turnover_indicators_average_price_stable_fill_rate,
          indicators_average_price_in_euros: ser_growth_turnover_indicators_average_price_stable_average_price_in_euros,
          indicators_number_of_customers_by_week: ser_growth_turnover_indicators_average_price_stable_number_of_customers_by_week,
          indicators_gross_margin_in_euros: ser_growth_turnover_average_price_stable_indicators_gross_margin_in_euros,
        },
        occupancy_rate_stable: {
          indicators_number_of_customers_by_day: ser_growth_turnover_occupancy_rate_stable_indicators_number_of_customers_by_day,
          indicators_fill_rate: ser_growth_turnover_occupancy_rate_stable_indicators_fill_rate,
          indicators_average_price_in_euros: ser_growth_turnover_occupancy_rate_stable_indicators_average_price_in_euros,
          indicators_number_of_customers_by_week: ser_growth_turnover_occupancy_rate_stable_indicators_number_of_customers_by_week,
        },
        average_price_increase: {
          price_increase: ser_growth_turnover_average_price_increase_price_increase,
          indicators_number_of_customers_by_day: ser_growth_turnover_average_price_increase_indicators_number_of_customers_by_day,
          indicators_fill_rate: ser_growth_turnover_average_price_increase_indicators_fill_rate,
          indicators_average_price_in_euros: ser_growth_turnover_average_price_increase_indicators_average_price_in_euros,
          indicators_number_of_customers_by_week: ser_growth_turnover_average_price_increase_indicators_number_of_customers_by_week,
          indicators_gross_margin_in_euros: ser_growth_turnover_average_price_increase_indicators_gross_margin_in_euros,
        },
        indicators_coefficients: ser_growth_turnover_indicators_coefficients,
        indicators_ratio_of_materials: ser_growth_turnover_indicators_ratio_of_materials,
        indicators_gross_margin_in_euros_excluding_consumed_production: ser_growth_turnover_indicators_gross_margin_in_euros_excluding_consumed_production,
      },
      ser_forecast: {
        new_variable_costs_rate: ser_forecast_new_variable_costs_rate,
        percentage_reduction_material_consumption: ser_forecast_percentage_reduction_material_consumption,
        simulation_turnover_before_tax: ser_forecast_simulation_turnover_before_tax,
        simulation_percentage_of_change_in_revenues: ser_forecast_simulation_percentage_of_change_in_revenues,
        simulation_fixed_costs: ser_forecast_simulation_fixed_costs,
        simulation_fixed_costs_variation_in_euros: ser_forecast_simulation_fixed_costs_variation_in_euros,
        simulation_percentge_of_variable_load_rate: ser_forecast_simulation_percentge_of_variable_load_rate,
        simulation_variable_costs: ser_forecast_variable_costs,
        simulation_margin_on_variable_costs_percentage: ser_forecast_simulation_margin_on_variable_costs_percentage,
        simulation_margin_on_variable_costs: ser_forecast_simulation_margin_on_variable_costs,
        simulation_current_income_before_taxes: ser_forecast_simulation_current_income_before_taxes,
        simulation_corporate_taxes: ser_forecast_simulation_corporate_taxes,
        simulation_net_profit_after_tax: ser_forecast_simulation_net_profit_after_tax,
        simulation_cash_flow: ser_forecast_simulation_cash_flow,
        simulation_loan_repayments: ser_forecast_simulation_loan_repayments,
        simulation_net_surplus: ser_forecast_simulation_net_surplus,
        indicators_turnover_day: ser_forecast_indicators_turnover_day,
        indicators_turnover_week: getTurnoverByWeek(
          ser_forecast_simulation_turnover_before_tax,
          sig.number_weeks_open_per_year
        ),
        indicators_coefficients: ser_forecast_indicators_coefficients,
        indicators_ratio_of_materials: ser_forecast_indicators_ratio_of_materials,
        indicators_gross_margin_in_euros_excluding_consumed_production: ser_forecast_indicators_gross_margin_in_euros_excluding_consumed_production,
        average_price_stable: {
          indicators_average_price_in_euros: ser_forecast_indicators_average_price_stable_average_price_in_euros,
          indicators_number_of_customers_by_day: ser_forecast_indicators_average_price_stable_number_of_customers_by_day,
          indicators_fill_rate: ser_forecast_indicators_average_price_stable_fill_rate,
          indicators_number_of_customers_by_week: ser_forecast_indicators_average_price_stable_number_of_customers_by_week,
          indicators_gross_margin_in_euros: ser_forecast_indicators_average_price_stable_gross_margin_in_euros,
        },
        occupancy_rate_stable: {
          indicators_number_of_customers_by_day: ser_forecast_indicators_occupancy_rate_stable_number_of_customers_by_day,
          indicators_fill_rate: ser_forecast_indicators_occupancy_rate_stable_fill_rate,
          indicators_average_price_in_euros: ser_forecast_indicators_occupancy_rate_stable_average_price_in_euros,
          indicators_number_of_customers_by_week: ser_forecast_indicators_occupancy_rate_stable_number_of_customers_by_week,
        },
        average_price_increase: {
          price_increase: ser_forecast_indicators_average_price_increase_price_increase,
          indicators_average_price_in_euros: ser_forecast_indicators_average_price_increase_average_price_in_euros,
          indicators_number_of_customers_by_day: ser_forecast_indicators_average_price_increase_number_of_customers_by_day,
          indicators_fill_rate: ser_forecast_indicators_average_price_increase_fill_rate,
          indicators_number_of_customers_by_week: ser_forecast_indicators_average_price_increase_number_of_customers_by_week,
          indicators_gross_margin_in_euros: ser_forecast_indicators_average_price_increase_gross_margin_in_euros,
        },
      },
      ser_coeff: {
        simulation_turnover_before_tax: ser_coeff_simulation_turnover_before_tax,
        simulation_variable_costs: ser_coeff_simulation_variable_costs,
        simulation_margin_on_variable_costs: ser_coeff_simulation_margin_on_variable_costs,
        simulation_fixed_costs: ser_coeff_simulation_fixed_costs,
        simulation_current_income_before_taxes: ser_coeff_simulation_current_income_before_taxes,
        simulation_corporate_taxes: ser_coeff_simulation_corporate_taxes,
        simulation_net_profit_after_tax: ser_coeff_simulation_net_profit_after_tax,
        simulation_cash_flow: ser_coeff_simulation_cash_flow,
        simulation_loan_repayments: ser_coeff_simulation_loan_repayments,
        simulation_net_surplus: ser_coeff_simulation_net_surplus,
        indicators_coefficients: ser_coeff_indicators_coefficients,
        indicators_ratio_of_materials: ser_coeff_indicators_ratio_of_materials,
        indicators_gross_margin_in_euros_excluding_consumed_production: ser_coeff_indicators_gross_margin_in_euros_excluding_consumed_production,
      },
    },
  });
};

export default computeCserData;
