import React from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";

import {
  Card as MuiCard,
  IconButton,
  Tooltip,
  TableCell,
  CardContent,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  makeStyles,
  Grid,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import AddIcon from "@material-ui/icons/Add";
import { useDispatch, useSelector } from "react-redux";
import RawMaterial from "./RawMaterial";
import { postRawMaterial } from "../../redux/actions/productActions";

const Card = styled(MuiCard)(spacing);

const useStyles = makeStyles({
  margin: {
    marginTop: "12px",
  },
  componentSig: {
    marginBottom: "0px!important",
  },
  input: {
    width: "90px!important",
  },
  gridInput: {
    borderLeft: "solid 1px",
    paddingLeft: "30px",
    borderBottom: "solid 1px",
  },
  gridTitle: {
    borderBottom: "solid 1px",
    paddingBottom: "11px",
    paddingTop: "21px",
  },
  tableEqualWidth: {
    "& th": { width: "20%!important" },
  },
  iconEdit: {
    fontSize: "1em",
    color: "#1876d1bd",
  },
  card: {
    paddingBottom: "0px!important",
  },
  gridColor: {
    backgroundColor: "#1876d121",
  },
  disableField: {
    "& td div": {
      color: "black!important",
      fontWeight: "600",
    },
  },
  buttonStyle: {
    height: "100%",
    padding: "16px",
    background: "transparent",
    width: "100%",
    borderRadius: "0",
  },
});

const RawMaterials = () => {
  const classes = useStyles();
  const product = useSelector((state) => state.product.product);
  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const handlePostNewRawMaterial = async () => {
    await dispatch(
      postRawMaterial(
        {
          product_id: product.id,
          name: t("Nouvelle matière première"),
        },
        user.accessToken
      )
    );
  };

  const rawMaterials = useSelector((state) => state.product.raw_materials);
  const sortedRawMaterials = rawMaterials.sort((a, b) => {
    return a.order - b.order;
  });

  return (
    <Grid item xs={12}>
      <Card xs={12}>
        <CardContent className={classes.card}>
          <TableContainer component={Paper}>
            <Table
              className={[classes.table, classes.tableEqualWidth]}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow
                  key="total_product"
                  className={[classes.gridColor, classes.disableField]}
                >
                  <TableCell component="th" scope="row">
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      display="inline"
                    >
                      <Trans i18nKey="Nom">Nom</Trans>
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      display="inline"
                    >
                      <Trans i18nKey="Fournisseur">Fournisseur</Trans>
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      display="inline"
                    >
                      <Trans i18nKey="Unité">Unité</Trans>
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      display="inline"
                    >
                      <Trans i18nKey="Energie (ex Kcal/U)">
                        Energie
                        <br />
                        (ex Kcal/U, CO2, g/u)
                      </Trans>
                    </Typography>
                  </TableCell>

                  <TableCell component="th" scope="row">
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      display="inline"
                    >
                      <Trans i18nKey="Quantité Brute">Quantité Brute</Trans>
                    </Typography>
                  </TableCell>

                  <div style={{ minWidth: "100px" }}>
                    <TableCell component="th" scope="row">
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        display="inline"
                      >
                        <Trans i18nKey="Perte (%)">Perte (%)</Trans>
                      </Typography>
                    </TableCell>
                  </div>
                  <TableCell component="th" scope="row">
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      display="inline"
                    >
                      <Trans i18nKey="Quantité Nette">Quantité Nette</Trans>
                    </Typography>
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ minWidth: "100px" }}
                  >
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      display="inline"
                    >
                      <Trans i18nKey="Prix Unitaire">Prix Unitaire</Trans>
                    </Typography>
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ minWidth: "100px" }}
                  >
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      display="inline"
                    >
                      <Trans i18nKey="Prix Total">Prix Total</Trans>
                    </Typography>
                  </TableCell>
                  <TableCell align="right" />
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedRawMaterials.map((rawMaterial) => {
                  return <RawMaterial rawMaterial={rawMaterial} />;
                })}
                <TableRow key="add_external_cost">
                  <TableCell colspan={12} component="th" scope="row">
                    <Tooltip
                      disableFocusListener
                      title={t("Ajouter une matière première")}
                    >
                      <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={() => handlePostNewRawMaterial()}
                        className={classes.buttonStyle}
                      >
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default RawMaterials;
