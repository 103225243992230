export const ADD_PRODUCT_TO_RANGE = "ADD_PRODUCT_TO_RANGE";
export const ADD_PRODUCT_TO_RANGE_SUCCESS = "ADD_PRODUCT_TO_RANGE_SUCCESS";
export const ADD_PRODUCT_TO_RANGE_ERROR = "ADD_PRODUCT_TO_RANGE_ERROR";

export const COMPUTE_RANGE_VALUES_SUCCESS = "COMPUTE_RANGE_VALUES_SUCCESS";

export const DISABLE_BUTTON_ADD_PRODUCT_TO_RANGE =
  "DISABLE_BUTTON_ADD_PRODUCT_TO_RANGE";
export const ENABLE_BUTTON_ADD_PRODUCT_TO_RANGE =
  "ENABLE_BUTTON_ADD_PRODUCT_TO_RANGE";

export const FETCH_RANGE_PRODUCTS = "FETCH_RANGE_PRODUCTS";
export const ERROR_FETCHING_RANGE_PRODUCTS = "ERROR_FETCHING_RANGE_PRODUCTS";
export const DELETE_RANGE_PRODUCT_SUCCESS = "DELETE_RANGE_PRODUCT_SUCCESS";
export const DELETE_RANGE_PRODUCT_ERROR = "DELETE_RANGE_PRODUCT_ERROR";
export const FETCH_RANGES_OF_A_PRODUCT_SUCCESS =
  "FETCH_RANGES_OF_A_PRODUCT_SUCCESS";
export const FETCH_RANGES_OF_A_PRODUCT_ERROR =
  "FETCH_RANGES_OF_A_PRODUCT_ERROR";
export const UPDATE_RANGE_PRODUCT_SUCCESS = "UPDATE_RANGE_PRODUCT_SUCCESS";
export const UPDATE_RANGE_PRODUCT_ERROR = "UPDATE_RANGE_PRODUCT_ERROR";
export const UPDATE_RANGE_PRODUCTS_SUCCESS = "UPDATE_RANGE_PRODUCTS_SUCCESS";
export const SWITCH_POPSER = "SWITCH_POPSER";

export const CLEAN_RANGE_PRODUCTS = "CLEAN_RANGE_PRODUCTS";

export const EDIT_RANGE_PRODUCT = "EDIT_RANGE_PRODUCT";
export const EDIT_RANGE_PRODUCT_SUCCESS = "EDIT_RANGE_PRODUCT_SUCCESS";
export const EDIT_RANGE_PRODUCT_ERROR = "EDIT_RANGE_PRODUCT_ERROR";
export const FETCH_RANGE_PRODUCTS_STARTED = "FETCH_RANGE_PRODUCTS_STARTED";
export const EDIT_RANGE_PRODUCTS = "EDIT_RANGE_PRODUCTS";
