import {
  FETCH_PROJECT_COMMENTS,
  ADD_PROJECT_COMMENTS,
  UPDATE_PROJECT_COMMENTS,
  FETCH_PROJECT_COMMENT_READ,
  ADD_PROJECT_COMMENT_READ,
  UPDATE_PROJECT_COMMENT_READ,
} from "../types/projectCommentsActionTypes";

const initialState = {
  comments: [
    {
      user: null,
      comment: null,
      dateTime: null,
    },
  ],
  commentRead: [{ user: null, comment: null }],
  loading: true,
};

const projectCommentsReducer = (state = initialState, action) => {
  const copyProjectComments = [];
  const copyProjectCommentRead = [];

  switch (action.type) {
    case FETCH_PROJECT_COMMENTS:
      return {
        ...state,
        comments: action.payload,
        loading: false,
      };
    case ADD_PROJECT_COMMENTS:
      return {
        ...state,
        comments: state.comments.concat(action.payload),
        loading: false,
      };
    case UPDATE_PROJECT_COMMENTS:
      return {
        ...state,
        comments: copyProjectComments.concat(
          state.comments.slice(
            0,
            state.comments.findIndex((x) => x.id === action.payload.id)
          ),
          action.payload,
          state.comments.slice(
            state.comments.findIndex((x) => x.id === action.payload.id) + 1
          )
        ),
        loading: false,
      };
    case FETCH_PROJECT_COMMENT_READ:
      return {
        ...state,
        commentRead: action.payload,
        loading: false,
      };
    case ADD_PROJECT_COMMENT_READ:
      return {
        ...state,
        commentRead: state.commentRead.concat(action.payload),
        loading: false,
      };
    case UPDATE_PROJECT_COMMENT_READ:
      return {
        ...state,
        commentRead: copyProjectCommentRead.concat(
          state.commentRead.slice(
            0,
            state.commentRead.findIndex((x) => x.id === action.payload.id)
          ),
          action.payload,
          state.commentRead.slice(
            state.commentRead.findIndex((x) => x.id === action.payload.id) + 1
          )
        ),
        loading: false,
      };
    default:
      return state;
  }
};

export default projectCommentsReducer;
