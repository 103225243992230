/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  TableRow,
  TableCell,
  TextField,
  IconButton,
  Input,
  InputAdornment,
} from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";

import { Delete as DeleteIcon } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { openSnackbar } from "../../redux/actions/snackbarActions";
import {
  deleteRawMaterial,
  putRawMaterial,
} from "../../redux/actions/productActions";
import useDebounce from "../../services/useDebounce";

import updateIndicators, {
  getNetQuantityFromRawQuantityAndLoss,
  getTotalPriceFromRawQuantityAndUnitPrice,
} from "../../services/product/RawMaterialService";
import { updateTechnicalSheet } from "../../redux/actions/technicalSheetActions";
// eslint-disable-next-line no-unused-vars

import { is2Decimal } from "../../services/helper/helperService";

const useStyles = makeStyles(() => ({
  input: {
    width: "90px",
    "& div": {
      margin: "0",
    },
  },
  inputName: {
    width: "185px",
  },
  inputSupplier: {
    width: "90px",
  },
  iconEdit: {
    fontSize: "1em",
    color: "#1876d1bd",
  },
  disableField: {
    "& td div": {
      color: "black!important",
    },
  },
}));

const RawMaterial = ({ rawMaterial }) => {
  const classes = useStyles();

  const auth = useSelector((state) => state.firebase.auth);
  const rawMaterials = useSelector((state) => state.product.raw_materials);

  const user = auth.stsTokenManager;
  const dispatch = useDispatch();
  const [rawMaterialState, setRawMaterialState] = useState({
    ...rawMaterial,
    user_input: false,
  });
  useEffect(() => {
    // When deleting a raw material, force the component to reload it's state as it can have change if we remove subsequents raw materials
    setRawMaterialState({
      ...rawMaterial,
      user_input: false,
    });
  }, [rawMaterial.id]);
  const currentProject = useSelector((state) => state.projects.currentProject);
  const debouncedRawMaterialState = useDebounce(rawMaterialState, 300);
  const handleDeleteRawMaterial = async () => {
    await dispatch(deleteRawMaterial(rawMaterial.id, user.accessToken));
    if (rawMaterials.length > 0) {
      await updateIndicators(debouncedRawMaterialState, user.accessToken);
    } else {
      const data = {
        idCurrentProject: currentProject.id,
        technicalSheet: {
          multiplyingFactor: {
            value: 0,
          },
          sellingPriceTTC: {
            value: 0,
          },
          sellingPriceHT: {
            value: 0,
          },
          totalPricePortion: {
            value: 0,
          },
          totalMaterialCost: {
            value: 0,
          },
          perUnitEnergy: {
            value: 0,
          },
          materialCosts: {
            value: 0,
          },
        },
      };
      dispatch(
        updateTechnicalSheet(
          data,
          user.accessToken,
          rawMaterial.product_id,
          currentProject.id
        )
      );
    }
  };

  const handleChangeRawQuantity = (rawQuantity) => {
    if (is2Decimal(rawQuantity)) {
      setRawMaterialState({
        ...rawMaterialState,
        raw_quantity: rawQuantity,
        net_quantity: getNetQuantityFromRawQuantityAndLoss(
          rawQuantity,
          rawMaterialState.loss
        ),
        total_price: getTotalPriceFromRawQuantityAndUnitPrice(
          rawQuantity,
          rawMaterialState.unit_price
        ),
        userInput: true,
      });
    } else {
      dispatch(openSnackbar("La saisie est limitée à 2 décimales", "Error"));
    }
  };

  const handleChangeLoss = (loss) => {
    if (is2Decimal(loss)) {
      setRawMaterialState({
        ...rawMaterialState,
        loss,
        net_quantity: getNetQuantityFromRawQuantityAndLoss(
          rawMaterialState.raw_quantity,
          loss
        ),
        userInput: true,
      });
    } else {
      dispatch(openSnackbar("La saisie est limitée à 2 décimales", "Error"));
    }
  };

  const handleChangeUnitPrice = (unit_price) => {
    if (is2Decimal(unit_price)) {
      setRawMaterialState({
        ...rawMaterialState,
        unit_price,
        total_price: getTotalPriceFromRawQuantityAndUnitPrice(
          rawMaterialState.raw_quantity,
          unit_price
        ),
        userInput: true,
      });
    } else {
      dispatch(openSnackbar("La saisie est limitée à 2 décimales", "Error"));
    }
  };

  useEffect(async () => {
    if (debouncedRawMaterialState.userInput === true) {
      await dispatch(
        putRawMaterial(
          debouncedRawMaterialState,
          rawMaterials,
          user.accessToken
        )
      );
      if (rawMaterials.length > 0) {
        await updateIndicators(debouncedRawMaterialState, user.accessToken);
      }
    }
  }, [debouncedRawMaterialState]);

  return (
    <TableRow className={classes.disableField} key="raw_material_purchases">
      <TableCell component="td" scope="row">
        <TextField
          onChange={(e) =>
            setRawMaterialState({
              ...rawMaterialState,
              name: e.target.value,
              userInput: true,
            })
          }
          value={rawMaterialState.name}
          className={classes.inputName}
          InputProps={{
            endAdornment: (
              <Edit className={classes.iconEdit} onClick={() => 0}>
                x
              </Edit>
            ),
          }}
        />
      </TableCell>
      <TableCell component="td" scope="row">
        <TextField
          value={rawMaterialState.supplier}
          onChange={(e) =>
            setRawMaterialState({
              ...rawMaterialState,
              supplier: e.target.value,
              userInput: true,
            })
          }
          className={classes.inputSupplier}
          InputProps={{
            endAdornment: (
              <Edit className={classes.iconEdit} onClick={() => 0}>
                x
              </Edit>
            ),
          }}
        />
      </TableCell>
      <TableCell component="td" scope="row">
        <TextField
          onChange={(e) =>
            setRawMaterialState({
              ...rawMaterialState,
              unit_weight: e.target.value,
              userInput: true,
            })
          }
          value={rawMaterialState.unit_weight}
          className={classes.input}
          InputProps={{
            endAdornment: (
              <Edit className={classes.iconEdit} onClick={() => 0}>
                x
              </Edit>
            ),
          }}
        />
      </TableCell>
      <TableCell component="td" scope="row">
        <TextField
          onChange={(e) =>
            setRawMaterialState({
              ...rawMaterialState,
              energy: e.target.value,
              userInput: true,
            })
          }
          value={rawMaterialState.energy}
          className={classes.input}
          InputProps={{
            endAdornment: (
              <Edit className={classes.iconEdit} onClick={() => 0}>
                x
              </Edit>
            ),
          }}
        />
      </TableCell>
      <TableCell component="td" scope="row">
        <TextField
          type="number"
          onChange={(e) => handleChangeRawQuantity(e.target.value)}
          value={rawMaterialState.raw_quantity}
          className={classes.input}
          InputProps={{
            endAdornment: (
              <Edit className={classes.iconEdit} onClick={() => 0}>
                x
              </Edit>
            ),
          }}
        />
      </TableCell>
      <TableCell scope="row">
        <Input
          type="number"
          onChange={(e) => handleChangeLoss(e.target.value)}
          value={rawMaterialState.loss}
          className={classes.input}
          endAdornment={
            <>
              <InputAdornment position="end">%</InputAdornment>
              <Edit className={classes.iconEdit} onClick={() => 0}>
                x
              </Edit>
            </>
          }
        />
      </TableCell>

      <TableCell component="td" scope="row">
        <Input type="number" disabled value={rawMaterialState.net_quantity} />
      </TableCell>
      <TableCell scope="row">
        <Input
          type="number"
          onChange={(e) => handleChangeUnitPrice(e.target.value)}
          value={rawMaterialState.unit_price}
          className={classes.input}
          endAdornment={
            <>
              <InputAdornment position="end">€</InputAdornment>
              <Edit className={classes.iconEdit} onClick={() => 0}>
                x
              </Edit>
            </>
          }
        />
      </TableCell>
      <TableCell scope="row">
        <Input
          type="number"
          disabled
          value={rawMaterialState.total_price}
          className={classes.input}
          endAdornment={
            <>
              <InputAdornment position="end">€</InputAdornment>
            </>
          }
        />
      </TableCell>
      <TableCell align="right">
        <IconButton
          aria-label="delete"
          onClick={() => handleDeleteRawMaterial()}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

RawMaterial.propTypes = {
  rawMaterial: PropTypes.shape({
    id: PropTypes.number,
    product_id: PropTypes.number,
    name: PropTypes.string,
    supplier: PropTypes.string,
    energy: PropTypes.number,
    raw_quantity: PropTypes.number,
    loss: PropTypes.number,
    net_quantity: PropTypes.number,
    unit_weight: PropTypes.number,
    total_price: PropTypes.number,
    unit_price: PropTypes.number,
  }).isRequired,
};
export default RawMaterial;
