import {
  AppBar,
  Divider as MuiDivider,
  Grid,
  makeStyles,
  styled,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import React, { useEffect, useState } from "react";
import { Loader } from "react-feather";
import { Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import RangeDashboard from "../../components/Ranges/RangeDashboard";
import RangeDetail from "../../components/Ranges/RangeDetail";
import RangeTitle from "../../components/Ranges/RangeTitle";
import { fetchRange } from "../../redux/actions/rangeActions";
import PreviewRange from "../../components/Ranges/PreviewRange";
import RangeProducts from "../../components/Ranges/RangeProducts";
import RangeBalance from "../../components/Ranges/RangeBalance";
import TabPanel from "../../components/TabPanel/TabPanel";
import { fetchRangeProducts } from "../../redux/actions/rangeProductActions";

const Divider = styled(MuiDivider)(spacing);

const useStyles = makeStyles(() => ({
  margin: {
    marginTop: "24px",
    marginBottom: "24px",
  },
  tabBar: {
    backgroundColor: "White",
    color: "Black",
  },
  tabContainer: {
    maxWidth: "100%",
    "& .MuiBox-root": {
      padding: "0px",
    },
  },
  widthContainerTable: {},
  rangeFeedback: {
    textAlign: "center",
    "& .MuiCardContent-root": {
      padding: "22px",
    },
    "& textarea": {
      border: "none",
      borderTop: "solid 2px",
      borderBottom: "solid 2px",
    },
    "& .MuiToolbar-root": {
      backgroundColor: "white",
    },
    "& .MuiIconButton-root": {
      borderRadius: "0px",
    },
  },
}));
const ShowRange = () => {
  // User Authentication
  const auth = useSelector((state) => state.firebase.auth);
  const user = auth.stsTokenManager;
  const rangeIsLoading = useSelector((state) => state.ranges.rangeIsLoading);
  const dispatch = useDispatch();
  const classes = useStyles();

  // Range Info
  const { rangeId } = useParams();

  useEffect(() => {
    dispatch(fetchRange(rangeId, user.accessToken));
    dispatch(fetchRangeProducts(rangeId, user.accessToken));
  }, [rangeId]);
  const [valueTab, setValueTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setValueTab(newValue);
  };
  if (rangeIsLoading) {
    return <Loader />;
  }
  return (
    <>
      <RangeTitle />
      <Divider my={6} className={classes.margin} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <RangeDetail />
        </Grid>
        <Typography variant="h6" gutterBottom display="inline">
          <Trans i18nKey="Tableau de bord">Tableau de bord</Trans>
        </Typography>
        <Grid container spacing={1} className={classes.widthContainerTable}>
          <Grid item xs={12}>
            <RangeDashboard />
            <PreviewRange />
            <AppBar position="static" className={classes.tabBar}>
              <Tabs
                value={valueTab}
                onChange={handleTabChange}
                aria-label="tabs"
              >
                <Tab
                  label={<Trans i18nKey="">Produits de la Gamme</Trans>}
                  id="tab-1"
                  aria-controls="tabpanel-1"
                />
                <Tab
                  label={<Trans i18nKey="">Equilibre de la Gamme</Trans>}
                  id="tab-2"
                  aria-controls="tabpanel-2"
                />
              </Tabs>
            </AppBar>
            <TabPanel
              value={valueTab}
              className={classes.tabContainer}
              index={0}
            >
              <RangeProducts />
            </TabPanel>
            <TabPanel
              value={valueTab}
              className={classes.tabContainer}
              index={1}
            >
              <RangeBalance />
            </TabPanel>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default ShowRange;
